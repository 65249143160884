import React, { useEffect } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import { Timer } from '../../Timer';

import { ContentBlock } from '../../ContentBlock';
import Button from '../../Button/Button';
import { withSubscription } from '../../../HOC/withSubscription';

import { POP_UP } from '../../../const';
import { required } from '../../../utils/Form/formik-validation';
import { Input } from '../../FormComponents/Input';

const validationSchema = Yup.object().shape({
  kod: Yup.string().required(required)
});

const initialValues = {
  kod: ''
};

type FormFields = {
  kod: string;
};

type FormProps = {
  error: string;
  time: number;
  buttonText: string;
  loading: boolean;
  isDisabled: boolean;
  reSendCode: () => void;
  onTimerFinish: () => void;
  onKodChange: (kod: string) => void;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({
  values,
  time,
  buttonText,
  loading,
  isDisabled,
  error,
  reSendCode,
  onTimerFinish,
  onKodChange
}) => {
  useEffect(() => {
    onKodChange(values.kod);
  }, [values.kod]);

  return (
    <Form noValidate>
      <Field
        placeholder="Код"
        name="kod"
        type="text"
        component={Input}
        className="_light _small"
      />

      {time > 0 ? (
        <div className="form__info">
          Повторно отправить код можно через{' '}
          <Timer time={time} timeFinishCallback={onTimerFinish} />
        </div>
      ) : (
        <div className="form__info form__info-available" onClick={reSendCode}>
          Отправить повторно{' '}
        </div>
      )}

      <Button
        type="submit"
        loading={loading}
        disabled={isDisabled}
        className="_success _large _wide form__button"
        text={buttonText}
      />

      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

type Props = {
  time: number;
  className: string;
  phone: string;
  content: string;
  buttonText: string;
  errorMessage: string;
  loading: boolean;
  isDisabled: boolean;
  withIir: boolean;
  iir: boolean;
  onHandleChange: () => void;
  onSubmit: () => void;
  reSendCode: () => void;
  onTimerFinish: () => void;
  onKodChange: (kod: string) => void;
};

const AutoFollowingFabric: React.FC<Props> = ({
  className,
  phone,
  content,
  time,
  buttonText,
  errorMessage,
  isDisabled,
  loading,
  onSubmit,
  reSendCode,
  onTimerFinish,
  onKodChange
}) => {
  return (
    <div className={cn('auto-following', className)}>
      <div className="modal__text-wrap">
        <div className="modal__text-block scrollbar">
          <ContentBlock className="_autofollowing">
            <div className="modal__text-title" />
            <p dangerouslySetInnerHTML={{ __html: content }} />
          </ContentBlock>
        </div>
      </div>
      <div className="modal__label">
        Пожалуйста, ознакомьтесь с текстом заявления и введите код, отправленный
        на номер {phone}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={onSubmit}>
        {props =>
          React.createElement(FormTemplate, {
            ...props,
            time,
            buttonText,
            loading,
            isDisabled,
            reSendCode,
            onTimerFinish,
            onKodChange,
            error: errorMessage
          })
        }
      </Formik>
    </div>
  );
};

// @ts-ignore
export const AutoFollowing = withSubscription(AutoFollowingFabric, {
  url: 'account/confirmbindstrategy',
  resendUrl: 'account/bindstrategy',
  name: POP_UP.AUTO_FOLLOWING,
  onSuccess: POP_UP.APPROVAL,
  gaEventName: 'PersonalNewBind',
  buttonText: 'Подписать'
});

export const RemoveStrategyConfirmation = withSubscription(
  // @ts-ignore
  AutoFollowingFabric,
  {
    url: 'account/confirmunbindstrategy',
    resendUrl: 'account/unbindstrategy',
    name: POP_UP.REMOVE_AUTO_FOLLOWING,
    onSuccess: POP_UP.APPROVAL,
    gaEventName: 'PersonalFailBind',
    buttonText: 'Подписать'
  }
);

// @ts-ignore
export const BindPortfolio = withSubscription(AutoFollowingFabric, {
  url: 'v2/termportfolios/confirmbind',
  resendUrl: 'v2/termportfolios/bind',
  name: POP_UP.BIND_PORTFOLIO,
  onSuccess: POP_UP.APPROVAL,
  gaEventName: 'PortfolioPurchase',
  buttonText: 'Подписать'
});

export const UnbindPortfolioConfirmation = withSubscription(
  // @ts-ignore
  AutoFollowingFabric,
  {
    url: 'v2/termportfolios/confirmunbind',
    resendUrl: 'v2/termportfolios/unbind',
    name: POP_UP.UNBIND_PORTFOLIO,
    onSuccess: POP_UP.APPROVAL,
    gaEventName: 'PersonalPortfolioCancel',
    buttonText: 'Подписать'
  }
);
