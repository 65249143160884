import { useStore } from 'effector-react';
import React from 'react';
import Helmet from 'react-helmet';

import { ArticleCardList } from '../../../components/Cards/ArticleCardList';
import { Author$ } from '../../../effector/author';
import { AnalyticsBrief } from '../../../types/analyticsBrief';

type Props = {
  articles: AnalyticsBrief[];
};

export const ArticlesSection: React.FC<Props> = ({ articles }) => {
  const author = useStore(Author$);

  const meta = {
    title: `${author?.firstName} ${author?.lastName}: инвестиционные статьи от эксперта Fintarget`,
    meta: [
      {
        name: 'description',
        content:
          `${author?.firstName} ${author?.lastName} представляет инвестиционные стратегии для торговли на бирже на сайте Fintarget. Автоследование от лучших трейдеров Fintarget.`
      }
    ]
  };

  return (
    <>
      {meta && <Helmet {...meta} />}
      <div className="tabs__tab _active">
        <ArticleCardList items={articles} />
      </div>
    </>
  );
};
