import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../../Button/Button';
import { bindStrategy } from '../../../api';
import * as PopupActions from '../../../actions/PopupActions';

import { toJS } from '../../../HOC/toJS';
import { POP_UP } from '../../../const';

const AutoFollowingAccept = ({ popupData, popupActions }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const confirm = () => {
    const binding = {
      ...popupData,
      sellAssestAgreement: true
    };

    setLoading(true);
    bindStrategy(binding)
      .then(result => {
        const { errorMessage, success, response } = result;

        if (success) {
          const popupData = { ...binding, content: response.acceptHtml };

          popupActions.openPopup(POP_UP.AUTO_FOLLOWING, popupData);
        } else {
          setErrorMessage(errorMessage);
        }
      })
      .finally(() => setLoading(false));
  };

  const cancel = () => {
    const binding = {
      ...popupData,
      sellAssestAgreement: false
    };

    setLoading(true);
    bindStrategy(binding)
      .then(result => {
        const { errorMessage, success } = result;

        if (success) {
          popupActions.openPopup(POP_UP.ADD_STRATEGY, {});
        } else {
          setErrorMessage(errorMessage);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div className="modal__text-title">
        На вашем счете есть открытые позиции!
      </div>
      <p className="modal__text-content">
        Подключая услугу "Автоследование" я соглашаюсь с тем, что все имеющиеся
        открытые позиции на выбранном брокерском счете будут автоматически
        закрыты по рыночной цене в момент балансировки портфеля.
      </p>
      {errorMessage && (
        <p className="error-message" dangerouslySetInnerHTML={{__html: errorMessage}} />
      )}
      <div className="modal-strategy__buttons">
        <div className="modal-strategy__buttons-row">
          <div className="modal-strategy__buttons-col">
            <div>
              <Button
                className="_primary _large _wide"
                text="Выбрать другой счет"
                loading={loading}
                onClick={cancel}
              />
            </div>
          </div>
          <div className="modal-strategy__buttons-col">
            <div>
              <Button
                className="_primary _large _wide"
                text="Подключить"
                loading={loading}
                onClick={confirm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  popupData: state.getIn(['popup', 'data'])
});

const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(AutoFollowingAccept));
