import React from 'react';

import { ReactComponent as Star } from './Star.svg';
import classNames from 'classnames';

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

type Props = {
  checked: number;
  size?: Size;
  className?: string;
};

export const StarRating: React.FC<Props> = ({
  checked,
  className,
  size = Size.small
}) => (
  <div className={classNames('star-rating', className)}>
    <div className="star-rating__container">
      <div className="star-rating__number">{checked}</div>
      <Star
        className={`star-rating__item star-rating__item-${size} star-rating__item-active`}
      />
    </div>
  </div>
);
