import { AuthenticationService } from '../services/authenticationService';
import { unauthorizedRequest } from '../actions/AccountActions';
import { store } from '../services/store/configureStore';

const FORBIDDEN = 403;
const UNAUTHORIZED = 401;
const NO_CONTENT = 204;

const authUrl = process.env.REACT_APP_AUTH_URL;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const clientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET;

type Config = {
  basePath: string;
  agent: (input: RequestInfo, options: RequestInit) => Promise<Response>;
  errorCatcher: (err: Error) => void;
};

export enum RequestTypes {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export type Options = {
  headers: Record<string, string>;
  method: RequestTypes;
  body?: string;
};

const config: Config = {
  basePath: '/api/',
  agent: fetch.bind(window),
  errorCatcher: (err: Error) => console.log('log error:', err)
};

const createEndpoint = (endpoint: string) => `${config.basePath}${endpoint}`;
const createOptions: (method: RequestTypes, body?: string) => Options = (
  method,
  body
) => {
  const defaultOptions = {
    headers: {
      Authorization: AuthenticationService.headerAccessToken,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method
  };

  if (method === RequestTypes.GET) {
    return defaultOptions;
  }

  return {
    ...defaultOptions,
    body
  };
};

const checkResponseOk = (response: Response) => {
  if (!response.ok) {
    throw new Error(
      JSON.stringify({
        type: 'api',
        url: response.url,
        status: response.status,
        statusText: response.statusText
      })
    );
  }

  return response;
};

const checkAppVersion = (response: Response) => {
  const headerKey = 'X-App-Version';
  const storageKey = 'appVersion';
  const appVersion = response.headers.get(headerKey);
  const currentAppVersion = localStorage.getItem(storageKey);

  if (currentAppVersion && appVersion !== currentAppVersion) {
    localStorage.setItem(storageKey, appVersion as string);
    window.location.reload(true);
  } else {
    localStorage.setItem(storageKey, appVersion as string);
  }

  return response;
};

const checkResponseCode = (response: Response) => {
  switch (response.status) {
    case NO_CONTENT:
      return new Response('{}');

    default:
      return response;
  }
};

const createRequestByType = (type: RequestTypes) => (
  endpoint: string,
  body?: object
) => {
  const options: Options = createOptions(type, JSON.stringify(body));

  return tryFetch(endpoint, options);
};

const request = (url: string, options: Options) =>
  config
    .agent(createEndpoint(url), options)
    .then(checkResponseOk)
    .then(checkAppVersion)
    .then(checkResponseCode)
    .then(res => res.json());

const tryFetch = async (url: string, options: Options) => {
  try {
    return await request(url, options);
  } catch (err) {
    try {
      const status = JSON.parse(err.message).status;
      const inTokenError = status === FORBIDDEN || status === UNAUTHORIZED;

      if (!inTokenError) {
        throw Error(err);
      }

      const token = AuthenticationService.refreshToken;

      if (!token) {
        throw Error(err);
      }

      const result = await refreshToken(token);

      AuthenticationService.accessToken = result?.access_token || '';
      AuthenticationService.refreshToken = result?.refresh_token || '';

      return await request(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: AuthenticationService.headerAccessToken
        }
      });
    } catch (err) {
      store.dispatch(unauthorizedRequest());
      config.errorCatcher(err);
      return ({
        errorMessage: 'Произошла непредвиденная ошибка',
        success: false,
        response: null
      })
    }
  }
};

export const get = createRequestByType(RequestTypes.GET);

export const post = createRequestByType(RequestTypes.POST);

export const put = createRequestByType(RequestTypes.PUT);

export const deleteRequest = createRequestByType(RequestTypes.DELETE);

const refreshToken = (token: string) => {
  const options: Options = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: RequestTypes.POST,
    body:
      `grant_type=refresh_token&refresh_token=${token}&client_id=${clientId}` +
      ((clientSecret && `&client_secret=${clientSecret}`) || '')
  };

  return config
    .agent(`${authUrl}`, options)
    .then(checkResponseOk)
    .then(checkResponseCode)
    .then(res => res.json())
    .catch(config.errorCatcher);
};

// @ts-ignore
export const auth = data => {
  const options: Options = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: RequestTypes.POST,
    body: data
  };

  // tslint:disable-next-line:max-line-length
  return config
    .agent(`${authUrl}`, options)
    .then(checkResponseOk)
    .then(checkResponseCode)
    .then(res => res.json())
    .catch(config.errorCatcher);
};
