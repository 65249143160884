import React, { PureComponent } from 'react'
import {Link} from '../Link'
import classNames from 'classnames'
import { LoadingDots } from '../UIKit/LoadingDots'

class Button extends PureComponent {
  render () {
    const {
      tag,
      link,
      className,
      text,
      type,
      disabled = false,
      loading = false,
      onClick
    } = this.props
    if (tag === 'a') {
      return (
        <Link className={classNames('button', className)} link={link} onClick={onClick}>
          <span className='button__text'>{text}</span>
        </Link>
      )
    } else if (type === 'submit') {
      return (
        <button
          className={classNames('button', className)}
          disabled={disabled || loading}
          onClick={onClick}
          type='submit'>
          <LoadingDots className={classNames({ 'button-hide': !loading })} />
          <span
            className={classNames('button__text', { 'button-hide': loading })}>
            {text}
          </span>
        </button>
      )
    } else {
      return (
        <button
          className={classNames('button', className)}
          disabled={disabled || loading}
          onClick={onClick}
          type='button'>
          <LoadingDots className={classNames({ 'button-hide': !loading })} />
          <span
            className={classNames('button__text', { 'button-hide': loading })}>
            {text}
          </span>
        </button>
      )
    }
  }
}

export default Button
