import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useStore } from 'effector-react';
import _sortBy from 'lodash/sortBy';

import { multiplier, SEO_CONTENT } from '../../const';

import { Strategies$, strategiesApi } from '../../effector/strategies';

import { Sort, sortCategories, Catalog } from './Catalog';
import { Filter } from './Filter';

import { Section } from '../../components/Section';
import { Form, Formik } from 'formik';
import { Option } from '../../types/option';
import { Nullable } from '../../types/utils';

export type SortCategory = {
  id: string;
  name: string;
  direction?: Sort;
};

type FilterProp = {
  q: string;
  risk: number;
  profit: number[];
  funds?: Nullable<Option[]>;
  tools?: Nullable<Option[]>;
  sort: SortCategory;
};

export type FormProps = {
  filter: FilterProp;
};

export const StrategiesPage: React.FC<{}> = () => {
  const strategies = useStore(Strategies$);

  useEffect(() => {
    // @ts-ignore
    strategiesApi.get();
  }, []);

  const getInitialValues = () => {
    const data = _sortBy(strategies.map(({ actualProfit }) => actualProfit));
    const min = data[0] * multiplier;
    const max = data[data.length - 1] * multiplier;
    const defaultValue = [min, max];

    return {
      filter: {
        q: '',
        funds: null,
        tools: null,
        risk: 7,
        profit: defaultValue,
        sort: { ...sortCategories[0], direction: Sort.DESK }
      }
    };
  };

  const seo = SEO_CONTENT['strategies'];

  if (!strategies.length) return null;

  const initialValues: FormProps = getInitialValues();

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => console.log('')}>
      <Form>
        {seo && <Helmet {...seo} />}
        <Filter />
        <Section className="_strategies">
          <Catalog />
        </Section>
      </Form>
    </Formik>
  );
};
