import { createStore, createApi, createEffect, forward } from 'effector';

import { getLastDeals } from '../api';
import { Nullable } from '../types/utils';
import { StrategySignal } from '../types/strategySignal';
// tslint:disable-next-line:no-any
type TDealsLast$State = Nullable<StrategySignal[]>;

export const DealsLast$ = createStore<TDealsLast$State>(null);

const { set } = createApi(DealsLast$, {
  set: (state, { result }) => {
    return result.response;
  }
});

export const dealsLastApi = {
  // @ts-ignore
  get: createEffect().use(getLastDeals)
};

forward({
  from: dealsLastApi.get.done,
  to: set
});
