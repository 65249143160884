import React from 'react'
import classNames from 'classnames'

import { enteredAnimate } from '../../HOC/enteredAnimate'

// eslint-disable-next-line
const BreadcrumbsLocal = ({ className, items }) => (
  <nav className={classNames('breadcrumbs', className)}>
    <ul className='breadcrumbs__list'>
      {items.map(({ text, active, onClick }, key) => (
        <li
          key={key}
          className={classNames('breadcrumbs__item', { _active: active })}
        >
          <span onClick={onClick} className='breadcrumbs__link breadcrumbs__pointer'>
            {text}
          </span>
        </li>
      ))}
    </ul>
  </nav>
)

const wrappedComponent = enteredAnimate(BreadcrumbsLocal)

export { wrappedComponent as BreadcrumbsLocal }
