import { createStore, createApi, createEffect, forward } from 'effector';

import { getAccountPortfolios } from '../api';
import { AccountPortfolio } from '../types/accountPortfolio';

type TAccountPortfolios$State = Record<string, AccountPortfolio[]>;

const initialState = {};

export const AccountPortfolios$ = createStore<TAccountPortfolios$State>(
  initialState
);

const { set } = createApi(AccountPortfolios$, {
  set: (state, { result, params }) => {
    const clientCode = params.clientCode as string;

    return { ...state, ...{ [clientCode]: result.response } };
  }
});

export const accountPortfoliosApi = {
  get: createEffect<{ clientCode: string }, {}, {}>().use(getAccountPortfolios)
};

forward({
  from: accountPortfoliosApi.get.done,
  to: set
});
