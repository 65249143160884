import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toJS } from './toJS';

import { post } from '../api/apiHelper';

import * as PopupActions from '../actions/PopupActions';

import { GAService } from '../services/gaService';
import { useStore } from 'effector-react';
import { Account$ } from '../effector/clientAccounts';
import { Nullable } from '../types/utils';

const defaultSecondsToUnblock = 60;

type Params = {
  url: string;
  resendUrl: string;
  name: string;
  onSuccess: string;
  gaEventName: string;
  buttonText: string;
  withIir: boolean;
};

type HOC = (
  WrappedComponent: React.FunctionComponent,
  params: Params
) => React.FC;

export const withSubscription: HOC = (WrappedComponent, params) => {
  type Props = {
    popupData: any;
    popupActions: any;
    className: string;
  };

  const Inner: React.FC<Props> = ({
    popupData,
    popupActions,
    className,
    ...rest
  }) => {
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [time, setTime] = useState<number>(defaultSecondsToUnblock);
    const [kod, setKod] = useState<string>('');

    const { account } = useStore(Account$);

    useEffect(() => {
      setError('');
    }, [kod]);

    const handleSubmit = () => {
      const body = { ...popupData, confirmationCode: kod };

      setLoading(true);

      GAService.sendEvent(params.gaEventName);

      post(params.url, body).then(result => {
        const { errorMessage, success } = result;

        if (success) {
          popupActions.hidePopup(params.name);
          popupActions.openPopup(params.onSuccess, {
            text: popupData.text,
            type: popupData.bindingType
          });

          if (popupData.action) {
            popupData.action();
          }
        } else {
          setError(errorMessage);
        }
      }).finally(() => {
        setLoading(false);
      });
    };

    const reSendCode = () => {
      post(params.resendUrl, popupData).then(result => {
        const { errorMessage, success } = result;

        if (success) {
          setTime(defaultSecondsToUnblock);
        } else {
          setError(errorMessage);
        }
      });
    };

    const modifyPhone = (phone: Nullable<string>) => {
      if (!phone) {
        return '';
      }

      const substituteSymbol = ['*'];
      const indexesToReplace = [
        phone.length - 3,
        phone.length - 2,
        phone.length - 1
      ];

      const withSecret = phone
        .split('')
        .reduce<string[]>((prev, cur, idx, arr) => {
          if (indexesToReplace.includes(idx)) {
            return prev.concat(substituteSymbol);
          }

          return prev.concat(cur);
        }, [])
        .join('');

      return withSecret;
    };

    return (
      <div className={cn('auto-following', className)}>
        <WrappedComponent
          {...rest}
          // @ts-ignore
          errorMessage={error}
          onSubmit={handleSubmit}
          isDisabled={!kod || error}
          phone={modifyPhone(account?.phone)}
          buttonText={params.buttonText}
          content={popupData.content}
          time={time}
          reSendCode={reSendCode}
          onTimerFinish={() => {
            setTime(0);
          }}
          onKodChange={setKod}
          loading={loading}
        />
      </div>
    );
  };

  // @ts-ignore
  const mapStateToProps = state => ({
    popupData: state.getIn(['popup', 'data'])
  });

  // @ts-ignore
  const mapDispatchToProps = dispatch => ({
    popupActions: bindActionCreators(PopupActions, dispatch)
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(toJS(Inner));
};
