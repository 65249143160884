import React, { Component } from 'react';
import { Uppy } from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { roundNumber } from '../../../utils';
import { deleteUploadedAuthorDocument } from '../../../api';
import { DragDrop } from '@uppy/react';
import cn from 'classnames';
import { ReactComponent as FileIcon } from '../Documents/file.svg';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';

const toMB = bytes => roundNumber(bytes / 1024 / 1024, 1);

export class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingFiles: [],
      files: [],
      error: ''
    };

    const size5mb = 1024 * 1024 * 5;

    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: size5mb,
        maxNumberOfFiles: 3,
        minNumberOfFiles: 1,
        allowedFileTypes: [
          '.xls',
          '.xlsx',
          '.csv',
          '.txt',
          '.zip',
          '.rar',
          '.doc',
          '.docx',
          '.pdf'
        ]
      }
    })
      .use(XHRUpload, {
        endpoint: '/api/authors/uploaddoc',
        allowMultipleUploads: true,
        method: 'POST',
        formData: true,
        fieldName: 'file'
      })
      .on('file-added', file => {
        const { uploadingFiles } = this.state;

        const fileInfo = {
          id: file.id,
          fileId: null,
          name: file.name,
          size: toMB(file.size),
          progress: 0,
          uploadedSize: 0,
          deleting: false
        };

        this.setState({ uploadingFiles: [...uploadingFiles, fileInfo] });
      })
      .on('upload-progress', (file, progress) => {
        const { uploadingFiles } = this.state;

        uploadingFiles.forEach(item => {
          if (item.id === file.id) {
            item.progress = roundNumber(
              (progress.bytesUploaded / progress.bytesTotal) * 100,
              0
            );
            item.uploadedSize = toMB(progress.bytesUploaded);
          }
        });

        this.setState({ uploadingFiles });
      })
      .on('upload-success', (file, result) => {
        const { uploadingFiles, files } = this.state;

        if (result.body && result.body.success) {
          const { response } = result.body;

          const uploadedFile = uploadingFiles.find(item => item.id === file.id);
          const newUploadingFiles = uploadingFiles.filter(
            item => item.id !== file.id
          );

          if (uploadedFile) {
            uploadedFile.fileId = response.id;

            const uploadedFiles = [...files, uploadedFile];
            const uploadedFileIds = uploadedFiles.map(item => item.fileId);

            this.setState({
              files: uploadedFiles
            });

            // eslint-disable-next-line
            this.props.onUploaded('fileIds', uploadedFileIds);

            setTimeout(
              () =>
                this.setState({
                  uploadingFiles: newUploadingFiles
                }),
              500
            );
          }
        } else if (result.body && !result.body.success) {
          const newUploadingFiles = uploadingFiles.filter(
            item => item.id !== file.id
          );
          this.setState({
            error: result.body.errorMessage,
            uploadingFiles: newUploadingFiles
          });
          setTimeout(() => this.setState({ error: '' }), 5000);
        }
      })
      .on('upload-error', (file, error, response) => {
        const { uploadingFiles } = this.state;
        const newUploadingFiles = uploadingFiles.filter(
          item => item.id !== file.id
        );
        this.setState({ error: 'Произошла непредвиденная ошибка' });
        setTimeout(
          () => this.setState({ error: '', uploadingFiles: newUploadingFiles }),
          5000
        );
      });
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  deleteFile = file => {
    const { files } = this.state;

    files.forEach(item => {
      if (item.id === file.id) {
        item.deleting = true;
      }
    });

    this.setState({ files });

    deleteUploadedAuthorDocument({ id: file.fileId }).then(result => {
      const { success } = result;

      if (success) {
        this.removeFile(file);
      }
    });
  };

  cancelUpload = file => {
    this.removeFile(file);
  };

  removeFile = file => {
    const { files } = this.state;

    const updatedFiles = files.filter(item => item.id !== file.id);
    const uploadedFileIds = updatedFiles.map(item => item.fileId);

    // eslint-disable-next-line
    this.props.onUploaded('fileIds', uploadedFileIds);

    this.setState({ files: updatedFiles });
    this.uppy.removeFile(file.id);
  };

  render() {
    const { files, uploadingFiles, error } = this.state;

    return (
      <div className="upload-form">
        <div className="form-upload">
          <DragDrop
            uppy={this.uppy}
            locale={{
              strings: {
                dropHereOr: 'Прикрепить файл'
              }
            }}
          />
        </div>
        {uploadingFiles &&
          uploadingFiles.map((item, index) => (
            <div key={index} className="upload-row">
              <div className="upload-row__header">
                <span className="upload-row__header-name">{item.name}</span>
                <span
                  className="upload-row__header-cancel"
                  onClick={() => this.cancelUpload(item)}>
                  Отменить загрузку
                </span>
              </div>
              <div className="upload-row__progress-bar-wrapper">
                <div
                  className="upload-row__progress-bar"
                  style={{ width: `${item.progress}%` }}
                />
              </div>
              <div className="upload-row__footer">
                <span className="upload-row__footer-progress">
                  {item.progress}%
                </span>
                <span className="upload-row__footer-size">
                  {item.uploadedSize}Мб из {item.size}Мб
                </span>
              </div>
            </div>
          ))}

        {error && <p className="error-message">{error}</p>}

        <div className="form-control-row__separator" />

        {files &&
          files.map((item, index) => (
            <div
              key={index}
              className={cn('uploaded-row', {
                'uploaded-row-deleting': item.deleting
              })}>
              <FileIcon className="uploaded-row__file" />
              <span className="uploaded-row__name">{item.name}</span>
              <span className="uploaded-row__size">{item.size}Мб</span>
              {/* <EditIcon className='uploaded-row__edit' /> */}
              <DeleteIcon
                className="uploaded-row__delete"
                onClick={() => this.deleteFile(item)}
              />
            </div>
          ))}
      </div>
    );
  }
}
