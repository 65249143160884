import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { required, number } from '../../../utils/Form/formik-validation';
import { Nullable } from '../../../types/utils';
import { Option } from '../../../types/option';
import { Select } from '../../../components/FormComponents/Select';
import { Input } from '../../../components/FormComponents/Input';
import { useStore } from 'effector-react';
import { Account$ } from '../../../effector/clientAccounts';
import { Portfolio } from '../../../types/portfolio';
import { Slider } from '../../../components/UIKit/Slider';
import { multiplier, POP_UP } from '../../../const';
import Button from '../../../components/Button/Button';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../../actions/PopupActions';
import { connect } from 'react-redux';
import { toJS } from '../../../HOC/toJS';
import { PortfolioBinding } from '../../../types/portfolioBinding';
import { bindPortfolio } from '../../../api';
import { Checkbox } from '../../../components/FormComponents/Checkbox';
import { formatMoney, roundNumber } from '../../../utils';
import { QuestionTooltip } from '../../../components/UIKit/Tooltip';

type FormFields = {
  bill: Nullable<Option>;
  summ: number;
  drawdown: number;
  profit: number;
  sendIirSms: boolean;
};

type FormProps = {
  portfolio: Portfolio;
  options: Option[];
  error: string;
  loading: boolean;
  setSelectedBill: (option: Nullable<Option>) => void;
} & FormikProps<FormFields>;

const sliderValueDivider = 10;

const FormTemplate: React.FC<FormProps> = ({
  values,
  options,
  portfolio,
  loading,
  error,
  setSelectedBill
}) => {
  useEffect(() => {
    setSelectedBill(values.bill);
  }, [values.bill, setSelectedBill]);

  return (
    <Form noValidate className="connect-portfolio">
      <div className="form-control-group form-control-row">
        <Field
          name="bill"
          component={Select}
          disabled={!options.length}
          className="form-control-group__item"
          placeholder="Выбрать счет для подключения"
          options={options}
        />
        <div className="form-control-group__item">
          <Slider
            title="Максимальная доходность: "
            divider={sliderValueDivider}
            name="profit"
            rcSlider={{
              min: Math.round(portfolio.minTP * multiplier),
              max: Math.round(portfolio.maxTP * multiplier),
              included: false,
              defaultValue: Math.round(portfolio.defaultTP * multiplier)
            }}
          />
        </div>
      </div>
      <div className="form-control-group form-control-row">
        <Field
          name="summ"
          component={Input}
          className="form-control-group__item"
          placeholder="Сумма вложений"
        />
        <div className="form-control-group__item">
          <Slider
            title="Максимальная просадка: -"
            divider={sliderValueDivider}
            name="drawdown"
            rcSlider={{
              min: Math.round(portfolio.minSL * multiplier),
              max: Math.round(portfolio.maxSL * multiplier),
              included: false,
              defaultValue: Math.round(portfolio.defaultSL * multiplier)
            }}
          />
        </div>
      </div>
      <div className="form-control-row portfolio__close-date">
        Портфель будет закрыт:{' '}
        {moment(portfolio.closeDate).format('DD.MM.YYYY')}
      </div>
      <div className="form-control-row portfolio__invest-profile">
        Инвестиционный профиль: {portfolio.minInvestProfile.name}{' '}
        <QuestionTooltip text={portfolio.minInvestProfile.description} />
      </div>
      <div className="form-control-row">
        <Field
          name="sendIirSms"
          text="Получать SMS-оповещения о поступлении инвестиционных рекомендаций"
          component={Checkbox}
        />
      </div>
      {error && <p className="error-message">{error}</p>}
      <div style={{ textAlign: 'center' }}>
        <Button
          className="_primary _large"
          disabled={!values.bill}
          loading={loading}
          text="Подключить"
          type="submit"
        />
      </div>
    </Form>
  );
};

type Props = {
  popupActions: any;
  portfolio: Portfolio;
};

const fullPart = 100;

const BindPortfolio: React.FC<Props> = ({ portfolio, popupActions }) => {
  const [selectedBill, setSelectedBill] = useState<Nullable<Option>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { account, accounts } = useStore(Account$);

  const options = useMemo(() => {
    const options = accounts.map(item => {
      const currencyPortfolio = item.currencyPortfolios.find(
        port => port.currency === portfolio.currency
      );
      const freeCash = currencyPortfolio
        ? `Свободные средства: ${formatMoney(
            currencyPortfolio.freeFunds,
            currencyPortfolio.currency,
            0
          )}`
        : 'Нет средств в нужной валюте';
      return {
        name: `${item.agreementId} (${item.clientCode}) ${freeCash}`,
        freeCash: currencyPortfolio?.freeFunds || 0,
        id: item.clientCode
      };
    });

    return options;
  }, [accounts]);

  const maxInvestValue = useMemo(() => {
    if (selectedBill) {
      // @ts-ignore
      return roundNumber(selectedBill.freeCash * (portfolio.maxPercent / fullPart));
    }
    return 0;
  }, [selectedBill, portfolio]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      bill: Yup.object().when('profit', {
        is: () => account,
        then: Yup.object()
          .required(required)
          .typeError(required),
        otherwise: Yup.object().nullable()
      }),
      summ: Yup.number().when('bill', {
        is: () => selectedBill,
        then: Yup.number()
          .min(
            portfolio.minSum,
            `Минимальная сумма инвестирования ${portfolio.minSum}`
          )
          .max(
            maxInvestValue,
            `Максимальная сумма инвестирования ${maxInvestValue}`
          )
          .integer(number)
          .typeError(number)
          .required(required),
        otherwise: Yup.number()
          .min(
            portfolio.minSum,
            `Минимальная сумма инвестирования ${portfolio.minSum}`
          )
          .integer(number)
          .typeError(number)
          .required(required)
      }),
      drawdown: Yup.number().required(required),
      profit: Yup.number().required(required),
      sendIirSms: Yup.boolean()
    });
  }, [portfolio.minSum, selectedBill]);

  const initialValues = useMemo(() => {
    const initialValues = {
      bill: null,
      summ: portfolio.minSum,
      drawdown: Math.round(portfolio.defaultSL * multiplier),
      profit: Math.round(portfolio.defaultTP * multiplier),
      sendIirSms: true
    };

    return initialValues;
  }, [portfolio.minSum, portfolio.minSL, portfolio.minTP]);

  const openAuthForm = () => {
    popupActions.openPopup(POP_UP.AUTHORIZATION, {
      onSuccess: () => {}
    });
  };

  const handleSubmit = (values: FormFields) => {
    if (!account) {
      openAuthForm();
      return;
    }

    const data: PortfolioBinding = {
      clientCode: values.bill?.id as string,
      portfolioId: portfolio.id,
      sum: values.summ,
      allowSell: false,
      sendIirSms: values.sendIirSms,
      sl: values.drawdown / multiplier,
      tp: values.profit / multiplier,
      confirmationCode: '',
      // @ts-ignore
      text: `<p>Спасибо! <br />
       Ваша заявка принята! <br />
       Статус подключения услуги вы можете увидеть в личном кабинете. </p>
        `
    };

    setLoading(true);
    bindPortfolio(data)
      .then(result => {
        const { errorMessage, success, response } = result;

        if (success) {
          const popupData = { ...data, content: response.acceptHtml };

          popupActions.openPopup(POP_UP.BIND_PORTFOLIO, popupData);
        } else {
          setErrorMessage(errorMessage);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      onSubmit={handleSubmit}>
      {props =>
        // @ts-ignore
        React.createElement(FormTemplate, {
          ...props,
          loading,
          options,
          portfolio,
          error: errorMessage,
          setSelectedBill
        })
      }
    </Formik>
  );
};

// @ts-ignore
const mapStateToProps = state => ({
  popupData: state.getIn(['popup', 'data'])
});

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(toJS(BindPortfolio));

export { wrappedComponent as BindPortfolio };
