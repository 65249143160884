import React from 'react';
import cn from 'classnames';

import { Link } from '../Link';
import { enteredAnimate } from '../../HOC/enteredAnimate';

type Element = {
  title: string;
  link: string;
  onClick?: () => void;
};

type Props = {
  className: string;
  items: Element[];
};

const Howto: React.FC<Props> = ({ className, items }) => (
  <div className={cn('howto', className)}>
    <div className="howto__list">
      {items.map(({ link, title, onClick }, key) => (
        <Link link={link} className="howto__item" key={key} onClick={onClick}>
          <h5
            className="howto__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Link>
      ))}
    </div>
  </div>
);

const wrappedComponent = enteredAnimate(Howto);

export { wrappedComponent as Howto };
