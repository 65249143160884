class GAService {
  constructor() {
    this._gtag = global.gtag;
  }

  get ga() {
    return this._gtag;
  }

  sendEvent = eventAction => {/*
    this._gtag('event', eventAction, {
      event_category: 'Web',
      event_callback: () => {
        console.log('ga sent event');
      }
    });
  */};
}

const GAServiceInstance = new GAService();

export { GAServiceInstance as GAService };
