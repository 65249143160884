import React from 'react'

export const Advantages = () => (
  <div className='experts-advantages'>
    <h1 className='experts-advantages__title'>Fintarget для авторов это:</h1>
    <ul className='experts-advantages__list'>
      <li className='experts-advantages__list-item'>Выгодные условия сотрудничества</li>
      <li className='experts-advantages__list-item'>Коммуникация с клиентами и обсуждение стратегий</li>
      <li className='experts-advantages__list-item'>Риск-менеджмент и поддержка со стороны Брокера №1</li>
    </ul>
    <div className='experts-advantages__note'>
      Это возможность делиться своими стратегиями с тысячами пользователей по
      всей России, используя преимущества современной и безопасной платформы
      Fintarget.
    </div>
  </div>
)
