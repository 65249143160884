import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import download from 'downloadjs';
import { Collapse } from 'react-collapse';

import { getAuthorDocument, getAuthorsDocuments } from '../../../api';

import { Nullable } from '../../../types/utils';
import { DocsGroup } from '../../../types/docsGroup';

import { ReactComponent as FileIcon } from './file.svg';
import { ReactComponent as PlusIcon } from '../../../components/Modal/_assets/i-plus.svg';

const DocumentGroup: React.FC<
  DocsGroup & { onDocumentClick: (id: string) => void }
> = ({ name, documents, onDocumentClick }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const onTopClick = useCallback(() => setIsOpened(!isOpened), [isOpened]);

  return (
    <div className="experts-documents__group-container">
      <div className="experts-documents__group" onClick={onTopClick}>
        <div className="experts-documents__group-name">{name}</div>
        <div
          className={cn('experts-documents__group-icon', {
            'experts-documents__group-icon_active': isOpened
          })}>
          <PlusIcon />
        </div>
      </div>
      <Collapse isOpened={isOpened}>
        <div className="experts-documents__group-list">
          {documents.map(({ id, name, descriptionHtml }, index) => (
            <DocumentRow
              key={index}
              name={name}
              descriptionHtml={descriptionHtml}
              onClick={() => onDocumentClick(id)}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

const DocumentRow: React.FC<{
  name: string;
  descriptionHtml: Nullable<string>;
  onClick: () => void;
}> = ({ name, descriptionHtml, onClick }) => (
  <div className="experts-documents__row-container">
    <div className="experts-documents__row">
      <FileIcon className="experts-documents__row-icon" />
      <div className="experts-documents__row-title" onClick={onClick}>
        {name}
      </div>
    </div>
    {descriptionHtml && (
      <div
        className="experts-documents__row-description"
        dangerouslySetInnerHTML={{
          __html: descriptionHtml
        }}
      />
    )}
  </div>
);

export const Documents: React.FC<{}> = ({}) => {
  const [documentGroups, setDocumentGroups] = useState<DocsGroup[]>([]);

  useEffect(() => {
    getAuthorsDocuments()
      .then(result => {
        const { success, response } = result;

        if (success) {
          setDocumentGroups(response);
        }
      })
      .catch(error => console.log('Произошла непредвиденная ошибка'));
  }, []);

  const onDocumentClick = (id: string) => {
    const params = {
      id
    };

    getAuthorDocument(params).then(result => {
      const { success, response } = result;

      if (success) {
        download(
          `data:application/octet-stream;base64,${response.bodyBase64}`,
          `${response.fileName}`,
          'application/octet-stream'
        );
      }
    });
  };

  return (
    <div className="experts-documents">
      <div className="experts-documents__title">Документы</div>
      {documentGroups.map(({ name, documents }, index) => {
        return name ? (
          <DocumentGroup
            key={index}
            name={name}
            documents={documents}
            onDocumentClick={onDocumentClick}
          />
        ) : (
          documents.map(({ id, name, descriptionHtml }, i) => (
            <DocumentRow
              key={i}
              name={name}
              descriptionHtml={descriptionHtml}
              onClick={() => onDocumentClick(id)}
            />
          ))
        );
      })}
    </div>
  );
};
