import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { unsubscribe } from '../../api';
import { Icon } from '../../components/Icon';
import { PAGE_HOME } from '../Routes';

const imageSize = {
  width: 136,
  height: 109
};

export const UnsubscribePage: React.FC<{}> = ({}) => {
  const [isUnsubscribed, setIsUnsubscribed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const location = useLocation();

  const params = queryString.parse(location.search);

  useEffect(() => {
    if (params.code) {
      const data = {
        code: params.code
      };

      unsubscribe(data)
        .then(result => {
          const { success } = result;

          if (success) {
            setIsUnsubscribed(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      history.push(PAGE_HOME);
    }
  }, []);

  return (
    <div className="unsubscribe-page">
      {!loading && (
        <>
          <Icon
            name={isUnsubscribed ? 'approval' : 'attention'}
            width={imageSize.width}
            height={imageSize.height}
          />
          <div className="unsubscribe-page__description">
            {isUnsubscribed
              ? 'Вы успешно отписались от рассылки на новости и аналитику!'
              : 'Данная подписка не найдена, либо сервис временно не доступен.'}
          </div>
        </>
      )}
    </div>
  );
};
