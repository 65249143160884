import React from 'react'
import cn from 'classnames'

// eslint-disable-next-line
export const Answer = ({ text, isSelected, onClick }) => (
  <div className={cn('invest-profile__content_answers_item', { '_active': isSelected })} onClick={onClick}>
    <div className={'invest-profile__content_answers_item_top'} >
      <div className={cn('invest-profile__content_answers_item_name', { '_active': isSelected })}>
        <span>{text}</span>
      </div>
    </div>
  </div>
)
