import React from 'react'
import cn from 'classnames'

// eslint-disable-next-line
export const Wave = ({
  // eslint-disable-next-line
  rootClass = 'card__waves',
  // eslint-disable-next-line
  childrenClass = 'card__wave'
}) => (
  <svg
    className={rootClass}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 423 119'
    fill='none'
  >
    <path
      className={cn('_front', childrenClass)}
      d='M0 71c2.5-9.9 10.2 25 35 25 31 0 31.4-33.1 67.5-33.1S146.6 28 186.4 28s44.2 43 88.7 43 53.2-23.9 86.1-23.9S394.4 63 423.1 63v56H0V71z'
    />
    <path
      className={cn('_back', childrenClass)}
      d='M423 71.1c-2.5-9.9-12.2-29.7-37-29.7-31 0-30.5 53.4-66.6 53.4C283.2 94.8 275.8 0 236 0c-39.8 0-44.4 77.1-88.9 77.1S94.9 17.6 62 17.6C29.2 17.6 28.7 63 0 63v56h423V71.1z'
    />
  </svg>
)
