import { fromJS } from 'immutable';

export const OPEN_POPUP = 'OPEN_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';
export const RESET_POPUP = 'RESET_POPUP';

const initialState = fromJS({
  auth: false,
  applicationForManagers: false,
  removeStrategy: false,
  autoFollowing: false,
  autoFollowingAccept: false,
  bindPortfolio: false,
  unbindPortfolio: false,
  approval: false,
  attention: false,
  removeAutoFollowing: false,
  nav: false,
  subscribe: false,
  data: {}
});

export const popup = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_POPUP:
      return initialState
        .set(action.payload.popupId, true)
        .set('data', action.payload.data);
    case HIDE_POPUP:
      return state.set(action.payload.popupId, false).set('data', {});
    case RESET_POPUP:
      return initialState;
    default:
      return state;
  }
};
