import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Helmet from 'react-helmet';

import { Faq$ } from '../../../effector/faq';
import { Nullable } from '../../../types/utils';
import { QuestionCategory } from '../../../types/questionCategory';

import { Sidebar } from '../components/Sidebar';
import { QuestionList } from '../components/QuestionList';
import { BreadcrumbsLocal } from '../../../components/Breadcrumbs/BreadcrumbsLocal';

import { PAGE_SUPPORT_FAQ, PAGE_SUPPORT_FAQ_CATEGORY } from '../../Routes';

export const Category: React.FC<{}> = ({}) => {
  const [questionCategory, setQuestionCategory] = useState<
    Nullable<QuestionCategory>
  >(null);
  const categories = useStore(Faq$);
  const { category } = useParams<{category: string}>();
  const history = useHistory();

  useEffect(() => {
    const foundedCategory = categories.find(item => item.tag === category);

    setQuestionCategory(foundedCategory);
  }, [categories, category]);

  const defaultCrumbs = [
    {
      text: 'Все вопросы',
      onClick: () => history.push(PAGE_SUPPORT_FAQ)
    },
    {
      text: '',
      active: true
    }
  ];

  const meta = {
    title: `${questionCategory?.name} / Fintarget.ru - Автоследование в БКС Брокер`,
    meta: [
      {
        name: 'description',
        content:
          'Инвестиционные стратегии для торговли на бирже от экспертов БКС Брокер в формате автоследования'
      }
    ]
  };

  return (
    <>
      {meta && <Helmet {...meta} />}
      <div className="support-faq__faqs">
        <Sidebar />
        <div className="faq-question-group">
          <BreadcrumbsLocal items={defaultCrumbs} />
          <div className="faq-question-group__content">
            {questionCategory ? (
              <QuestionList
                groupTag={questionCategory.tag}
                title={questionCategory.name}
                limit={questionCategory.questions.length}
                questions={questionCategory.questions}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
