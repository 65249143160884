import React from 'react'

// eslint-disable-next-line
export const StrategyParams = ({ items }) => (
  <div className='strategy-params'>
    {items.map(({ name, description }, index) => (
      <div className='strategy-params__row' key={index}>
        <div
          className='strategy-params__row-name'
          dangerouslySetInnerHTML={{ __html: name }}
        />
        <div className='strategy-params__row-description'>{description}</div>
      </div>
    ))}
    {/* <div className='strategy-params__all'>Все параметры</div> */}
  </div>
)
