import { SET_PATH, REMOVE_PATH, SET_EXTRA_OPEN } from '../reducers/routerInfo';

export const setPath = data => dispatch =>
  dispatch({
    type: SET_PATH,
    payload: { previousPath: data.path, profileId: data.profile }
  });

export const setExtraOpen = () => dispatch =>
  dispatch({
    type: SET_EXTRA_OPEN
  });

export const removePath = () => dispatch =>
  dispatch({
    type: REMOVE_PATH
  });
