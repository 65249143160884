import { createStore, createApi, createEffect, forward } from 'effector';

import { getPortfolio, getPortfolios } from '../api';
import { Portfolio, ShortPortfolio } from '../types/portfolio';
import { Nullable } from '../types/utils';

type TPortfolios$State = {
  portfolios: ShortPortfolio[];
  portfolio: Nullable<Portfolio>;
};

const initialState = {
  portfolios: [] as ShortPortfolio[],
  portfolio: null
};

export const Portfolios$ = createStore<TPortfolios$State>(initialState);

const { set, setPortfolio } = createApi(Portfolios$, {
  set: (state, { result }) => {
    return { ...state, portfolios: result.response };
  },
  setPortfolio: (state, { result }) => {
    return { ...state, portfolio: result.response };
  }
});

export const portfoliosApi = {
  get: createEffect().use(getPortfolios),
  getPortfolio: createEffect<{ portfolioId: string }, {}, {}>().use(
    getPortfolio
  )
};

forward({
  from: portfoliosApi.get.done,
  to: set
});

forward({
  from: portfoliosApi.getPortfolio.done,
  to: setPortfolio
});
