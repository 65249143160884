import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import StrategyDetail from './Details/StrategyDetail';
import {useParams} from 'react-router-dom';
import { useStore } from 'effector-react';

import { Page404 } from '../Page404/Page404';
import { Strategy$, strategyApi } from '../../effector/strategy';
import Helmet from 'react-helmet';
import {accountApi} from '../../effector/clientAccounts';

const defaultCrumbs = [
  {
    link: '/strategies',
    text: 'Стратегии'
  }
];

export const StrategyItem: React.FC<{}> = ({}) => {
  const [loaded, setLoaded] = useState(false);
  const strategy = useStore(Strategy$);
  const { id } = useParams<{id: string}>();

  useEffect(() => {
    strategyApi.get(id).finally(() => setLoaded(true));
  }, [id]);

  if (!loaded) {
    return null;
  }

  if (loaded && !strategy) {
    return (
      <div className="strategy-item">
        <Page404 />
      </div>
    );
  }

  const meta = {
    title: strategy?.seoTitle,
    meta: [
      {
        name: 'description',
        content: strategy?.seoDescription
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href
      }
    ],
  };

  return (
    <div className="strategy-item">
      <>
        {meta && <Helmet {...meta} />}
        <Breadcrumbs
          items={[...defaultCrumbs, { active: true, text: strategy?.name }]}
        />
        <StrategyDetail strategy={strategy} />
      </>
    </div>
  );
};
