import React, { useEffect, useState } from 'react';
import TitleTable from '../TitleTable/TitleTable';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../../actions/PopupActions';
import { connect } from 'react-redux';
import {PieChart} from '../../Chart/PieChart';
import {LineChart} from '../../Chart/LineChart';
import { COLORS, FREE_MONEY_COLOR } from '../../Chart/COLORS';
import { toJS } from '../../../HOC/toJS';
import { formatMoney, roundNumber } from '../../../utils';
import { post } from '../../../api/apiHelper';
import Moment from 'react-moment';
import { POP_UP } from '../../../const';
import { useStore } from 'effector-react';
import { Strategy$, strategyApi } from '../../../effector/strategy';

const pieThead = ['', 'Тикер', 'Доля'];

const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

const RecomendationDescriptionTab = ({
  strategyId,
  clientCode,
  onDelete,
  popupActions,
  profile,
  currencyPortfolios
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const strategy = useStore(Strategy$);

  useEffect(() => {
    strategyApi.get(strategyId);
  }, [strategyId]);

  const openRemoveStrategy = () => {
    const popupData = {
      strategyId,
      clientCode
    };

    post('account/unbindstrategy', popupData).then(result => {
      const { errorMessage, success, response } = result;

      if (success) {
        const approvalText = `<p>Спасибо! <br /> Ваша заявка принята.</p>`;
        const data = {
          ...popupData,
          content: response.acceptHtml,
          text: approvalText,
          action: onDelete
        };

        popupActions.openPopup(POP_UP.REMOVE_AUTO_FOLLOWING, data);
      } else {
        setErrorMessage(errorMessage);
      }
    });
  };

  const getTitleTable = () => {
    let titleTable = [
      {
        order: 1,
        title: 'Инвестиционный профиль клиента',
        text: [profile]
      },
      {
        order: 2,
        title: 'Стоимость вложений',
        text: currencyPortfolios
          ? currencyPortfolios.map(item =>
              item.positionsCost
                ? formatMoney(item.positionsCost, item.currency)
                : 0
            )
          : [],
        prefix: 'или'
      },
      {
        order: 3,
        title: 'Ликвидная стоимость',
        text: currencyPortfolios
          ? currencyPortfolios.map(item =>
              item.liquidPrice
                ? formatMoney(item.liquidPrice, item.currency)
                : 0
            )
          : [],
        prefix: 'или'
      }
    ];

    titleTable = titleTable.sort((a, b) => a.order - b.order);

    return titleTable;
  };

  const titlesTable = getTitleTable();

  if (!strategy) {
    return null;
  }

  return (
    <>
      <div className="account-description__row">
        <div className="account-description__title">
          График доходности модельного портфеля
        </div>
      </div>
      <>
        <div className="account-description__chart-info">
          <div className="chart _legend">
            <div className="chart__img account-description__chart-img">
              {strategy.positions && (
                <PieChart
                  data={strategy.positions}
                  textKey={'avgPrice'}
                  valueKey={'weight'}
                  symbolKey={'symbol'}
                />
              )}
            </div>
            <div className="chart__legend account-description__chart-legend">
              <table className="table _bordered account-description__table">
                <thead>
                  <tr data-stagger="">
                    {pieThead.map((item, key) => (
                      <th key={key}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {strategy.positions.map((item, key) => (
                    <tr data-stagger="" key={key}>
                      <td>
                        <div className="chart__item">
                          <div
                            className="chart__color _small"
                            style={{
                              backgroundColor: item.symbol
                                ? COLORS[key]
                                : FREE_MONEY_COLOR
                            }}
                          />
                          <span className="chart__item__name">
                            {item.symbol ? item.name : 'Свободные средства'}
                          </span>
                        </div>
                      </td>
                      {[
                        {
                          title: 'Тикер',
                          text: item.symbol ? item.symbol : ''
                        },
                        {
                          title: 'Доля',
                          text: `${roundNumber(Math.abs(item.weight))}%`
                        }
                      ].map((item, key) => (
                        <td key={key}>
                          <div className="table__row">
                            <div className="table__title">{item.title}</div>
                            <div className="table__content _nobr">
                              {item.text}
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
      <div className="account-description__info _v3">
        <TitleTable data={titlesTable} />
      </div>
      <div style={{ textAlign: 'right' }}>
        <button
          className="account-description__close"
          type="button"
          onClick={openRemoveStrategy}>
          Отключиться от стратегии
        </button>
      </div>
    </>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(toJS(RecomendationDescriptionTab));
