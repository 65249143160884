import { createStore, createApi, createEffect, forward } from 'effector';

import { getStrategies } from '../api';
import { Strategy } from '../types/strategy';

type TStrategies$State = Strategy[];

export const Strategies$ = createStore<TStrategies$State>([]);

const { set } = createApi(Strategies$, {
  set: (state, { result }) => {
    return result.response;
  }
});

export const strategiesApi = {
  get: createEffect().use(getStrategies)
};

forward({
  from: strategiesApi.get.done,
  to: set
});
