import React, { useState } from 'react';
import * as yup from 'yup';
import { Document, Page, pdfjs } from 'react-pdf';
import { Field, Form, Formik, FormikProps } from 'formik';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import { Timer } from '../../../components/Timer';
import { Input } from '../../../components/FormComponents/Input';

import { Actions } from '../Actions';
import { startProfileConfirmation, confirmProfile } from '../../../api';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const defaultSecondsToUnblock = 60;

const validationSchema = yup.object().shape({
  kod: yup.string().required('Введите код подтверждения')
});

const initialValues = {
  kod: ''
};

type FormFields = {
  kod: string;
};

type FormProps = {
  error: string;
  actions: object[];
  started: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ actions, started, error }) => (
    <Form className="pdf-viewer__confirmation-form" noValidate>
      {started && (
        <Field
          placeholder="Код подтверждения"
          name="kod"
          type="text"
          className="pdf-viewer__confirmation-form__label"
          component={Input}
        />
      )}
      {error && <p className="error-message">{error}</p>}
      <Actions items={actions} />
    </Form>
);

type Props = {
  file: string;
  questionaryId: number;
  documentId: string;
  onComplete: () => void;
};

export const PdfViewer: React.FC<Props> = ({
  file,
  questionaryId,
  documentId,
  onComplete
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [isWatched, setWatched] = useState(false);
  const [started, setStarted] = useState(false);
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setErrorMessage] = useState('');

  // @ts-ignore
  const onDocumentLoad = ({ numPages }) => {
    setPageCount(numPages);
  };

  const onTimerFinish = () => {
    setTime(0);
  };

  const startConfirmation = () => {
    const data = {
      questionaryId,
      documentId
    };

    setLoading(true);
    setErrorMessage('');

    startProfileConfirmation(data)
      .then(result => {
        setStarted(true);
        setTime(defaultSecondsToUnblock);
      })
      .catch(error => setErrorMessage(error))
      .finally(() => setLoading(false));
  };

  const endProfileConfirmation = (values: FormFields) => {
    const data = {
      questionaryId,
      documentId,
      confirmationCode: values.kod
    };

    setLoading(true);
    setErrorMessage('');

    confirmProfile(data)
      .then(result => {
        const { success, errorMessage } = result;
        if (success) {
          onComplete();
        } else {
          setErrorMessage(errorMessage);
        }
      }) // eslint-disable-next-line
      .catch(error => setErrorMessage('Произошла непредвиденная ошибка'))
      .finally(() => setLoading(false));
  };

  const reSendButton = {
    loading,
    className: '_large',
    text: 'Отправить снова',
    disabled: time > 0 || loading,
    onClick: startConfirmation
  };

  const timerButton = {
    className: '_large timer',
    text: <Timer time={time} timeFinishCallback={onTimerFinish} />,
    disabled: false
  };

  const confirmButton = {
    loading,
    className: '_primary _large',
    text: 'Подтвердить',
    disabled: !isWatched || loading,
    onClick: started ? endProfileConfirmation : startConfirmation
  };

  return (
    <div className="invest-profile__pdf_viewer">
      <div className="invest-profile__pdf_container">
        <BottomScrollListener onBottom={() => setWatched(true)}>
          {scrollRef => (
            <Document
              file={file}
              className="pdf-document scrollbar"
              error="Не удалось сформировать анкету инвестиционного профилирования"
              loading="Формируем анкету инвестиционного профилирования"
              onLoadSuccess={onDocumentLoad}
              // @ts-ignore
              inputRef={scrollRef}>
              {Array.from(new Array(pageCount), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
        </BottomScrollListener>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={endProfileConfirmation}>
        {props =>
          React.createElement(FormTemplate, {
            ...props,
            error,
            started,
            actions: started
              ? [timerButton, reSendButton, {...confirmButton, onClick: props.submitForm}]
              : [confirmButton]
          })
        }
      </Formik>
    </div>
  );
};
