import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { SEO_CONTENT } from '../../const';

import { faqApi } from '../../effector/faq';
import {
  PAGE_SUPPORT_FAQ_CATEGORY,
  PAGE_SUPPORT_FAQ_QUESTION
} from '../Routes';

import { General } from './General';
import { Question } from './Question';
import { HeaderSearch } from './components/HeaderSearch';
import { Category } from './Category';

export const SupportFaq: React.FC<{}> = React.memo(({}) => {
  const { url } = useRouteMatch();

  useEffect(() => {
    const body = document.querySelector(`body`);
    body?.classList.add('header-no-margin');

    faqApi.get('');
    return () => {
      const body = document.querySelector(`body`);
      body?.classList.remove('header-no-margin');
    };
  }, []);

  const seo = SEO_CONTENT['faq'];

  return (
    <div className={'support-faq'}>
      <Helmet {...seo} />
      <HeaderSearch />
      <Switch>
        <Route exact path={url} component={General} />
        <Route
          exact
          path={`${url}${PAGE_SUPPORT_FAQ_CATEGORY}`}
          component={Category}
        />
        <Route
          exact
          path={`${url}${PAGE_SUPPORT_FAQ_CATEGORY}${PAGE_SUPPORT_FAQ_QUESTION}`}
          component={Question}
        />
      </Switch>
    </div>
  );
});
