import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Icon } from '../../Icon';
import { connect } from 'react-redux';
import { toJS } from '../../../HOC/toJS';
import Button from '../../Button/Button';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../../actions/PopupActions';
import { POP_UP } from '../../../const';
import { PAGE_PERSONAL } from '../../../Pages/Routes';

class Approval extends PureComponent {
  onButtonClick = () => {
    const { history, popupActions } = this.props;

    history.push(PAGE_PERSONAL);
    popupActions.hidePopup(POP_UP.APPROVAL);
  };

  render() {
    const { className, popupData } = this.props;
    return (
      <div className={cn('modal-attention', className)}>
        <div className="modal-attention__icon">
          <Icon
            className="modal-attention__svg"
            name="approval"
            width={136}
            height={109}
          />
        </div>
        <div
          className="modal-attention__text"
          dangerouslySetInnerHTML={{ __html: popupData && popupData.text }}
        />
        <div className="modal-attention__action">
          <Button
            className="_primary _large"
            onClick={this.onButtonClick}
            text="Продолжить"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  popupData: state.getIn(['popup', 'data'])
});

const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(toJS(Approval))
);
