import React, { useMemo, useState } from 'react';
import Autosuggest, {
  RenderSuggestion,
  SuggestionsFetchRequestedParams
} from 'react-autosuggest';

import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';

import { Question } from '../../../../types/questionCategory';
import { Faq$ } from '../../../../effector/faq';

import { ReactComponent as EllipseLeft } from './assets/ellipse-left.svg';
import { ReactComponent as EllipseRight } from './assets/ellipse-right.svg';

import { PAGE_SUPPORT_FAQ } from '../../../Routes';

const escapeRegexCharacters = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const renderSuggestion: React.FC<RenderSuggestion<Question>> = suggestion => (
  // @ts-ignore
  <span>{suggestion.title}</span>
);

export const HeaderSearch: React.FC<{}> = ({}) => {
  const [value, setSearchValue] = useState<string>('');
  const categories = useStore(Faq$);
  const history = useHistory();
  const suggestions: Question[] = useMemo(
    () =>
      categories.reduce(
        (prev, cur) => {
          return [
            ...prev,
            ...cur.questions.map(item => ({ ...item, tag: cur.tag }))
          ];
        },
        [] as Question[]
      ),
    [categories]
  );

  const [options, setOptions] = useState<Question[]>(suggestions);

  const onSearchChange = (
    event: React.SyntheticEvent,
    { newValue }: { newValue: string }
  ) => setSearchValue(newValue);

  const getSuggestionValue = (question: Question) => {
    history.push(`${PAGE_SUPPORT_FAQ}/${question.tag}/${question.friendlyUrl}`);

    return question.title;
  };

  const getSuggestions = (value: string) => {
    const escapedValue = escapeRegexCharacters(value.toLowerCase().trim());

    if (escapedValue === '') {
      return [];
    }

    return suggestions.filter(question =>
      question.title.toLowerCase().includes(escapedValue)
    );
  };

  const onSuggestionsFetchRequested = ({
    value
  }: SuggestionsFetchRequestedParams) => setOptions(getSuggestions(value));

  const onSuggestionsClearRequested = () => setOptions([]);

  return (
    <div className="support-faq_header-search header-search">
      <div className="header-search__images">
        <div className="header-search__images-wrapper">
          <EllipseLeft className="header-search__image-left" />
          <EllipseRight className="header-search__image-right" />
        </div>
      </div>
      <h1 className="support-faq__title">Вопросы и ответы</h1>

      <Autosuggest
        suggestions={options}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        // @ts-ignore
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: 'Поиск по статьям',
          value,
          onChange: onSearchChange
        }}
      />
    </div>
  );
};
