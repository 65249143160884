import { createStore, createApi, createEffect, forward } from 'effector';

import { getTop } from '../api';

// tslint:disable-next-line:no-any
type TStrategiesTop$State = any[];

export const StrategiesTop$ = createStore<TStrategiesTop$State>([]);

const { set } = createApi(StrategiesTop$, {
  set: (state, { result }) => {
    return result.response
  }
});

export const strategiesTopApi = {
  get: createEffect().use(getTop)
};

forward({
  from: strategiesTopApi.get.done,
  to: set
});
