import React, { useMemo } from 'react';
import cn from 'classnames';

import { Table, TableRow } from '../../../../components/Table';

import { Nullable } from '../../../../types/utils';
import { StrategyProfit } from '../../../../types/strategyProfit';

const monthArray = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

const headers = ['', ...monthArray, 'Год' /*'Максимальная просадка'*/];

type Props = {
  profits: Nullable<StrategyProfit[]>;
  className?: string;
};

export const ProfitsTable: React.FC<Props> = ({ profits, className }) => {
  const profitRows = useMemo(() => {
    let rows: TableRow[][] = [];

    if (profits) {
      rows = profits.map(({ year, months, profit /*drawdown*/ }) => {
        const row = [
          {
            title: '',
            content: year.toString()
          },
          ...monthArray.map((month, index) => ({
            title: month,
            content: months && months[index] ? `${months[index]} %` : '-',
            className:
              months && months[index]
                ? months[index] > 0
                  ? 'rise'
                  : 'fall'
                : ''
          })),
          {
            title: 'Год',
            content: `${profit} %`,
            className: profit > 0 ? 'rise' : 'fall'
          }
          // {
          //   title: 'Максимальная просадка',
          //   content: `${drawdown} %`
          // }
        ];

        return row;
      });
    }

    return rows;
  }, [profits]);

  return (
    <div className={cn('profits-table', className)}>
      <Table headers={headers} rows={profitRows} linesCount={2} />
    </div>
  );
};
