import { Author } from '../../Author';
import React from 'react';
import { StarRating, Size } from '../../StarRating';

import {Icon} from '../../Icon';
import { Link } from '../../Link';
import { PAGE_STRATEGIES } from '../../../Pages/Routes';

import cn from 'classnames';
import { enteredAnimate } from '../../../HOC/enteredAnimate';
import { AuthorColorType } from '../../Author';
import { floorPercent, getProgressSymbol } from '../../../utils';
import { Wave } from '../Wave';
import { SimpleLineChart } from '../../Chart/LineChart/SimpleLineChart';

const StableCard = ({
  className,
  profitDescription,
  name,
  caption,
  author,
  actualProfit,
  friendlyUrl,
  num,
  history,
  currency,
  minInvestProfile,
  displayRating
}) => {
  let icon = {
    class: 'stable-card__icon',
    name: 'chevron-right',
    width: 7,
    height: 11
  };

  let styleClass = '';

  switch (num % 9) {
    case 1:
    case 5:
    case 6:
      styleClass = '_violet';
      break;
    case 2:
    case 3:
    case 7:
      styleClass = '_dark';
      break;
    default:
  }

  return (
    <Link
      link={`${PAGE_STRATEGIES}/${friendlyUrl}`}
      className={cn('stable-card', styleClass, className)}>
      <header className="stable-card__header">
        {/* <div className='stable-card__labels-wrap'> */}
        {/*  <div className='stable-card__labels'> */}
        {/*    {labels.map((label, key) => ( */}
        {/*      <span className={cn('label stable-card__label', '_primary')} key={key}> */}
        {/*        {label.text} */}
        {/*      </span> */}
        {/*    ))} */}
        {/*  </div> */}
        {/*  <div className='stable-card__labels-overflow' /> */}
        {/*  <span */}
        {/*    className={cn('label stable-card__counter', counter && [counter.class])} */}
        {/*  /> */}
        {/* </div> */}
        {displayRating && (
          <StarRating
            checked={displayRating}
            size={Size.large}
          />
        )}
      </header>

      <div className="stable-card__content">
        <div className="stable-card__title-info">
          {caption && (
            <span className="stable-card__caption _dark">{caption}</span>
          )}
          {minInvestProfile && (
            <div className="stable-card__profile">{minInvestProfile.name}</div>
          )}
          <div className="stable-card__title"> {name}</div>
        </div>
        <div className="stable-card__info">
          <div className="stable-card__info-wrap">
            <div className="stable-card__info-inner _v2">
              <div
                className={cn('stable-card__value', {
                  fall: actualProfit < 0
                })}>
                {getProgressSymbol(actualProfit)}
                {floorPercent(actualProfit)}%
              </div>
              {currency && (
                <div className="stable-card__caption _light">
                  {profitDescription}
                </div>
              )}
            </div>
            <div className="stable-card__chart">
              <SimpleLineChart
                className="stable-card__chart-content"
                data={[history]}
              />
            </div>
          </div>
        </div>
      </div>

      <footer className="stable-card__footer">
        <Author
          {...author}
          type={styleClass && AuthorColorType.light}
          showLink={false}
        />

        <span className="stable-card__link">
          <Icon {...icon} />
        </span>
      </footer>
      <Wave rootClass="stable-card__waves" childrenClass="stable-card__wave" />
    </Link>
  );
};

export default enteredAnimate(StableCard);
