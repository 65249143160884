import React from 'react';

import { enteredAnimate } from '../../HOC/enteredAnimate';
import { ShortPortfolio } from '../../types/portfolio';
import { PortfolioCard } from './PortfolioCard';

type Props = {
  items: ShortPortfolio[];
};

const PortfolioCardList: React.FC<Props> = ({ items }) => (
  <div>
    <div className="portfolios-cards">
      <div className="portfolios-cards__list">
        {items.map((item, key) => (
          <div className="portfolios-cards__item" key={key}>
            <PortfolioCard {...item} num={key} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

const wrapped = enteredAnimate(PortfolioCardList);

export { wrapped as PortfolioCardList };
