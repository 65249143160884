import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Ticker from 'react-ticker';

import { getProgressSymbol } from '../../../utils';
import { getStrategiesProfits } from '../../../api';
import { PAGE_STRATEGIES } from '../../../Pages/Routes';
import { Link } from '../../Link';

export const Tickers: React.FC<{}> = () => {
  const [tickers, setTickers] = useState([]);
  const [period, setPeriod] = useState('');

  useEffect(() => {
    getStrategiesProfits().then(result => {
      const { response } = result;

      if (response) {
        setPeriod(response.period);
        setTickers(response.profits);
      }
    });
  }, []);

  if (!tickers.length) return null;

  return (
    <div className="ticker-wrapper">
      <div className="ticker-wrapper__period">{period}:</div>
      <div className="ticker-wrapper__values">
        <Ticker>
          {() => (
            <div className="tickers page-header__tickers">
              {tickers.map(({ strategyId, friendlyUrl, strategyName, profit }) => (
                <div key={strategyId} className="tickers__item">
                  <Link
                    link={`${PAGE_STRATEGIES}/${friendlyUrl}`}
                    className="tickers__link">
                    {strategyName}
                  </Link>
                  <div
                    className={classNames('tickers__cell', {
                      _up: profit > 0,
                      _down: profit < 0
                    })}>
                    {getProgressSymbol(profit)}
                    {Math.abs(profit)}%
                  </div>
                </div>
              ))}
            </div>
          )}
        </Ticker>
      </div>
    </div>
  );
};
