import React from 'react';
import classNames from 'classnames';

import { Link } from '../Link';

import { ReactComponent as LogoIcon } from './assets/logo.svg';

type Props = {
  className: string;
};

export const Logo: React.FC<Props> = ({ className }) => (
  <div className={classNames('logo', className)}>
    <Link link="/" className="logo__link">
      <div className="logo__icon">
        <LogoIcon />
      </div>
      <div className="logo__title">проект БКС Брокер</div>
    </Link>
  </div>
);
