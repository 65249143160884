import React, { useState, useEffect } from 'react';

import { getRecentDeals } from '../../../../api';
import { getProgressSymbol } from '../../../../utils';

import { Deal } from '../../../../components/Deal';
import { Nullable } from '../../../../types/utils';

type Props = {
  strategyId: string;
};

export const RecentDeals: React.FC<Props> = ({ strategyId }) => {
  const [deals, setDeals] = useState<Nullable<any[]>>(null);

  useEffect(() => {
    getRecentDeals(strategyId)
      .then(result => {
        const { success, response } = result;

        if (success) {
          setDeals(response);
        }
      }) // eslint-disable-next-line
      .catch(error => {
        console.log('Произошла непредвиденная ошибка');
      });
  }, [strategyId]);

  if (!deals || deals.length === 0) return null;

  return (
    <>
      <div className="strategy-detail__subtitle">Последние сделки</div>
      <div className="recent-deals recent-deals_horizontal">
        {deals.map(({ realizedPl, symbol, name, time, comment }, index) => {
          const title = `${getProgressSymbol(realizedPl)} ${Math.abs(
            realizedPl
          )}% ${symbol} (${name})`;

          return (
            <div className="recent-deal-wrapper" key={index}>
              <Deal
                realizedPl={realizedPl}
                title={title}
                time={time}
                comment={comment}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
