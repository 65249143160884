import React from 'react'

import { Wave } from '../../../components/Cards/Wave'

const questions = [
  'У вас есть интересная и прибыльная торговая стратегия?',
  'Вы хотите получать дополнительный доход?',
  'Вам нужна экспертиза рисков?'
]

export const Questions = () => (
  <div className='experts-questions'>
    <div className='experts-questions__list'>
      {questions.map((item, index) => (
        <div className='experts-questions__item' key={index}>
          {item}
          <div className='experts-questions__item-wave'>
            <Wave
              rootClass='experts-questions__waves'
              childrenClass='experts-questions__wave'
            />
          </div>
        </div>
      ))}
    </div>
    <Wave
      rootClass='experts-questions__waves'
      childrenClass='experts-questions__wave'
    />
  </div>
)
