import React, {useCallback, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { Collapse } from 'react-collapse';

import { Icon } from '../../Icon';
import TitleTable from '../TitleTable/TitleTable';
import { ClientAccount } from '../../../types/clientAccount';
import {
  AccountPortfolios$,
  accountPortfoliosApi
} from '../../../effector/accountPortfolios';
import { PortfolioItem } from './PortfolioItem';

import { formatMoney } from '../../../utils';

type Props = {
  account: ClientAccount;
  state: string;
  refreshAccounts: () => void;
  className?: string;
};

export const AccountPortfolio: React.FC<Props> = ({
  account,
  state,
  refreshAccounts,
  className
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const accountPortfolios = useStore(AccountPortfolios$);

  const portfolios = accountPortfolios[account.clientCode];

  useEffect(() => {
    const data = {
      clientCode: account.clientCode
    };
    accountPortfoliosApi.get(data);
  }, [account]);

  const titleTables = useMemo(() => {
    const titleTable = [
      {
        order: 1,
        title: 'Подключенные портфели',
        text: [`${account.strategyName}`]
      },
      {
        order: 2,
        title: 'Всего средств',
        text: account.currencyPortfolios
          ? account.currencyPortfolios.map(item =>
              item.liquidPrice
                ? formatMoney(item.liquidPrice, item.currency)
                : 0
            )
          : [],
        prefix: 'или'
      },
      {
        order: 4,
        title: 'С момента подключения',
        row: {
          text: `${formatMoney(
            account.totalPnL,
            account.strategyCurrency || 'RUB'
          )} (${account.totalPnLPct}%)`,
          className: account.totalPnL >= 0 ? 'rise' : 'fall'
        }
      }
    ];

    return titleTable;
  }, [
    account.strategyName,
    account.currencyPortfolios,
    account.totalPnL,
    account.totalPnLPct
  ]);

  const onRemovePortfolio = useCallback(() => {
    setIsOpen(false);
    refreshAccounts();
  }, [setIsOpen, refreshAccounts]);

  if (!account) {
    return null;
  }

  return (
    <article
      className={cn(
        'account',
        { _expanded: isOpen, _blocked: state },
        className
      )}>
      {state && (
        <div className="account__block">
          <div className="account__block-item">
            {state}
          </div>
        </div>
      )}
      <header className="account__header">
        <div className="account__number">{`${account.agreementId}`}</div>
        <button
          className="account__toggle"
          type="button"
          onClick={() => setIsOpen(!isOpen)}>
          <Icon
            className="card__icon"
            name="chevron-down"
            width={11}
            height={7}
          />
        </button>
      </header>
      <TitleTable data={titleTables} />
      <Collapse isOpened={isOpen}>
        <div className="account__detail" data-expandable-content="">
          {portfolios &&
            portfolios.map(item => (
              <PortfolioItem
                key={item.id}
                portfolio={item}
                clientCode={account.clientCode}
                onRemovePortfolio={onRemovePortfolio}
              />
            ))}
        </div>
      </Collapse>
    </article>
  );
};
