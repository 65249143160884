import { createStore, createApi, createEffect, forward } from 'effector';

import { getAuthorArticles } from '../api';
import { Nullable } from '../types/utils';
import { AnalyticsBrief } from '../types/analyticsBrief';

type TAuthorArticles$State = Nullable<AnalyticsBrief[]>;

export const AuthorArticles$ = createStore<TAuthorArticles$State>(null);

const { set } = createApi(AuthorArticles$, {
  set: (state, { result }) => {
    return result.response;
  }
});

export const authorArticlesApi = {
  get: createEffect().use(getAuthorArticles)
};

forward({
  from: authorArticlesApi.get.done,
  to: set
});
