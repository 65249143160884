import { useStore } from 'effector-react';
import React from 'react';
import Helmet from 'react-helmet';

import Cards from '../../../components/Cards/Cards';
import { Author$ } from '../../../effector/author';
import { Strategy } from '../../../types/strategy';

type Props = {
  strategies: Strategy[]
};

export const StrategiesSection: React.FC<Props> = ({ strategies }) => {
  const author = useStore(Author$);

  const meta = {
    title: `${author?.firstName} ${author?.lastName} — инвестиционные стратегии от эксперта Fintarget`,
    meta: [
      {
        name: 'description',
        content:
          `${author?.firstName} ${author?.lastName} - представляет инвестиционные стратегии для торговли на биржена сайте Fintarget. Автоследование от лучших трейдеров Fintarget.`
      }
    ]
  };

  return (
    <>
      {meta && <Helmet {...meta} />}
      <div className="tabs__tab _active">
        <Cards items={strategies} />
      </div>
    </>
  );
};
