import React from 'react';
import { useStore } from 'effector-react';

import { Faq$ } from '../../../../effector/faq';
import { Link } from '../../../../components/Link';

import { PAGE_SUPPORT_FAQ } from '../../../Routes';

import { QuestionCategory } from '../../../../types/questionCategory';

export const Sidebar: React.FC<{}> = ({}) => {
  const categories = useStore<QuestionCategory[]>(Faq$);

  return (
    <div className="support-faq__sidebar">
      <ul className="support-faq__sidebar_list">
        {categories.map((item, index) => (
          <li key={index} className="support-faq__sidebar_list_item">
            <Link link={`${PAGE_SUPPORT_FAQ}/${item.tag}`}>{item.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
