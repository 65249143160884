import React, { useState } from 'react'
import moment from 'moment'
import InputMask from 'react-input-mask'

import { Actions } from '../../Actions'

moment.locale('ru')

const DATE_LENGTH = 10

const DatePickerView = ({ error, setError, setValue }) => {
  const onChange = e => {
    const value = e.target.value

    setError('')

    if (value.length === DATE_LENGTH) {
      const inputDate = moment(value, 'DD.MM.YYYY')
      const dateIsValid = inputDate.isBefore(moment().subtract(18, 'years'))

      !dateIsValid && setError('Некорректная дата рождения')
    }
    setValue(value)
  }

  return (
    <>
      <label className='date-picker__field'>
        <div className='date-picker__field_placeholder'>Дата рождения</div>
        <div>
          <InputMask
            mask={'99.99.9999'}
            maskChar={null}
            placeholder='10.10.1999'
            onChange={onChange}
          />
        </div>
      </label>
      {error && <p className='date-picker__error'>{error}</p>}
    </>
  )
}

export const DatePickerComponent = ({
  loading,
  questionCode,
  error,
  items,
  canGoBack,
  onComplete,
  goBack
}) => {
  const [startDate, setStartDate] = useState('')
  const [errorDate, setError] = useState('')

  const onSubmit = () => {
    const result = {
      questionCode,
      answers: [
        {
          answerCode: items[0].answerCode,
          date: moment(startDate, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ssZ')
        }
      ]
    }
    onComplete(result)
  }

  const backButton = {
    loading,
    className: '_large',
    text: 'Назад',
    disabled: loading,
    onClick: goBack
  }

  const nextButton = {
    loading,
    className: '_primary _large',
    text: 'Далее',
    disabled: errorDate || startDate.length !== DATE_LENGTH || loading,
    onClick: onSubmit
  }

  return (
    <>
      <DatePickerView
        setValue={setStartDate}
        error={errorDate}
        setError={setError}
      />
      {error && <div className='invest-profile__error'> {error}</div>}
      <Actions items={canGoBack ? [backButton, nextButton] : [nextButton]} />
    </>
  )
}
