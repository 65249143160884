import React, {useEffect} from 'react';
import {useStore} from 'effector-react';
import {useParams, Switch, useRouteMatch, Route, NavLink} from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {Author} from '../../components/Author';
import {Section} from '../../components/Section';

import {Author$, authorApi} from '../../effector/author';
import {AuthorStrategy$, authorStrategyApi} from '../../effector/authorStrategies';
import {AuthorArticles$, authorArticlesApi} from '../../effector/authorArticles';
import {PAGE_AUTHOR_ARTICLES, PAGE_AUTHOR_STRATEGIES} from '../Routes';
import {AboutSection} from './About';
import {StrategiesSection} from './Strategies';
import {ArticlesSection} from './Articles';
import Helmet from 'react-helmet';

const defaultCrumbs = [
  {
    active: false,
    link: '/experts',
    text: 'Авторы'
  }
];

export const AuthorPage: React.FC<{}> = ({}) => {
  const {url} = useRouteMatch();
  const {id} = useParams<{id: string}>();

  const author = useStore(Author$);
  const strategies = useStore(AuthorStrategy$);
  const articles = useStore(AuthorArticles$);

  const ABOUT_ROUTE = `${url}`;
  const STRATEGIES_ROUTE = `${url}${PAGE_AUTHOR_STRATEGIES}`;
  const ARTICLES_ROUTE = `${url}${PAGE_AUTHOR_ARTICLES}`;

  useEffect(() => {
    authorApi.get(id);
    authorStrategyApi.get(id);
    const params = {
      id,
      count: 12
    };

    authorArticlesApi.get(params);
  }, [id]);

  const tabs = [
    {
      name: 'Об авторе',
      path: ABOUT_ROUTE
    },
    {
      name: 'Стратегии',
      path: STRATEGIES_ROUTE
    },
    {
      name: 'Статьи',
      path: ARTICLES_ROUTE
    }
  ];

  const meta = {
    title: `${author?.firstName} ${author?.lastName} / Fintarget.ru - Автоследование в БКС Брокер`,
    meta: [
      {
        name: 'description',
        content:
          'Инвестиционные стратегии для торговли на бирже от экспертов БКС Брокер в формате автоследования'
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href
      }
    ],
  };

  // @ts-ignore
  return (
    <>
      {meta && <Helmet {...meta} />}
      <Breadcrumbs items={[...defaultCrumbs, {active: true, text: `${author?.firstName} ${author?.lastName}`}]}/>

      <section className="author-detail">
        <div className="author-detail__header">
          <div className="author-detail__author">
            {author && (
              // @ts-ignore
              <Author className="_personal" {...author} showLink={false} />
            )}
          </div>
        </div>
        <hr className="author-detail__hr"/>
      </section>
      <Section>
        <div className="section__content">
          <div className="tabs _static">
            <div className="tabs__nav-wrapper">
              <div className="tabs__nav">
                {tabs.map(({name, path}) => (
                  <NavLink
                    key={path}
                    to={path}
                    exact
                    className="tabs__nav-item"
                    activeClassName="_active">
                    {name}
                  </NavLink>
                ))}
              </div>
            </div>
            <div className="tabs__content-wrapper">
              <Switch>
                <Route
                  exact
                  path={ABOUT_ROUTE}
                  render={props => <AboutSection {...props} description={author?.infoHtml || ''}/>}
                />
                <Route
                  exact
                  path={STRATEGIES_ROUTE}
                  render={props => <StrategiesSection {...props} strategies={strategies || []}/>}
                />
                <Route
                  exact
                  path={ARTICLES_ROUTE}
                  render={props => <ArticlesSection {...props} articles={articles || []}/>}
                />
              </Switch>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};
