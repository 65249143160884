import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {Icon} from '../../Icon';
import { PAGE_INVEST_PROFILE } from '../../../Pages/Routes';
import { setPath } from '../../../actions/RouterInfoActions';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../../actions/PopupActions';
import { POP_UP } from '../../../const';
import { useStore } from 'effector-react';
import { Strategy$ } from '../../../effector/strategy';

const Attention = ({ className, location, history, setPath, popupActions }) => {
  const strategy = useStore(Strategy$);

  const onClick = () => {
    setPath({
      path: location.pathname,
      profile: strategy?.minInvestProfile?.id
    });
    history.push(PAGE_INVEST_PROFILE);
    popupActions.hidePopup(POP_UP.ATTENTION);
  };

  return (
    <div className={cn('modal-attention', className)}>
      <div className="modal-attention__icon">
        <Icon
          className="modal-attention__svg"
          name="attention"
          width={136}
          height={109}
        />
      </div>
      <div className="modal-attention__text">
        Ваш текущий инвестиционный профиль не соответствует
        <br />
        параметрам стратегии. Вы можете пройти процедуру присвоения
        инвестиционного профиля снова. Сделать это можно по{' '}
        <span
          style={{ textDecoration: 'none', cursor: 'pointer' }}
          className="modal__link"
          onClick={onClick}>
          ссылке
        </span>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setPath: data => dispatch(setPath(data)),
  popupActions: bindActionCreators(PopupActions, dispatch)
});

const wrappedComponent = withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Attention)
);

export { wrappedComponent as Attention };
