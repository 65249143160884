import React, { useState } from 'react'
import cs from 'classnames'
import { useField } from 'formik'

export const TextArea = ({
  field: fieldProp,
  className,
  rows,
  placeholder
}) => {
  const [focus, setFocus] = useState(false)
  const [field, meta] = useField(fieldProp.name)
  const isError = meta.touched && meta.error
  const isDirty = meta.value !== meta.initialValue

  const onFocus = () => setFocus(true)
  const onBlur = (e) => {
    field.onBlur(e)
    setFocus(false)
  }

  return (
    <label
      className={cs('form-control', className, { _focus: focus || isDirty })}
    >
      <textarea
        className={cs('form-control__field _textarea', { _invalid: isError })}
        placeholder={placeholder}
        rows={rows}
        onBlur={onBlur}
        onFocus={onFocus}
        required
        {...field}
      />
      {isError && <span className='form-control__message'>{meta.error}</span>}
      <span className='form-control__placeholder'>{placeholder}</span>
    </label>
  )
}
