import React, { useState } from 'react';
import * as Yup from 'yup';
import cn from 'classnames';
import { Field, Form, Formik, FormikProps } from 'formik';

import { post } from '../../../api/apiHelper';

import { required } from '../../../utils/Form/formik-validation';

import { GAService } from '../../../services/gaService';

import { Link } from '../../Link';
import Button from '../../Button/Button';
import {Section} from '../../Section';

import { Input } from '../../FormComponents/Input';
import { Checkbox } from '../../FormComponents/Checkbox';
import { TextArea } from '../../FormComponents/TextArea';
import { InputPhone } from '../../FormComponents/InputPhone';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(required),
  phone: Yup.string().required(required),
  description: Yup.string().required(required),
  rules: Yup.boolean().required(required)
});

const initialValues = {
  name: '',
  phone: '',
  description: '',
  rules: false
};

type FormFields = {
  name: string;
  phone: string;
  description: string;
  rules: boolean;
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ loading, error, values }) => (
    <Form className="section__form" noValidate>
      <div className="form-control-row">
        <Field placeholder="Имя" name="name" type="text" component={Input} />
      </div>

      <div className="form-control-row">
        <Field placeholder="Телефон" name="phone" component={InputPhone} />
      </div>

      <div className="form-control-row">
        <Field
          /* tslint:disable-next-line:no-magic-numbers */
          rows={5}
          name="description"
          placeholder="Вопрос"
          component={TextArea}
        />
      </div>

      <div className="form-control-row">
        <Field
          name="rules"
          text={`Даю согласие на обработку <a class='checkbox__link' href='https://broker.ru/sdobs/assets/agreement.pdf'>персональных&nbsp;данных</a>`}
          component={Checkbox}
        />
      </div>

      <div>
        <Button
          type="submit"
          loading={loading}
          disabled={!values.rules}
          className="_primary _large _wide"
          text="Отправить"
        />
      </div>

      {error && <p className="error-message">{error}</p>}
    </Form>
  );

type Props = {
  name: string;
  eventName: string;
  className?: string;
};

export const AskQuestion: React.FC<Props> = ({ name, eventName, className, children }) => {
  const [formSuccess, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleSubmit = (values: FormFields) => {
    const request = {
      question: {
        name: values.name,
        description: values.description,
        phone: values.phone
      },
      accept: values.rules
    };

    setLoading(true);
    setError('');

    GAService.sendEvent(eventName);

    post('questions/add', request)
      .then(({ success, errorMessage }) => {
        if (success) {
          setSuccess(true);
        } else {
          setError(errorMessage);
          setLoading(false);
        }
      })
      .catch(errorMessage => {
        setError(errorMessage);
        setLoading(false);
      });
  };

  return (
    <Section className={cn('_form', className)}>
      <div className="section__inner">
        <div className="section__content">
          <div className="section__title">{name}</div>
          <p className="section__desc">
            {children}
          </p>
        </div>
        {formSuccess && (
          <div className={'section__form'}>
            <div className="section__desc">
              <div className="section__subtitle">Спасибо!</div>
              <p className="section__success">
                Мы получили ваш запрос и в ближайшее время на него ответим!
              </p>
            </div>
          </div>
        )}
        {!formSuccess && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            validateOnBlur
            onSubmit={handleSubmit}>
            {props =>
              React.createElement(FormTemplate, {
                ...props,
                loading,
                error
              })
            }
          </Formik>
        )}
      </div>
    </Section>
  );
};
