import { Author } from './author';
import { Index } from './index';
import { StrategyPosition } from './strategyPosition';
import { ChartPoint } from './chartPoint';
import { Market } from './market';
import { Tool } from './tool';
import { Service } from './service';
import { PriceCategory } from './priceCategory';
import { StrategyComission } from './strategyComission';
import { InvestProfile } from './investProfile';
import { Currency } from './currency';
import { Duration } from './duration';
import { Nullable } from './utils';

export type Range = {
  start: number;
  end: number;
};

export type Label = {
  id: number;
  text: string;
  tooltip: string;
  foreColor: string;
  backColor: string;
};

type Board = {
  id: number;
  code: string;
  name: string;
  description: string;
};

export type Test = {
  id: string;
  name: string;
}

export enum ServiceKind {
  AF = 1,
  AC = 2
}

type Campaign = {
  serviceKind: ServiceKind;
  campaignName: string;
  tariffDisplayValue: string;
  tariffInfo: string;
  tariffDescription: string;
};

export type Strategy = {
  id: string;
  friendlyUrl: string;
  name: string;
  maxInstrumentFraq?: number;
  autoconsult: boolean;
  tradesFrequency: string;
  autofollow: boolean;
  iis: boolean;
  rating: number;
  displayRating: number;
  tarif: number;
  showFullPortfolio: boolean;
  index: Index;
  positions: StrategyPosition[];
  indexId?: number;
  plStartDate?: string;
  priceAC: string;
  priceAF: string;
  isFree: boolean;
  forQualifiedInvestorsOnly: boolean;
  isClientAttached: boolean;
  // public List<ClientAccountsAvailability> ClientAccountsAvailability { get; set; }
  pnLRange: Range;
  subscriptionThresholdRange: Range;
  indexHistory: ChartPoint[];
  comissionHistory: ChartPoint[];
  positionsCount: number;
  tools: Tool[];
  markets: Market[];
  services: Service[];
  category: PriceCategory;
  history: ChartPoint[];
  comissionObjects: StrategyComission[];
  price?: string;
  chartComment?: string;
  estimatedProfit: number;
  estimatedRisk: number;
  subscriptionThreshold: number;
  subscriptionThresholdRub: number;
  minInvestProfile: InvestProfile;
  currency: Currency;
  pictureBase64?: string;
  pictureFormat: string;
  descriptionHtml: string;
  infoHtml: string;
  tag: string;
  author: Author;
  toolDrawndown: number;
  testMode: boolean;
  duration: Duration;
  actualProfit: number;
  startDate: string;
  minInvestCalculation: number;
  benchmarkComparison: number;
  actualDrawdown: number;
  order: number;
  capacity: number;
  isAlgostrategy: boolean;
  maxIndustryWeight: number;
  maxPositionWeight: number;
  leverage: number;
  open: boolean;
  recommended: boolean;
  labels: Label[];
  unbindNote: Nullable<string>;
  seoTitle: string;
  seoDescription: string;
  videoUrl: string;
  strategyBoards: Board[];
  campaigns: Nullable<Campaign[]>;
  requiredTests: Nullable<Test[]>;
  requiredTestsLink: Nullable<string>;
};
