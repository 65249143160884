import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { toJS } from '../../HOC/toJS';

import { Logo } from '../Logo';
import { Tickers } from './Tickers';
import { Navbar } from '../PageHeader/Navbar';

export const LARGE_CLASS = '_large';

type Props = {
  className: string;
  stateClassName: string;
};

const PageHeader: React.FC<Props> = ({ className, stateClassName }) => (
  <header className={classNames('page-header', className, stateClassName)}>
    <div className="page-header__container">
      <div className="page-header__main">
        <Logo className="_light _large" />
        <Navbar />
      </div>
      <Tickers />
    </div>
  </header>
);

// tslint:disable-next-line:no-default-export
export default connect(state => ({
  // @ts-ignore
  stateClassName: state.getIn(['pageHeader', 'className'])
  // @ts-ignore
}))(toJS(PageHeader));
