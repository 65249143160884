import React, { PureComponent } from 'react'
import cn from 'classnames'

class TitleTable extends PureComponent {
  render () {
    const {
      // eslint-disable-next-line
      className,
      // eslint-disable-next-line
      data
    } = this.props

    return (
      <div className={cn('account__content', className)}>
        {data.map((item, key) => (
          <div className={`account__info-item _v${item.order}`} key={key}>
            <div className='account__info-item-title'>{item.title}</div>
            {item.text &&
              item.text.map((money, ind) => {
                const prefix =
                  item.prefix && ind === 1 ? (
                    <span key={ind} className='account__info-item-prefix'>
                      {item.prefix}
                    </span>
                  ) : null

                return (
                  <div
                    key={ind}
                    className={cn('account__info-item-text', item.className)}
                  >
                    {prefix} {money}
                  </div>
                )
              })}

            {item.row && (
              <div>
                <div
                  className={cn(
                    'account__info-item-text',
                    item.row.className
                  )}
                >
                  {item.row.text}
                  {item.row.date && (
                    <div className='account__info-item-prefix'>
                      на {item.row.date}{' '}
                    </div>
                  )}
                </div>
                {item.row.img}
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }
}

export default TitleTable
