import { createStore, createApi, createEffect, forward } from 'effector';

import { getTools } from '../api';
import { Option } from '../types/option';

type TMarkets$State = Option[];

export const Tools$ = createStore<TMarkets$State>([]);

const { set } = createApi(Tools$, {
  set: (state, { result }) => result.response
});

export const toolsApi = {
  get: createEffect().use(getTools)
};

forward({
  from: toolsApi.get.done,
  to: set
});
