import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Sidebar } from '../components/Sidebar';
import { BreadcrumbsLocal } from '../../../components/Breadcrumbs/BreadcrumbsLocal';

import { Faq$ } from '../../../effector/faq';
import { Nullable } from '../../../types/utils';
import {
  Question as QuestionType,
  QuestionCategory
} from '../../../types/questionCategory';
import { PAGE_SUPPORT_FAQ } from '../../Routes';
import Helmet from 'react-helmet';

export const Question: React.FC<{}> = ({}) => {
  const [questionEntity, setQuestion] = useState<Nullable<QuestionType>>(null);
  const [questionCategory, setQuestionCategory] = useState<
    Nullable<QuestionCategory>
  >(null);

  const categories = useStore(Faq$);
  const { category, question } = useParams<{category: string, question: string}>();
  const history = useHistory();

  useEffect(() => {
    const foundedCategory: Nullable<QuestionCategory> = categories.find(
      item => item.tag === category
    );
    const foundedQuestion = foundedCategory?.questions.find(
      item => item.friendlyUrl === question
    );

    setQuestionCategory(foundedCategory);
    setQuestion(foundedQuestion);
  }, [categories, category, question]);

  useEffect(() => {
    // @ts-ignore
    if (window?.MathJax) {
      // @ts-ignore
      setTimeout(() => window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]), 500);
    }
    // @ts-ignore
  }, [questionEntity, window?.MathJax]);

  const defaultCrumbs = [
    {
      text: 'Все вопросы',
      onClick: () => history.push(PAGE_SUPPORT_FAQ)
    },
    {
      text: questionCategory?.name,
      onClick: () => history.push(`${PAGE_SUPPORT_FAQ}/${questionCategory?.tag}`)
    }
  ];

  const meta = {
    title: `${questionEntity?.title} / Fintarget.ru - Автоследование в БКС Брокер`,
    meta: [
      {
        name: 'description',
        content:
          'Инвестиционные стратегии для торговли на бирже от экспертов БКС Брокер в формате автоследования'
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href
      }
    ],
  };

  return (
    <>
      {meta && <Helmet {...meta} />}
      <div className="support-faq__faqs">
        <Sidebar />
        <div className="faq-question">
          <BreadcrumbsLocal items={defaultCrumbs} />
          {questionEntity ? (
            <>
              <h1 className="faq-question__title">{questionEntity.title}</h1>
              <div
                className="faq-question__description"
                dangerouslySetInnerHTML={{ __html: questionEntity.text }}
              />
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
