import React, { useState } from 'react';
import {useParams, useRouteMatch} from 'react-router-dom';

import { Link } from '../../../../components/Link';

import { Question } from '../../../../types/questionCategory';

const MAX_SIZE = 3;

type Props = {
  title: string;
  questions: Question[];
  groupTag: string;
  limit?: number;
};

export const QuestionList: React.FC<Props> = ({
  title,
  questions,
  groupTag,
  limit = MAX_SIZE
}) => {
  const [size, setSize] = useState<number>(limit);
  const { url } = useRouteMatch();
  const { category } = useParams<{category: string}>();

  const categoryLink = category ? url : `${url}/${groupTag}`;

  return (
    <>
      <Link link={categoryLink} className="faq-question-list__title">
        {title}
      </Link>
      <ul className="faq-question-list__questions">
        {questions.map((question, ind) => {
          if (ind <= size) {
            const questionLink = category ? `${url}/${question.friendlyUrl}` : `${url}/${groupTag}/${question.friendlyUrl}`
            return (
              <li key={ind}>
                <Link
                  link={questionLink}
                  className="faq-question-list__question">
                  {question.title}
                </Link>
              </li>
            );
          }
        })}
        {size < questions.length && (
          <li
            className="faq-question-list__question faq-question-list__more"
            onClick={() => setSize(questions.length)}>
            Показать еще
          </li>
        )}
      </ul>
    </>
  );
};
