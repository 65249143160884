import React from 'react'

import { Actions } from '../Actions'
import Slider from 'rc-slider'

// eslint-disable-next-line
export const ViewProfile = ({ title, value, description, error, loading, onCancel, onConfirm }) => {
  return (
    <div className='view-profile'>
      <div className='view-profile__title'>
        Ваш инвестиционный профиль: {title}
      </div>
      <div className='view-profile__description'>{description}</div>
      <div className='view-profile__visualisation range-slider _danger' >
        <Slider
          min={1}
          max={7}
          marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7 }}
          included={false}
          value={value}
        />
      </div>
      {error && <div className='invest-profile__error'> {error}</div>}
      <Actions
        items={[
          {
            loading,
            className: '_primary _large view-profile__retry',
            text: 'Перепройти',
            disabled: loading,
            onClick: onCancel
          },
          {
            loading,
            className: '_primary _large',
            text: 'Подтвердить',
            disabled: loading,
            onClick: onConfirm
          }
        ]}
      />
    </div>
  )
}
