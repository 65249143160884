import React from 'react';
import cn from 'classnames';
import Moment from 'react-moment';

// @ts-ignore
import { ReactComponent as DealUp } from './deal-up.svg';
// @ts-ignore
import { ReactComponent as DealFail } from './deal-fail.svg';

type Props = {
  realizedPl: number;
  title: string;
  time: string;
  comment: string;
  subTitle?: string;
  className?: string;
};

export const Deal: React.FC<Props> = ({
  realizedPl,
  title,
  subTitle,
  time,
  comment,
  className
}) => {
  return (
    <div className={cn('recent-deal', className)}>
      <div className="recent-deal__header">
        {realizedPl >= 0 ? <DealUp /> : <DealFail />}
        <div
          className={cn('recent-deal__title', {
            'recent-deal__title-up': realizedPl >= 0,
            'recent-deal__title-fail': realizedPl < 0
          })}>
          {title}
        </div>
      </div>
      {subTitle && <div className="recent-deal__sub-title">{subTitle}</div>}
      <div className="recent-deal__description" dangerouslySetInnerHTML={{__html: comment}} />
      <div className="recent-deal__date">
        <Moment format="DD.MM.YYYY" locale="ru">
          {time}
        </Moment>
      </div>
    </div>
  );
};
