import React from 'react'
import cn from 'classnames'
import Button from '../Button/Button'
import { enteredAnimate } from '../../HOC/enteredAnimate'
import { toJS } from '../../HOC/toJS'
import { formatMoney } from '../../utils'
import { GAService } from '../../services/gaService'
import { withRouter } from 'react-router-dom'
import { PAGE_STRATEGIES } from '../../Pages/Routes'

const AccountMain = ({
  // eslint-disable-next-line
  className,
  // eslint-disable-next-line
  title,
  // eslint-disable-next-line
  currencyPortfolios,
  // eslint-disable-next-line
  state,
  // eslint-disable-next-line
  history
}) => (
  <article className={cn('account', { _blocked: state }, className)}>
    {state && (
      <div className='account__block'>
        <div className='account__block-item'>{state}</div>
      </div>
    )}
    <div className='account__main-info'>
      <div className='account__number'>{title}</div>
      <div className='account__content'>
        <div className='account__info-item'>
          <div className='account__info-item-title'>Всего средств</div>
          {currencyPortfolios &&
            currencyPortfolios.map((item, ind) => {
              const prefix =
                ind === 1 ? (
                  <span key={ind} className='account__info-item-prefix'>
                    или
                  </span>
                ) : null

              return (
                <div
                  key={ind}
                  className={cn('account__info-item-text', item.className)}
                >
                  {prefix} {item.liquidPrice ? formatMoney(item.liquidPrice, item.currency) : 0}
                </div>
              )
            })}
        </div>
        <div className='account__info-item'>
          <div className='account__info-item-title'>Свободные средства</div>
          {currencyPortfolios &&
            currencyPortfolios.map((item, ind) => {
              const prefix =
                ind === 1 ? (
                  <span key={ind} className='account__info-item-prefix'>
                    и
                  </span>
                ) : null

              return (
                <div key={ind} className='account__info-item-text'>
                  {prefix} {formatMoney(item.freeFunds, item.currency)}
                </div>
              )
            })}
        </div>
      </div>
    </div>
    <div className='account__main-content'>
      <div className='account__main-card'>
        <div className='account__main-card-container'>
          <div className='account__main-text'>
            <p className='account__main-text-grey'>
              Стратегия: не подключена
            </p>
          </div>
          <div className='account__main-button'>
            <Button
              onClick={() => {
                GAService.sendEvent('PersonalSelectStrategy')
                history.push(PAGE_STRATEGIES)
              }}
              className='_primary _large _small'
              text='Выбрать стратегию'
            />
          </div>
        </div>
      </div>
    </div>
  </article>
)

export default enteredAnimate(withRouter(toJS(AccountMain)))
