import React from 'react';

import { ReactComponent as ManIcon } from './man.svg';
import { ReactComponent as CircleIcon } from './circle.svg';
import { ReactComponent as ManMobileIcon } from './man-mobile.svg';

export const HeaderList: React.FC<{}> = ({ children }) => (
  <div className="header-list">
    <div className="header-list__container">
      <div className="header-list__content">
        <p className="header-list__content-subtitle">{children}</p>
      </div>
      <div className="header-list__image">
        <ManIcon className="header-list__icon header-list__icon-type" />
        <ManMobileIcon className="header-list__icon header-list__icon-type-mobile" />
        <CircleIcon className="slider-item__icon-circle" />
      </div>
    </div>
  </div>
);
