import React from 'react';
import Helmet from 'react-helmet';

import { Statistics } from './Statistics';
import { Documents } from './Documents';
import { Advantages } from './Advantages';
import { Request } from './Request';
import { Questions } from './Questions';
import { SEO_CONTENT } from '../../const';

export const ExpertsPage = () => {
  const seo = SEO_CONTENT['experts'];
  return (
    <>
      {seo && <Helmet {...seo} />}
      <div className="page-experts">
        <div className="page-experts__col page-experts__col-left">
          <Questions />
          <Advantages />
          <Request />
        </div>
        <div className="page-experts__col page-experts__col-right">
          <div className="page-experts__col-block">
            <Statistics />
          </div>
          <div className="page-experts__col-block">
            <Documents />
          </div>
        </div>
      </div>
    </>
  );
};
