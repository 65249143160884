import React from 'react';
import classNames from 'classnames';

import {Link} from '../Link';
import { enteredAnimate } from '../../HOC/enteredAnimate';

const Breadcrumbs = ({ className, items }) => (
  <nav className={classNames('breadcrumbs', className)}>
    <ul className="breadcrumbs__list">
      <li className="breadcrumbs__item">
        <Link link="/" className="breadcrumbs__link _home">
          Главная
        </Link>
      </li>
      {items.map(({ link, text, active }, key) => (
        <li
          key={key}
          className={classNames('breadcrumbs__item', { _active: active })}>
          <Link link={link} className="breadcrumbs__link">
            {text}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

export default enteredAnimate(Breadcrumbs);
