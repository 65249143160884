import Cookies from 'js-cookie';

export const UTM_PARAMS = {
  REF_ID: 'refid',
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_TERM: 'utm_term'
};

class QueryService {
  constructor() {
    this._refId = Cookies.get(UTM_PARAMS.REF_ID) || '';
    this._utmSource = Cookies.get(UTM_PARAMS.UTM_SOURCE) || '';
    this._utmMedium = Cookies.get(UTM_PARAMS.UTM_MEDIUM) || '';
    this._utmCampaign = Cookies.get(UTM_PARAMS.UTM_CAMPAIGN) || '';
    this._utmContent = Cookies.get(UTM_PARAMS.UTM_CONTENT) || '';
    this._utmTerm = Cookies.get(UTM_PARAMS.UTM_TERM) || '';
  }

  get refId() {
    return this._refId || '';
  }

  get utmSource() {
    return this._utmSource || '';
  }

  get utmMedium() {
    return this._utmMedium || '';
  }

  get utmCampaign() {
    return this._utmCampaign || '';
  }

  get utmContent() {
    return this._utmContent || '';
  }

  get utmTerm() {
    return this._utmTerm || '';
  }

  set refId(refId) {
    this._refId = refId;
    Cookies.set(UTM_PARAMS.REF_ID, refId, { expires: 90 });
  }

  set utmSource(utmSource) {
    this._utmSource = utmSource;
    Cookies.set(UTM_PARAMS.UTM_SOURCE, utmSource, { expires: 90 });
  }

  set utmMedium(utmMedium) {
    this._utmMedium = utmMedium;
    Cookies.set(UTM_PARAMS.UTM_MEDIUM, utmMedium, { expires: 90 });
  }

  set utmCampaign(utmCampaign) {
    this._utmCampaign = utmCampaign;
    Cookies.set(UTM_PARAMS.UTM_CAMPAIGN, utmCampaign, { expires: 90 });
  }

  set utmContent(utmContent) {
    this._utmContent = utmContent;
    Cookies.set(UTM_PARAMS.UTM_CONTENT, utmContent, { expires: 90 });
  }

  set utmTerm(utmTerm) {
    this._utmTerm = utmTerm;
    Cookies.set(UTM_PARAMS.UTM_TERM, utmTerm, { expires: 90 });
  }
}

const QueryServiceInstance = new QueryService();

export { QueryServiceInstance as QueryService };
