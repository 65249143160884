import { combineReducers } from 'redux-immutable';
import { reducer as remoteData } from '@aic/react-remote-data-provider';
import { popup } from './popup';
import { pageHeader } from './pageHeader';
import { signal } from './signal';
import { position } from './position';
import { history } from './history';
import { recommendation } from './recommendation';
import { routerInfo } from './routerInfo';

export const rootReducer = combineReducers({
  remoteData,
  popup,
  pageHeader,
  signal,
  position,
  history,
  recommendation,
  routerInfo
});
