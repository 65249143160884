import React, { useState } from 'react'

import { Answer } from '../Answer'
import { Actions } from '../../Actions'

export const RadioButtonList = ({
  loading,
  questionCode,
  error,
  items,
  canGoBack,
  onComplete,
  goBack
}) => {
  const [answer, setAnswer] = useState({})

  const inAnswers = item => answer.answerCode === item.answerCode

  const onAnswerClick = item => {
    setAnswer(item)
  }

  const onSubmit = () => {
    const result = {
      questionCode,
      answers: [answer]
    }

    setAnswer({})
    onComplete(result)
  }

  const backButton = {
    loading,
    className: '_large',
    text: 'Назад',
    disabled: loading,
    onClick: goBack
  }

  const nextButton = {
    loading,
    className: '_primary _large',
    text: 'Далее',
    disabled: Object.keys(answer).length === 0 || loading,
    onClick: onSubmit
  }

  return (
    <>
      {items.map(item => (
        <Answer
          key={item.answerCode}
          isSelected={inAnswers(item)}
          text={item.value}
          onClick={() => {
            onAnswerClick({
              answerCode: item.answerCode
            })
          }}
        />
      ))}
      {error && <div className='invest-profile__error'> {error}</div>}
      <Actions items={canGoBack ? [backButton, nextButton] : [nextButton]} />
    </>
  )
}
