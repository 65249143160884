import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useStore } from 'effector-react';
import noun from 'plural-ru';
import Helmet from 'react-helmet';

import { Categories$, categoriesApi } from '../../effector/categories';
import { Articles$, articlesApi } from '../../effector/articles';

import { ArticleCardList } from '../../components/Cards/ArticleCardList';
import {SubscribeToAnalytics} from '../../components/SubscribeToAnalytics';
import {SEO_CONTENT} from '../../const';

const all = {
  id: -1,
  name: 'Все'
};

export const Articles: React.FC<{}> = ({}) => {
  const [category, setCategory] = useState(all.id);

  const articles = useStore(Articles$);
  const categories = useStore(Categories$);

  const loadArticles = () => {
    const ids = category > 0 ? [category] : [];
    const params = {
      categoryIds: ids,
      count: 12
    };

    articlesApi.get(params);
  };

  useEffect(() => {
    loadArticles();
  }, [category]);

  useEffect(() => {
    // @ts-ignore
    categoriesApi.get();
  }, []);

  if (!categories) {
    return null;
  }

  const categoriesList = [all, ...categories];
  const seo = SEO_CONTENT['blogs'];

  return (
    <>
      {seo && <Helmet {...seo} />}
      <div className="articles">
        <header className="articles__header">
          <div className="articles__title">Новости и аналитика</div>
          <div className="articles__subscribe">
            <SubscribeToAnalytics />
          </div>
          <div className="articles__categories">
            <div className="articles__categories-label">Категории: </div>
            <div className='articles__categories-list'>
            {categoriesList.map((item, key) => (
              <div
                key={key}
                onClick={() => setCategory(item.id)}
                className={cn('articles__categories-item', {
                  'articles__categories-item-active': category === item.id
                })}>
                {item.name}
              </div>
            ))}
            </div>
          </div>
          {articles && (
            <span className="articles__caption">
              {noun(articles?.length, 'Найдена', 'Найдено', 'Найдено')}{' '}
              {noun(articles?.length, '%d статья', '%d статьи', '%d статей')}
            </span>
          )}
        </header>
        <div className="articles__content">
          {articles && <ArticleCardList items={articles} />}
        </div>
      </div>
    </>
  );
};
