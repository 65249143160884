import { fromJS } from 'immutable';
import { RESET_DATA } from '../actions/GeneralActions';

export const SET_SIGNALS_DATA = 'SET_SIGNALS_DATA';

const initialState = fromJS({});

export const signal = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SIGNALS_DATA:
      return state.set(payload.clientId).set(payload.clientId, payload.data);
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};
