import React, { useState, useEffect } from 'react';

import { getAuthorsStatistic } from '../../../api';

// eslint-disable-next-line
const StatisticRow = ({ value, description }) => (
  <div className="experts-statistics__row">
    <div className="experts-statistics__row-count">{value}</div>
    <div className="experts-statistics__row-description">{description}</div>
  </div>
);

// eslint-disable-next-line
export const Statistics = () => {
  const [statistic, setStatistic] = useState(null);

  useEffect(() => {
    getAuthorsStatistic()
      .then(result => {
        const { success, response } = result;

        if (success) {
          setStatistic(response);
        }
      }) // eslint-disable-next-line
      .catch(error => console.log('Произошла непредвиденная ошибка'));
  }, []);

  if (!statistic) {
    return null;
  }

  return (
    <div className="experts-statistics">
      <StatisticRow
        value={statistic.authors}
        description="авторов уже с нами"
      />
      <div className="experts-statistics__row-separator" />
      <StatisticRow
        value={statistic.strategies}
        description="стратегий представлено"
      />
    </div>
  );
};
