import React, { useState } from 'react'
import cs from 'classnames'
import InputMask from 'react-input-mask'
import { useField } from 'formik'

export const InputPhone = ({
  field: fieldProp,
  className,
  type,
  placeholder
}) => {
  const [focus, setFocus] = useState(false)
  const [field, meta] = useField(fieldProp.name)
  const isError = meta.touched && meta.error
  const isDirty = meta.value !== meta.initialValue

  const onFocus = () => setFocus(true)
  const onBlur = e => {
    field.onBlur(e)
    setFocus(false)
  }

  return (
    <label
      className={cs('form-control', className, {
        _focus: focus || isDirty
      })}
    >
      <InputMask
        className={cs('form-control__field', { _invalid: isError })}
        mask='+7 999 999 99 99'
        maskChar='_'
        placeholder={placeholder}
        type={type}
        required
        {...field}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {isError && <span className='form-control__message'>{meta.error}</span>}
      <span className='form-control__placeholder'>{placeholder}</span>
    </label>
  )
}
