import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { Field, useFormikContext } from 'formik';

import { Search } from './Search';
import { RangeSlider } from './RangeSlider';
import { ProfitFilter } from './ProfitFilter';

import { Select } from '../../../components/FormComponents/Select';

import {
  InvestProfiles$,
  investProfilesApi
} from '../../../effector/investProfiles';
import { Strategies$ } from '../../../effector/strategies';
import { Tools$, toolsApi } from '../../../effector/tools';
import { Markets$, marketsApi } from '../../../effector/markets';

import { FormProps } from '../';
import Button from '../../../components/Button/Button';

type Props = {};

export const Filter: React.FC<Props> = ({}) => {
  const strategies = useStore(Strategies$);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const { handleReset } = useFormikContext<FormProps>();

  const tools = useStore(Tools$);
  const markets = useStore(Markets$);
  const investProfiles = useStore(InvestProfiles$);

  useEffect(() => {
    toolsApi.get('');
    investProfilesApi.get('');
    marketsApi.get('');
  }, []);

  return (
    <section className={cn('filter', filterIsOpen && '_expanded')}>
      <div className="filter__form">
        <Button
          className="_outline _wide filter__toggle"
          text="Фильтр"
          onClick={() => setFilterIsOpen(!filterIsOpen)}
        />
        <header className="filter__header">
          <Field name="filter.q" component={Search} />
          <Button
            className="_outline filter__reset"
            text="Сбросить фильтр"
            onClick={handleReset}
          />
        </header>
        <div className="filter__content">
          <div className="filter__row">
            <div className="filter__col">
              <Field
                name="filter.funds"
                component={Select}
                placeholder="Выбрать рынки"
                multiple={true}
                options={markets}
              />
            </div>
            <div className="filter__col">
              <Field
                name="filter.tools"
                component={Select}
                placeholder="Выбрать инструменты"
                multiple={true}
                options={tools}
              />
            </div>
          </div>

          <div className="filter__row">
            <div className="filter__col">
              <RangeSlider
                name="filter.risk"
                className="_danger"
                title="Инвестиционный профиль: "
                profiles={investProfiles}
                divider="/"
                rcSlider={{
                  min: 1,
                  max: 7,
                  marks: { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7 },
                  included: false,
                  defaultValue: 7
                }}
              />
            </div>
            {/*<div className="filter__col">*/}
            {/*  <ProfitFilter strategies={strategies} />*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </section>
  );
};
