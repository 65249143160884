import React from 'react'

import { Actions } from '../Actions'

// eslint-disable-next-line
export const Final = ({ onClick }) => (
  <div className='invest-profile-final'>
    <div className='invest-profile-final__title'>
      Заявка на изменение инвестпрофиля успешно отправлена
    </div>
    <div className='invest-profile-final__description'>
      Информация будет обновлена в течение 2-3 минут
    </div>
    <Actions
      items={[
        {
          className: '_primary _large',
          text: 'Завершить',
          disabled: false,
          onClick
        }
      ]}
    />
  </div>
)
