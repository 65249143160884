import React, { useCallback, useMemo } from 'react';
import { PieChart } from '../../../Chart/PieChart';
import { LineChart } from '../../../Chart/LineChart';
import { PieChartDetails } from '../../../Chart/PieChartDetails';
import { AccountPortfolio } from '../../../../types/accountPortfolio';
import { COLORS } from '../../../Chart/COLORS';
import { roundNumber, formatMoney } from '../../../../utils';
import moment from 'moment';
import classNames from 'classnames';
import { POP_UP } from '../../../../const';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../../../actions/PopupActions';
import { connect } from 'react-redux';
import { toJS } from '../../../../HOC/toJS';

type Props = {
  clientCode: string;
  portfolio: AccountPortfolio;
  onRemovePortfolio: () => void;
  popupActions: any;
};

const PortfolioItem: React.FC<Props> = ({
  portfolio,
  clientCode,
  onRemovePortfolio,
  popupActions
}) => {
  const positionTitles = useMemo(() => ['', 'Доля', 'Текущая стоимость'], []);

  const chartData = useMemo(() => {
    const positions = portfolio.positions.map(
      ({ currentPrice, currency, name, weight }, index) => {
        const columns = [
          {
            title: '',
            text: name,
            color: COLORS[index]
          },
          {
            title: 'Доля',
            text: `${roundNumber(Math.abs(weight))}%`
          },
          {
            title: 'Текущая цена',
            text: formatMoney(currentPrice, currency)
          }
        ];

        return columns;
      }
    );

    return positions;
  }, [portfolio.positions]);

  const unbindPortfolio = useCallback(() => {
    const approvalText = `<p>Спасибо! <br /> Ваша заявка принята.</p>`;

    popupActions.openPopup(POP_UP.UNBIND_PORTFOLIO, {
      portfolioId: portfolio.id,
      clientCode,
      text: approvalText,
      action: onRemovePortfolio
    });
  }, [clientCode, portfolio, onRemovePortfolio]);

  return (
    <div className={classNames('account__portfolio')}>
      <div className="account__portfolio-name">{portfolio.name}</div>
      <div className="account__portfolio-charts">
        <div className="account__portfolio-charts_pie">
          {portfolio.positions && (
            <PieChart
              data={portfolio.positions}
              textKey="securityKey"
              valueKey="weight"
              symbolKey="securityKey"
            />
          )}
        </div>
        <div className="account__portfolio-charts_line">
          {
            // @ts-ignore
            <LineChart data={portfolio.history} />
          }
        </div>
      </div>
      <div className="account__portfolio-separator" />
      {chartData && (
        <PieChartDetails titles={positionTitles} data={chartData} />
      )}
      <div className="account__portfolio-description">
        <div className="account__portfolio-description_item">
          Портфель действует до{' '}
          {moment(portfolio.closeDate).format('DD.MM.YYYY')}
        </div>
        <div className="account__portfolio-description_item">
          Ограничение по рискам: при достижении общей стоимости портфеля -{' '}
          {portfolio.sl}% портфель будет распродан{' '}
        </div>
        <div className="account__portfolio-description_item">
          Ограничение по прибыли: при достижении общей стоимости +{portfolio.tp}
          % портфель будет распродан{' '}
        </div>
      </div>
      <div style={{ textAlign: 'right' }}>
        <button
          className="account-description__close"
          type="button"
          onClick={unbindPortfolio}>
          Отключиться от портфеля
        </button>
      </div>
    </div>
  );
};

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

const wrappedComponent = connect(
  null,
  mapDispatchToProps
)(toJS(PortfolioItem));

export { wrappedComponent as PortfolioItem };
