import React, { PureComponent } from 'react';
import cn from 'classnames';
import download from 'downloadjs';
import Moment from 'react-moment';
import 'moment/locale/ru';
import { Table } from '../../Table';
import { post } from '../../../api/apiHelper';

import { ReactComponent as Pdf } from './pdf.svg';

class HistoryTableRecommendations extends PureComponent {
  downloadPdf = id => {
    const params = {
      id
    };

    post('account/clientsignaldoc', params).then(result => {
      const { success, response } = result;

      if (success) {
        download(
          `data:application/pdf;base64,${response.bodyBase64}`,
          `${response.fileName}.pdf`,
          'application/pdf'
        );
      }
    });
  };

  prepareRowData = () => {
    const { items } = this.props;

    const result = items.map(item => {
      const rowData = [
        {
          title: 'Дата',
          content: (
            <Moment format={'DD.MM.YYYY HH:mm:ss'} locale={'ru'}>
              {item.time}
            </Moment>
          )
        },
        {
          title: '№ ИИР',
          content: item.docId
        },
        {
          title: 'ИИР',
          content: (
            <span className="account__iir-download">
              pdf{' '}
              <span
                className="account__iir-download_icon"
                onClick={() => this.downloadPdf(item.signalId)}>
                <Pdf width={25} height={25} />
              </span>
            </span>
          )
        }
      ];

      return rowData;
    });

    return result;
  };

  render() {
    const { className, tableHeaders } = this.props;

    const rowsData = this.prepareRowData();

    return (
      <Table
        headers={tableHeaders}
        rows={rowsData}
        clasName={cn('_v2', className)}
      />
    );
  }
}

export default HistoryTableRecommendations;
