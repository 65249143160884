import React, { PureComponent } from 'react'
import { CONNECTION_TYPE } from '../../../const'
import AutoFollowDescriptionTab from './AutoFollow'
import RecomendationDescriptionTab from './Recomendation'

class DescriptionTab extends PureComponent {
  render () {
    const {
      status
    } = this.props

    return status === CONNECTION_TYPE.AUTOFOLLOW ? <AutoFollowDescriptionTab {...this.props} /> : <RecomendationDescriptionTab {...this.props} />
  }
}

export default DescriptionTab
