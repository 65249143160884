import React, { useState } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Field, Form, Formik, FormikProps } from 'formik';

import * as PopupActions from '../../../actions/PopupActions';

import { toJS } from '../../../HOC/toJS';

import Button from '../../Button/Button';
import { Input } from '../../FormComponents/Input';

import { PAGE_ONBOARDING } from '../../../Pages/Routes';

import { POP_UP } from '../../../const';

import { withQuery } from '../../../utils';
import { FIELD_LENGTH, required } from '../../../utils/Form/formik-validation';

import { GAService } from '../../../services/gaService';
import { QueryService, UTM_PARAMS } from '../../../services/queryService';
import { AuthenticationService } from '../../../services/authenticationService';

import { ReactComponent as BrokerIcon } from './bcs-broker.svg';
import { accountApi } from '../../../effector/clientAccounts';
import { auth } from '../../../api/apiHelper';

const validationSchema = Yup.object().shape({
  userId: Yup.string()
    .required(required)
    .max(FIELD_LENGTH.login),
  password: Yup.string()
    .required(required)
    .max(FIELD_LENGTH.password)
});

const initialValues = {
  userId: '',
  password: ''
};

type FormFields = {
  userId: string;
  password: string;
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ loading, error }) => (
  <Form noValidate>
    <div className="form-control-row">
      <Field
        placeholder="введите k-логин или номер телефона"
        name="userId"
        type="text"
        component={Input}
      />
    </div>

    <div className="form-control-row">
      <Field
        placeholder="введите пароль"
        name="password"
        type="password"
        component={Input}
      />
    </div>

    <Button
      type="submit"
      loading={loading}
      className="_primary _large _wide"
      text="Войти"
    />

    {error && <p className="error-message">{error}</p>}
  </Form>
);

type Props = {
  // tslint:disable-next-line:no-any
  popupData: any;
  // tslint:disable-next-line:no-any
  popupActions: any;
};

const AuthForm: React.FC<Props> = ({ popupData, popupActions }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET;

  const onSubmit =
    process.env.REACT_APP_PREPROD === 'true'
      ? (values: FormFields) => {
          setLoading(true);
          GAService.sendEvent('ButtonAutorization');
          accountApi
            .login(values)
            .then(result => {
              // @ts-ignore
              const { errorMessage, success } = result;

              if (success) {
                popupActions.hidePopup(POP_UP.AUTHORIZATION);
                popupData.onSuccess();
              } else {
                setError(errorMessage);
              }
            })
            .catch(() => {
              setError('Неверно указаны имя пользователя или пароль');
            }).finally(() => {
            setLoading(false);
          });
        }
      : (values: FormFields) => {
          let authData = `grant_type=password&username=${encodeURIComponent(
            values.userId
          )}&password=${encodeURIComponent(
            values.password
          )}&client_id=${clientId}`;

          if (clientSecret) {
            authData += `&client_secret=${clientSecret}`;
          }

          setLoading(true);
          GAService.sendEvent('ButtonAutorization');

          auth(authData).then(response => {
            if (response) {
              AuthenticationService.accessToken = response.access_token;
              AuthenticationService.refreshToken = response.refresh_token;

              accountApi.getAccount('').then(result => {
                // @ts-ignore
                const { success } = result;

                if (success) {
                  popupActions.hidePopup(POP_UP.AUTHORIZATION);
                  popupData.onSuccess();
                } else {
                  setLoading(false);
                  setError('Произошла непредвиденная ошибка');
                }
              });
            } else {
              setError('Неверно указаны имя пользователя или пароль');
            }
          }).finally(() => {
            setLoading(false);
          });
        };

  // end of auth

  const toOnboadring = () => {
    const path = PAGE_ONBOARDING;

    GAService.sendEvent('ButtonOpenDepo');

    const params = {
      [UTM_PARAMS.REF_ID]: QueryService.refId,
      [UTM_PARAMS.UTM_SOURCE]: QueryService.utmSource,
      [UTM_PARAMS.UTM_CAMPAIGN]: QueryService.utmCampaign,
      [UTM_PARAMS.UTM_CONTENT]: QueryService.utmContent,
      [UTM_PARAMS.UTM_MEDIUM]: QueryService.utmMedium,
      [UTM_PARAMS.UTM_TERM]: QueryService.utmTerm
    };

    const url = withQuery(params)(path);

    const win = window.open(url, '_blank');
    win?.focus();
  };

  return (
    <>
      <Button
        onClick={toOnboadring}
        loading={loading}
        className="_primary _large _wide"
        text="Открыть счет в «БКС Брокер»"
      />
      <div className="modal__text">Вход для клиентов БКС</div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={onSubmit}>
        {props =>
          React.createElement(FormTemplate, {
            ...props,
            loading,
            error
          })
        }
      </Formik>
      <BrokerIcon />
    </>
  );
};

// @ts-ignore
const mapStateToProps = state => ({
  popupData: state.getIn(['popup', 'data'])
});

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

const wrappedComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(toJS(AuthForm))
);

export { wrappedComponent as AuthForm };
