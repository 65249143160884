import React, { useEffect } from 'react';
import { Section } from '../../components/Section';
import { noun } from 'plural-ru';
import { Portfolios$, portfoliosApi } from '../../effector/portfolios';
import { useStore } from 'effector-react';
import { PortfolioCardList } from '../../components/Cards/PortfolioCardList';
import { HeaderList } from '../../components/HeaderList';

export const PortfoliosPage: React.FC<{}> = ({}) => {
  const { portfolios } = useStore(Portfolios$);

  useEffect(() => {
    portfoliosApi.get('');
  }, []);

  return (
    <Section className="_portfolios">
      <header className="section__header">
        <HeaderList>
          <div>
            <b>«Портфель трейдера»</b> - это инструмент для активной торговли и
            контроля рисков на Вашем счете.
          </div>
          <div>
            Выберете портфель отвечающий Вашим целям, определите уровень
            допустимого риска и интересующей доходности.{' '}
          </div>
          <div>
            После подключения наша система будет контролировать заданные
            параметры и при их достижении, выбранный портфель зафиксирует
            доходность или ограничит риск.
          </div>
        </HeaderList>
        <h2 className="section__title">Портфели</h2>
        <span className="section__caption">
          {noun(portfolios.length, 'Найдена', 'Найдено', 'Найдено')}{' '}
          {noun(
            portfolios.length,
            '%d портфель',
            '%d портфеля',
            '%d портфелей'
          )}
        </span>
      </header>
      <div className="section__content">
        {portfolios.length > 0 && <PortfolioCardList items={portfolios} />}
      </div>
    </Section>
  );
};
