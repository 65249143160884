import React from 'react'
import classNames from 'classnames'

import {Link} from '../Link'

// eslint-disable-next-line
const MainNav = ({ className, items }) => (
  <nav className={classNames('main-nav', className)}>
    <ul className='main-nav__list'>
      {items.map(({ link, text }, key) => (
        <li key={key} className='main-nav__item'>
          <Link link={link} className='main-nav__link'>
            {text}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
)

export default MainNav
