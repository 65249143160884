import { createStore, createApi, createEffect, forward } from 'effector';

import {getFaq} from '../api';
import { QuestionCategory } from '../types/questionCategory';

type TFqa$State = QuestionCategory[];

export const Faq$ = createStore<TFqa$State>([]);

const { set } = createApi(Faq$, {
  set: (state, { result }) => {
    return result.response;
  }
});


export const faqApi = {
  // @ts-ignore
  get: createEffect().use(getFaq)
};

forward({
  from: faqApi.get.done,
  to: set
});
