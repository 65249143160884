import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'react-tippy';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';
import { Howto } from '../../components/Howto';
import { COLORS } from '../../components/Chart/COLORS';
import { PieChart } from '../../components/Chart/PieChart';
import { AskQuestion } from '../../components/Form/AskQuestion';
import { PieChartDetails } from '../../components/Chart/PieChartDetails';

import { Portfolios$, portfoliosApi } from '../../effector/portfolios';
import {
  roundNumber,
  getProgressSymbol,
  floorPercent,
  formatMoney
} from '../../utils';
import { Page404 } from '../Page404/Page404';
import { BindPortfolio } from './BindPortfolio';
import { ReactComponent as QuestionIcon } from '../../components/UIKit/Tooltip/question.svg';

export const PortfolioPage: React.FC<{}> = ({}) => {
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams<{id: string}>();
  const { portfolio } = useStore(Portfolios$);

  useEffect(() => {
    const data = {
      portfolioId: id as string
    };

    portfoliosApi.getPortfolio(data).finally(() => setLoaded(true));
  }, [id]);

  const items = useMemo(
    () => [
      {
        title: 'Вы покупаете профессиональную подборку ценных бумаг одним портфелем.',
        link: ''
      },
      {
        title: 'Вы устанавливаете желаемый уровень прибыли и ограничиваете уровень возможных убытков.',
        link: ''
      },
      {
        title: 'Инвестиционная идея действует определенный период времени. По истечении этого срока портфель автоматически закроется.',
        link: ''
      }
    ],
    []
  );

  const positionTitles = useMemo(
    () => ['', 'Доля', 'Текущая стоимость', 'Целевая цена'],
    []
  );

  const chartData = useMemo(() => {
    const positions = portfolio?.positions.map(
      ({ symbol, price, currentPrice, name, weight }, index) => {
        const columns = [
          {
            title: '',
            text: name,
            color: COLORS[index]
          },
          {
            title: 'Доля',
            text: `${roundNumber(Math.abs(weight))}%`
          },
          {
            title: 'Текущая стоимость',
            text: currentPrice
              ? formatMoney(currentPrice, portfolio.currency)
              : ''
          },
          {
            title: 'Целевая цена',
            text: price ? formatMoney(price, portfolio.currency) : ''
          }
        ];

        return columns;
      }
    );

    return positions;
  }, [portfolio?.positions, portfolio?.currency]);

  if (!loaded) {
    return null;
  }

  if (loaded && !portfolio) {
    return (
      <div className="strategy-item">
        <Page404 />
      </div>
    );
  }

  return (
    <div className="portfolio">
      <div className="portfolio__header">
        <h1 className="portfolio__header-name">{portfolio?.name}</h1>
        <div className="portfolio__header-profit">
          <div className="portfolio__header-profit_name">
            Потенциальная доходность портфеля
          </div>
          <div className="portfolio__header-profit_value">
            {getProgressSymbol(portfolio?.potencial)}
            {floorPercent(portfolio?.potencial)}%
          </div>
          <Tooltip
            className="portfolio__header-tooltip"
            theme="light"
            title={portfolio?.securityForecastsSourceDisclaimer}
            arrow={true}
            position="bottom"
            trigger="mouseenter">
            <QuestionIcon width={13} height={13} />
          </Tooltip>
        </div>
      </div>
      <div className="portfolio__chart">
        <div className="chart__img account-description__chart-img">
          {portfolio?.positions && (
            <PieChart
              data={portfolio.positions}
              textKey="isin"
              valueKey="weight"
              symbolKey="symbol"
            />
          )}
        </div>
        {chartData && (
          <PieChartDetails
            titles={positionTitles}
            data={chartData}
            className="portfolio__chart-details"
          />
        )}
      </div>
      {portfolio?.salesPoints && (
        <div className="portfolio__sales-points">
          {portfolio.salesPoints.map(point => (
            <div
              className="portfolio__sales-point"
              dangerouslySetInnerHTML={{ __html: point }}
            />
          ))}
        </div>
      )}
      <div
        className="portfolio__short-info"
        dangerouslySetInnerHTML={{
          __html: portfolio?.shortDescription as string
        }}
      />
      <div className="portfolio__connect">
        <BindPortfolio portfolio={portfolio} />
      </div>
      <div className="portfolio__description">
        <div className="portfolio__description-header">Описание портфеля</div>
        <div
          className="portfolio__description-content"
          dangerouslySetInnerHTML={{ __html: portfolio?.description as string }}
        />
      </div>
      <div className="portfolio__works">
        <div className="portfolio__works-header">Как это работает</div>
        <Howto items={items} className="portfolio" />
      </div>
      <div className="portfolio__feedback">
        <AskQuestion
          name="Задайте вопрос"
          eventName="CallbackRequest"
          className="promo__section">
          Остались вопросы? Напишите нам и наш менеджер свяжется с вами для
          обсуждения всех вопросов
        </AskQuestion>
      </div>
    </div>
  );
};
