import React, { PureComponent } from 'react';
import cn from 'classnames';
import Moment from 'react-moment';
import 'moment/locale/ru';
import { post } from '../../../api/apiHelper';
import download from 'downloadjs';
import { ReactComponent as Pdf } from './pdf.svg';
import { formatMoney } from '../../../utils';
import { Table } from '../../Table';
import { Tooltip } from 'react-tippy';
import {ReactComponent as QuestionIcon} from "../../UIKit/Tooltip/question.svg";

class HistoryTable extends PureComponent {
  downloadPdf = id => {
    const params = {
      id
    };

    post('account/clientsignaldoc', params).then(result => {
      const { success, response } = result;

      if (success) {
        download(
          `data:application/octet-stream;base64,${response.bodyBase64}`,
          `${response.fileName}`,
          'application/octet-stream'
        );
      }
    });
  };

  prepareRowData = () => {
    const { items } = this.props;

    const result = items.map(item => {
      const d = new Date(item.date);
      const rowData = [
        {
          title: 'Сделка',
          content: item.execCount > 0 ? 'Покупка' : 'Продажа'
        },
        {
          title: 'Дата',
          content: (
            //<Moment format={'DD.MM.YYYY HH:mm:ss'} locale={'ru'}>
            ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
            d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
            //</Moment>
          )
        },
        {
          title: 'Инструмент',
          content: item.securityKey
        },
        {
          title: 'Объем',
          content:
            item.execCount === 0 ? 'Не исполнено' : Math.abs(item.execCount),
          className: item.execCount === 0 ? 'fall' : ''
        },
        {
          title: 'Цена входа',
          content: formatMoney(
            item.execPrice,
            item.priceInPct ? '%' : item.currency
          )
        },
        {
          title: 'Стоимость вложений',
          content: item.execValue
            ? formatMoney(item.execValue, item.currency)
            : ''
        },
        {
          title: 'P/L',
          content:
            item.realizedPL !== 0
              ? `${formatMoney(item.realizedPL, item.currency)} (${
                  item.realizedPLPct
                }%)`
              : '',
          className:
            item.realizedPL !== 0
              ? item.realizedPL >= 0
                ? 'rise'
                : 'fall'
              : ''
        },
        {
          title: 'Документ',
          content: (
            <span className="account__iir-download">
              <span
                className="account__iir-download_icon"
                onClick={() => this.downloadPdf(item.id)}>
                <Pdf width={25} height={25} />
              </span>
              {item.isExchange ? 'ИC ' : 'ИИР '}
              <Tooltip
                theme="light"
                title={item.isExchange ? 'Информационное сообщение о сделке с иностранной валютой' : 'Индивидуальная инвестиционная рекомендация'}
                arrow={true}
                position="bottom"
                trigger="mouseenter">
                <QuestionIcon width={13} height={13} />
              </Tooltip>
            </span>
          )
        }
      ];

      return rowData;
    });

    return result;
  };

  render() {
    const { className, tableHeaders } = this.props;

    const rowsData = this.prepareRowData();

    return (
      <Table
        headers={tableHeaders}
        rows={rowsData}
        clasName={cn('_v2', className)}
      />
    );
  }
}

export default HistoryTable;
