import React, { useState } from 'react';
import cn from 'classnames';

import { ReactComponent as QuestionIcon } from './question.svg';

type Props = {
  text: string | React.ReactNode;
  className?: string;
  onShow?: () => void;
};

const width = 13;
const height = 13;

export const QuestionTooltip: React.FC<Props> = ({
  text,
  onShow,
  className = ''
}) => {
  const [hover, setHover] = useState(false);

  const mouseEnter = () => {
    if (!hover) {
      setHover(true);
      if (onShow) {
        onShow();
      }
    }
  };

  const mouseLeave = () => {
    if (hover) {
      setHover(false);
    }
  };

  return (
    <span
      className={className}
      onMouseLeave={mouseLeave}
      onMouseEnter={mouseEnter}>
      <span className="question">
        <QuestionIcon width={width} height={height} />
      </span>
      <span className="position">
        {typeof text === 'string' ? (
          <span
            className={cn('position__tooltip ', {
              position__hovered: hover
            })}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ) : (
          <span
            className={cn('position__tooltip ', {
              position__hovered: hover
            })}>
            {text}
          </span>
        )}
      </span>
    </span>
  );
};
