import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import StableCard from '../../components/Cards/StableCard';
import { getArticleStrategies } from '../../api';

export const ArticleStrategies: React.FC<{}> = ({}) => {
  const [strategies, setStrategies] = useState([]);
  const { id } = useParams<{id: string}>();

  useEffect(() => {
    getArticleStrategies(id).then(result => {
      const { response, success } = result;

      if (response) {
        setStrategies(response);
      }
    });
  }, [id]);

  return (
    <div className="promo-cards">
      {strategies.map((item, key) => (
        <div key={key} className="promo-cards__item">
          <StableCard {...item} num={key} />
        </div>
      ))}
    </div>
  );
};
