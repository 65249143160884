import { createStore, createApi, createEffect, forward } from 'effector';

import { getArticle } from '../api';
import { Nullable } from '../types/utils';
import { Article } from '../types/article';
// tslint:disable-next-line:no-any
type TArticle$State = Nullable<Article>;

export const Article$ = createStore<TArticle$State>(null);

const { set } = createApi(Article$, {
  set: (state, { result }) => result.response
});

export const articleApi = {
  get: createEffect().use(getArticle)
};

forward({
  from: articleApi.get.done,
  to: set
});
