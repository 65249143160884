import { fromJS } from 'immutable';

export const SET_PATH = 'SET_PATH';
export const SET_EXTRA_OPEN = 'SET_EXTRA_OPEN';
export const REMOVE_PATH = 'REMOVE_PATH';

const initialState = fromJS({
  previousPath: '/',
  profileId: null,
  extraOpen: false
});

export const routerInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_PATH:
      return state
        .set('previousPath', action.payload.previousPath)
        .set('profileId', action.payload.profileId);
    case SET_EXTRA_OPEN:
      return state.set('extraOpen', true);
    case REMOVE_PATH:
      return initialState;
    default:
      return state;
  }
};
