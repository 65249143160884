import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toJS } from '../../../HOC/toJS';
import * as PopupActions from '../../../actions/PopupActions';
import Button from '../../Button/Button';
import { post } from '../../../api/apiHelper';
import { POP_UP } from '../../../const';

const mapStateToProps = state => ({
  popupData: state.getIn(['popup', 'data'])
});

const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

class RemoveStrategy extends PureComponent {
  state = {
    errorMessage: '',
    loading: false
  };

  openAcceptTel = () => {

    const { popupData } = this.props;

    this.setState({ loading: true });

    post('account/unbindstrategy', popupData).then(result => {
      const { errorMessage, success, response } = result;

      if (success) {
        const data = { ...popupData, content: response.acceptHtml };

        this.props.popupActions.openPopup(POP_UP.REMOVE_AUTO_FOLLOWING, data);
      } else {
        this.setState({ errorMessage });
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  };

  render() {
    const { errorMessage } = this.state;
    const { popupData } = this.props;

    return (
      <div>
        <p className="modal__text-content">
          Внимание! При отключении автоследования от стратегии все текущие
          открытые позиции останутся на вашем брокерском счете. Закрыть их вы
          можете самостоятельно.
        </p>
        <p className="modal__text-content-warning" dangerouslySetInnerHTML={{ __html: popupData.unbindNote }} />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <Button
          className="_primary _large _wide"
          text="Отключить"
          onClick={this.openAcceptTel}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(RemoveStrategy));
