import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _orderBy from 'lodash/orderBy';
import Fuse from 'fuse.js';

import { FormProps } from '../';
import { Sort } from '../Catalog';
import { multiplier } from '../../../const';
import { Option } from '../../../types/option';
import { Strategy } from '../../../types/strategy';

type FilterSelectProps = {
  select: Option[];
  collection: Strategy[];
  propName: string;
};

// @ts-ignore
const filterBySelect: (props: FilterSelectProps) => Strategy[] = ({
  select,
  collection,
  propName
}) => {
  return _filter(collection, (item) => {
    // @ts-ignore
    return _filter(item[propName], ({id}) => {
      return _filter(
        select,
        ({ id: propItemId }) => propItemId === id
      ).length;
    }).length;
  });
};

type Props = {
  values: FormProps;
  cards: Strategy[];
  withProfit?: boolean;
};

export const filterCards: (props: Props) => Strategy[] = ({
  values,
  cards,
  withProfit = true
}) => {
  let innerCards = cards;

  if (values.filter) {
    if (values.filter.risk) {
      innerCards = _filter(
        innerCards,
        ({ minInvestProfile }) => minInvestProfile.id <= values.filter.risk
      );
    }

    if (values.filter?.funds?.length) {
      const { funds } = values.filter;
      innerCards = filterBySelect({
        collection: innerCards,
        propName: 'markets',
        select: funds
      });
    }

    if (values.filter?.tools?.length) {
      const { tools } = values.filter;
      innerCards = filterBySelect({
        collection: innerCards,
        propName: 'tools',
        select: tools
      });
    }

    if (values.filter.q.length) {
      const { q } = values.filter;
      const options = {
        keys: ['name'],
        threshold: 0.4
      };

      const fuse = new Fuse(innerCards, options);
      const fuseResult = fuse.search(q);
      innerCards = fuseResult?.map(({item}) => item);
    }

    if (withProfit && values.filter.profit) {
      const { profit } = values.filter;
      innerCards = _filter(
        innerCards,
        ({ actualProfit }) =>
          actualProfit >= profit[0] / multiplier &&
          actualProfit <= profit[1] / multiplier
      );
    }

    if (values.filter.sort) {
      innerCards = _orderBy(
        innerCards,
        [values.filter.sort.id],
        [values.filter.sort.direction as Sort]
      );
    }
  }

  return innerCards;
};

type ProfitRangeSliderProps = {
  profitRangeSlider: {
    rcSlider: {
      min: number;
      max: number;
      marks: Record<number, string>;
      defaultValue: number[];
    };
  };
};

type ProfitRangeProps = {
  cards: Strategy[];
};

export const profitRangeSlider: (
  props: ProfitRangeProps
) => ProfitRangeSliderProps = ({ cards }) => {
  const data = _sortBy(cards.map(({ actualProfit }) => actualProfit));
  const delimiter = 2;
  // слайдер не работает с дробными значениями, поэтому мы переводим в целые числа сохраняя точность
  const min = data[0] * multiplier;
  const max = data[data.length - 1] * multiplier;
  const middle = (max - min) / delimiter + min;
  const marks = {
    [min]: `${min / multiplier}%`,
    [middle]: `${(middle / multiplier).toLocaleString('ru-RU', {
      maximumFractionDigits: 2
    })}%`,
    [max]: `${max / multiplier}%`
  };
  const defaultValue = [min, max];

  return {
    profitRangeSlider: {
      rcSlider: { min, max, marks, defaultValue }
    }
  };
};
