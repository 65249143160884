import { HIDE_POPUP, OPEN_POPUP, RESET_POPUP } from '../reducers/popup';

export const hidePopup = popupId => dispatch =>
  dispatch({
    type: HIDE_POPUP,
    payload: { popupId }
  });

export const openPopup = (popupId, data) => dispatch =>
  dispatch({
    type: OPEN_POPUP,
    payload: { popupId, data }
  });

export const resetPopup = () => dispatch => dispatch({ type: RESET_POPUP });
