import React from 'react';
import cn from 'classnames';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { isBrowser } from '../../../utils';
import { HIGHCHART_SETTINGS } from '../../../const';

if (isBrowser) {
  Highcharts.setOptions(HIGHCHART_SETTINGS);
}

const chartStyle = {
  type: 'area',
  name: 'доходность',
  threshold: null,
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: true
      }
    }
  }
};

// eslint-disable-next-line
export const SimpleLineChart = ({ className, data }) => {
  const fillColor = {
    linearGradient: {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1
    },
    stops: [
      [
        0,
        Highcharts.Color('#fff')
          .setOpacity(0.2)
          .get('rgba')
      ],
      [
        0.5,
        Highcharts.Color('#fff')
          .setOpacity(0.1)
          .get('rgba')
      ],
      [
        1,
        Highcharts.Color('#DFF5EC')
          .setOpacity(0)
          .get('rgba')
      ]
    ]
  };

  let min = 0;
  let max = 0;

  const chartData = data.map(item => {
    if (item != null) {
      let _data = item.reduce((array, curPoint) => {
        let newPoint = [curPoint.t, curPoint.v];

        if (min > curPoint.v) {
          min = curPoint.v;
        }

        if (max < curPoint.v) {
          max = curPoint.v;
        }
        return array.concat([newPoint]);
      }, []);

      return {
        ...chartStyle,
        data: _data,
        fillColor: fillColor
      };
    }

    return { ...chartStyle, data: [] };
  });

  const options = {
    title: {
      text: ''
    },
    chart: {
      backgroundColor: 'transparent',
      height: '55px',
      spacing: [0, 0, 0, 0],
      margin: [0, 0, 0, 0],
      type: 'line'
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      max: Math.sign(max) * (Math.abs(max) + (max - min) * 0.05),
      min: Math.sign(min) * (Math.abs(min) + (max - min) * 0.05),
      startOnTick: false,
      visible: false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    series: chartData
  };

  return (
    <div className={cn('chart', className)}>
      <div className="chart__img">
        <HighchartsReact options={options} highcharts={Highcharts} />
      </div>
    </div>
  );
};
