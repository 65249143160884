import { fromJS } from 'immutable';

export const ADD_HEADER_CLASS = 'ADD_HEADER_CLASS';

const initialState = fromJS({
  className: ''
});

export const pageHeader = (state = initialState, action) => {
  switch (action.type) {
    case ADD_HEADER_CLASS:
      return state.set('className', action.payload);
    default:
      return state;
  }
};
