import React from 'react';
import { PAGE_ARTICLES } from '../../../Pages/Routes';
import { Link } from '../../Link';
import { AnalyticsBrief } from '../../../types/analyticsBrief';
import moment from 'moment';

type Props = AnalyticsBrief;

export const ArticleCard: React.FC<Props> = ({
  date,
  url,
  title,
  image,
  cutHtml,
  categories
}) => {
  const styles = {
    backgroundImage: `url(${image})`
  };

  return (
    <Link link={`${PAGE_ARTICLES}/${url}`} className="article-card">
      <div className="article-card__img" style={styles} />
      <div className="article-card__content">
        <div className="article-card__labels">
          {categories.map((item, key) => (
            <span
              key={key}
              className="label _primary article-card__labels-item">
              {item.name}
            </span>
          ))}
        </div>
        <div className="article-card__title">{title}</div>
        <div
          className="article-card__anons"
          dangerouslySetInnerHTML={{ __html: cutHtml }}
        />
        <div className="article-card__date">
          {moment(date).format('DD.MM.YYYY HH:mm')}
        </div>
      </div>
    </Link>
  );
};
