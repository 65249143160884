import React, { useEffect } from 'react';

import { useStore } from 'effector-react';
import Helmet from 'react-helmet';

import { StrategiesTop$, strategiesTopApi } from '../../effector/top';

import Cards from '../../components/Cards/Cards';
import { Howto } from '../../components/Howto';
import Button from '../../components/Button/Button';
import { Section } from '../../components/Section';
import { Slider } from '../../components/Slider';
import { AskQuestion } from '../../components/Form/AskQuestion';

import { PAGE_ARTICLES, PAGE_STRATEGIES } from '../Routes';
import Request from './Request/Request';
import { addHeaderClassName } from '../../HOC/addHeaderClassName';
import { SEO_CONTENT } from '../../const';

import { ArticleList } from '../../components/ArticleList';

import data from './home-page-howto';

import { ArticlesLast$, articlesLastApi } from '../../effector/articlesLast';
import { DealsLast$, dealsLastApi } from '../../effector/dealsLast';
import { getProgressSymbol } from '../../utils';
import { Deal } from '../../components/Deal';
import { Link } from '../../components/Link';
import { Banners$, bannersApi } from '../../effector/banners';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { UTM_PARAMS } from '../../services/queryService';
import { GAService } from '../../services/gaService';

const COMMENT_LENGTH = 200;

const HomePage = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const top = useStore(StrategiesTop$);
  const banners = useStore(Banners$);
  const articles = useStore(ArticlesLast$);
  const deals = useStore(DealsLast$);

  useEffect(() => {
    strategiesTopApi.get();
    bannersApi.get(params[UTM_PARAMS.UTM_CONTENT]);
    articlesLastApi.get(3);
    dealsLastApi.get(3);
  }, []);

  const seo = SEO_CONTENT['/'];

  return (
    <>
      <Helmet {...seo} />
      <Slider items={banners} />
      <Section>
        <header className="section__header">
          <h1 className="section__title">Toп 6 стратегий</h1>
        </header>
        <div className="section__content">
          <Cards
            items={top}
            footer={
              <footer className="cards__footer">
                <Button
                  link={PAGE_STRATEGIES}
                  tag="a"
                  className="_primary _large"
                  text="Посмотреть все"
                  onCLick={() => {
                    GAService.sendEvent('List_strategies');
                  }}
                />
              </footer>
            }
          />
        </div>
      </Section>
      <Section>
        <header className="section__header _divider">
          <div className="section__title">Новости и аналитика</div>
          <Link link={PAGE_ARTICLES} className="section__link">
            вся аналитика
          </Link>
        </header>

        <div className="section__content">
          {articles && <ArticleList items={articles} />}
        </div>
      </Section>
      {deals && (
        <Section>
          <header className="section__header _divider">
            <div className="section__title">Последние сделки по стратегиям</div>
          </header>

          <div className="section__content">
            <div className="recent-deals recent-deals_horizontal">
              {deals.map(
                (
                  {
                    realizedPl,
                    symbol,
                    name,
                    time,
                    comment,
                    strategyName,
                    strategyId,
                    friendlyUrl
                  }
                ) => {
                  const title = `${getProgressSymbol(realizedPl)} ${Math.abs(
                    realizedPl
                  )}% ${symbol} (${name})`;

                  const text =
                    comment.length > COMMENT_LENGTH
                      ? `${comment.substring(
                          0,
                          COMMENT_LENGTH
                        )}...<span class="recent-deal__description-more">читать полностью</span>`
                      : comment;

                  return (
                    <Link
                      key={strategyId}
                      link={`${PAGE_STRATEGIES}/${friendlyUrl}`}
                      className="recent-deal-wrapper">
                      <Deal
                        realizedPl={realizedPl}
                        title={title}
                        subTitle={strategyName}
                        time={time}
                        comment={text}
                        className="recent-deal-main"
                      />
                    </Link>
                  );
                }
              )}
            </div>
          </div>
        </Section>
      )}
      <Section className="_dark _howto">
        <header className="section__header">
          <div className="section__title">{data.title}</div>
        </header>
        <div className="section__content">
          <p
            className="section__desc"
            dangerouslySetInnerHTML={{ __html: data.desc }}
          />
          <Howto items={data.items} />
        </div>
      </Section>
      <AskQuestion
        name="Задать вопрос"
        eventName="MainQuestionSend"
      >
        Ответы на самые популярные вопросы мы собрали для вас в {' '}
        <Link link="/faq" className="section__link decorated">
          справочном разделе.
        </Link>
        <br />
        <br />
        Если вы не нашли нужную информацию - задайте вопрос эксперту.
      </AskQuestion>
      <Request />
    </>
  );
};

export default addHeaderClassName(HomePage);
