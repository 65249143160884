import React, {useCallback, useEffect, useMemo, useState} from 'react';
import * as Yup from 'yup';
import cn from 'classnames';
import {connect} from 'react-redux';
import {useStore} from 'effector-react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {Field, Form, Formik, FormikProps} from 'formik';

import * as PopupActions from '../../../actions/PopupActions';

import {toJS} from '../../../HOC/toJS';

import {bindStrategy} from '../../../api';

import {POP_UP} from '../../../const';

import {formatMoney, roundNumber} from '../../../utils';
import {required} from '../../../utils/Form/formik-validation';

import Button from '../../Button/Button';
import {Select} from '../../FormComponents/Select';

import {Option} from '../../../types/option';
import {Nullable} from '../../../types/utils';
import {ClientAccount, CurrencyPortfolio} from '../../../types/clientAccount';

import {Strategy$} from '../../../effector/strategy';
import {Account$, accountApi} from '../../../effector/clientAccounts';
import {ServiceKind} from "../../../types/strategy";

export const BINDING_TYPE = {
  AUTO_FOLLOWING: 1,
  RECOMMENDATIONS: 2
};

const speedSettings = {
  invest: {
    label: 'Минимальная сумма инвестиций',
    addition: ''
  },
  drawdown: {
    label: 'Допустимая просадка',
    addition: '%'
  }
};

const validationSchema = Yup.object().shape({
  bill: Yup.object().nullable().required(required)
});

const initialValues = {
  bill: null,
};

type FormFields = {
  bill: Nullable<Option>;
};

type FormProps = {
  account: Nullable<ClientAccount>;
  accountPrice: Nullable<CurrencyPortfolio>;
  options: Option[];
  error: string;
  checkBillRestriction: (bill: Nullable<Option>) => void;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({
                                             account,
                                             accountPrice,
                                             options,
                                             values,
                                             error,
                                             checkBillRestriction
                                           }) => {

  useEffect(() => {
    checkBillRestriction(values.bill);
  }, [values.bill]);

  return (
    <Form noValidate>
      <div className="modal-strategy__info-row _account">
        <div className="modal-strategy__info-title">Счет</div>
        <div className="modal-strategy__info-description">

          <Field
            name="bill"
            component={Select}
            className="modal-strategy__select _small"
            placeholder="Выбрать счет для подключения"
            options={options}
          />

          <div className="modal-strategy__account-content">
            {account && accountPrice && (
              <>
                <div className="modal-strategy__account-row">
                  <div className="modal-strategy__account-title">
                    Всего средств
                  </div>
                  <div className="modal-strategy__account-info">
                    {formatMoney(
                      accountPrice.liquidPrice,
                      accountPrice.currency,
                      0
                    )}
                  </div>
                </div>
                <div className="modal-strategy__account-row">
                  <div className="modal-strategy__account-title">
                    Свободные средства
                  </div>
                  <div className="modal-strategy__account-info">
                    {formatMoney(
                      accountPrice.freeFunds,
                      accountPrice.currency,
                      0
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {error && <p className="error-message" dangerouslySetInnerHTML={{__html: error}} />}
    </Form>
  )
};

type Props = {
  popupActions: any;
  className: string;
}

const AddStrategy: React.FC<Props> = ({className, popupActions}) => {
  const [errorMessage, setError] = useState<string>('');
  const [bill, setBill] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [accountHasPositions, setAccountHasPositions] = useState<boolean>(false);

  const {accounts, refreshedAccounts} = useStore(Account$);
  const strategy = useStore(Strategy$);

  useEffect(() => {
    if (strategy) {
      accountApi.refreshAccountsV2(strategy.id);
    }
    return () => {
      accountApi.resetRefreshV2('');
    }
  }, [strategy]);

  const getPromotion = useCallback((type: ServiceKind) => {
    const selectedAccount = accounts.find(acc => acc.clientCode === bill);

    return selectedAccount && refreshedAccounts.find(({agreementId}) =>
      agreementId === selectedAccount?.agreementGuid)?.campaigns?.find(({serviceKind}) => serviceKind === type);
  }, [accounts, refreshedAccounts, bill]);

  const afPromotion = useMemo(() => getPromotion(ServiceKind.AF), [getPromotion]);
  const acPromotion = useMemo(() => getPromotion(ServiceKind.AC), [getPromotion]);

  const checkBillRestriction = (newBill: Nullable<Option>) => {
    if (newBill && newBill.id !== bill) {
      const account = accounts.find(acc => acc.clientCode === newBill.id);

      setBill(newBill?.id);
      setError('');
      setAccountHasPositions(account?.hasOpenPositions as boolean);
    }
  }

  // @ts-ignore
  const sendData = (connectionParams, type) => {
    const binding = {
      ...connectionParams,
      clientCode: bill,
      strategyId: strategy?.id,
      confirmationCode: ''
    };

    setLoading(true);

    if (
      accountHasPositions &&
      binding.bindingType === BINDING_TYPE.AUTO_FOLLOWING
    ) {
      popupActions.openPopup(POP_UP.AUTO_FOLLOWING_ACCEPT, binding);
    } else {
      bindStrategy(binding).then(result => {
        const {errorMessage, success, response} = result;

        if (success) {
          const popupData = {...binding, content: response.acceptHtml};

          popupActions.openPopup(type, popupData);
        } else {
          setError(errorMessage);
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const openAutoFollowing = () => {
    const data = {
      bindingType: BINDING_TYPE.AUTO_FOLLOWING,
      sendIirSms: false,
      text: `<p>Спасибо! <br />
       Ваша заявка принята! <br />
       Статус подключения услуги вы можете увидеть в личном кабинете. <br /><br />
       После подключения автоследования вы получите на электронную почту индивидуальную инвестиционную рекомендацию, на основании которой будет произведена балансировка портфеля.
       </p>
        `
    };

    sendData(data, POP_UP.AUTO_FOLLOWING);
  };

  const openRecommendations = () => {
    const data = {
      bindingType: BINDING_TYPE.RECOMMENDATIONS,
      sendIirSms: true,
      text: `<p>Спасибо! <br />
       Ваша заявка принята! <br />
       Статус подключения услуги вы можете увидеть в личном кабинете.<br /><br />
       После подключения автоконсультирования вы получите на электронную почту индивидуальную инвестиционную рекомендацию, на основании которой вы можете привести свой инвестиционный портфель в соответствие портфелю выбранной стратегии.
       </p>
        `
    };

    sendData(data, POP_UP.RECOMMENDATIONS);
  };

  const getGrangeData = () => {

    const settings = [
      {
        ...speedSettings.drawdown,
        value: roundNumber(strategy?.estimatedRisk)
      },
      {
        ...speedSettings.invest,
        value: formatMoney(strategy?.subscriptionThreshold, strategy?.currency.id, 0)
      }
    ];

    return settings;
  };

  const getAccountsData = () => {
    const options = accounts.map(item => ({
      name: `${item.agreementId} (${item.clientCode})`,
      id: item.clientCode
    }));

    return options;
  };

  const isCorrect = () => {
    return !bill || errorMessage;
  };

  if (!strategy || !accounts.length) {
    return null;
  }

  const options = getAccountsData();
  const granges = getGrangeData();
  const account = accounts.find(acc => acc.clientCode === bill);
  const currencyPortfolios = account?.currencyPortfolios;
  const accountPrice = currencyPortfolios?.find(port => port.currency === strategy.currency.id);

  return (
    <div className={cn('add-strategy', className)}>
      <div className="modal-strategy__info">
        <div className="modal-strategy__info-row">
          <div className="modal-strategy__info-title">Стратегия</div>
          <div className="modal-strategy__info-description">
            {strategy.name}
          </div>
        </div>
        <div className="modal-strategy__info-row">
          <div className="modal-strategy__info-title">
            Категория стратегии
          </div>
          <div className="modal-strategy__info-description">
            {strategy.category}
          </div>
        </div>
        <div className="modal-strategy__info-row">
          <div className="modal-strategy__info-title">
            Инвестиционный профиль
          </div>
          <div className="modal-strategy__info-description">
            {strategy.minInvestProfile.name}
          </div>
        </div>
        <div className="modal-strategy__info-row">
          <div className="modal-strategy__info-title">
            Параметры стратегии
          </div>
          <div className="modal-strategy__info-description">
            {granges.map((item, key) => (
              <div key={key}>
                {item.label}: {item.value}
                {item.addition}
              </div>
            ))}
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          onSubmit={() => console.log('')}>
          {props =>
            // @ts-ignore
            React.createElement(FormTemplate, {
              ...props,
              account,
              options,
              accountPrice,
              error: errorMessage,
              checkBillRestriction
            })
          }
        </Formik>
      </div>
      <div className="modal-strategy__buttons">
        <div className="modal-strategy__buttons-row">
          {strategy?.autoconsult && (
            <div className="modal-strategy__buttons-col">
              <div>
                <Button
                  disabled={isCorrect()}
                  loading={loading}
                  className="_primary _large _wide modal-strategy__button"
                  text="Подключить автоконсультирование"
                  onClick={openRecommendations}
                />
              </div>
              <div className="modal-strategy__buttons-description">
                Получайте персональные рекомендации по сделкам автора
                стратегии. Сделки по рекомендациям вы можете совершать
                самостоятельно в любое время
              </div>
              <div className="modal-strategy__buttons-price">
                Стоимость услуги: {acPromotion
                ? <span className="modal-strategy__buttons-promotion">{acPromotion.tariffDisplayValue}</span>
                : strategy.priceAC}
              </div>
            </div>
          )}
          {strategy?.autofollow && (
            <div className="modal-strategy__buttons-col">
              <div>
                <Button
                  disabled={isCorrect()}
                  loading={loading}
                  className="_success _large _wide modal-strategy__button"
                  text={'Подключить\nавтоследование'}
                  onClick={openAutoFollowing}
                />
              </div>
              <div className="modal-strategy__buttons-description">
                Мы автоматически составим вам сбалансированный портфель по
                стратегии автора и будем следить за его актуальностью
              </div>
              <div className="modal-strategy__buttons-price">
                Стоимость услуги: {afPromotion
                  ? <span className="modal-strategy__buttons-promotion">{afPromotion.tariffDisplayValue}</span>
                  : strategy.priceAF}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

// tslint:disable-next-line:no-default-export
export default withRouter(connect(
    null,
    mapDispatchToProps
    // @ts-ignore
    )((toJS(AddStrategy))
    ));
