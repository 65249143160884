import React from 'react';

// import {PromoStrategies} from './strategies';

import image from './default.png';
import { PromoPageWrapper } from '../../components/PromoPageWrapper';
import { enteredAnimate } from '../../HOC/enteredAnimate';

import data2 from './get-it-free';
import data from './get-it-free-ny.json';

const LeftColumn = () => (
  <>

    <div
      className="page-template__content-title"
      dangerouslySetInnerHTML={{ __html: data.title }}></div>
    <div className="page-template__content">
      {data.items.map(({ name, points }) => (
        <>
          <div
            key={Math.random()}
            className="page-template__content_point_name">
            {name}
          </div>
          {points.map(({ content, subpoints }) => (
            <>
              <p
                key={Math.random()}
                className="page-template__content_point_description"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              {subpoints &&
              subpoints.map(({ content }) => (
                <p
                  key={Math.random()}
                  className="page-template__content_subpoint_description"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ))}
            </>
          ))}
        </>
      ))}
    </div>
    <div className="page-template__content">
      <a name="footnote-1">
        На период проведения Акции в отношении Участников Акции ставки R1 и R2 для целей расчета вознаграждения
        ООО «Компания БКС» за оказание Клиенту услуги «Автоследование» (Fee) для всех категорий
        Стратегий предоставления ИИР устанавливаются в размере 0% годовых, соответственно,
        сумма вознаграждения, рассчитываемого в соответствии с п.31.5.1 Приложения №11 к Регламенту (𝑭𝒆𝒆𝒎𝒇),
        и вознаграждения, рассчитываемого в соответствии с п.31.5.2 Приложения №11 к Регламенту (𝑭𝒆𝒆𝒔𝒇) будет также равняться 0 руб.
      </a>
    </div>

    <div
      className="page-template__content-title"
      dangerouslySetInnerHTML={{ __html: data2.title }}></div>
    <div className="page-template__content">
      {data2.items.map(({ name, points }) => (
        <>
          <div
            key={Math.random()}
            className="page-template__content_point_name">
            {name}
          </div>
          {points.map(({ content, subpoints }) => (
            <>
              <p
                key={Math.random()}
                className="page-template__content_point_description"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              {subpoints &&
                subpoints.map(({ content }) => (
                  <p
                    key={Math.random()}
                    className="page-template__content_subpoint_description"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                ))}
            </>
          ))}
        </>
      ))}
    </div>
    <div className="page-template__content">
      <a name="footnote-1">
        1. Наименование Акции служит исключительно в целях индивидуализации
        Акции. Наименование Акции не определяет условия Акции и их сущность.
      </a>
    </div>
  </>
);

const LeftColumnConnected = enteredAnimate(LeftColumn);

const RightColumn = () => (
  <>
    {/*<div className="page-template__suggestion_title">*/}
    {/*  Стратегии, участвующие в Акции:*/}
    {/*</div>*/}
    {/*<div className="page-template__suggestion_content">*/}
    {/*  <PromoStrategies />*/}
    {/*</div>*/}
  </>
);

export const GetFreePage = () => (
  <PromoPageWrapper
    image={image}
    showRight={false}
    LeftComponent={LeftColumnConnected}
    RightComponent={RightColumn}
  />
);
