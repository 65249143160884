import Cookies from 'js-cookie';

const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';

class AuthenticationService {
  constructor() {
    this._accessToken = Cookies.get(ACCESS_TOKEN) || '';
    this._refreshToken = Cookies.get(REFRESH_TOKEN) || '';
  }

  get accessToken() {
    return this._accessToken || '';
  }

  get headerAccessToken() {
    return this._accessToken ? `Bearer ${this._accessToken}` : '';
  }

  get refreshToken() {
    return this._refreshToken;
  }

  set accessToken(token) {
    this._accessToken = token;
    Cookies.set(ACCESS_TOKEN, token);
  }

  set refreshToken(token) {
    this._refreshToken = token;
    Cookies.set(REFRESH_TOKEN, token);
  }

  clearAccessToken = () => {
    this._accessToken = '';
    Cookies.set(ACCESS_TOKEN, '');
  };

  clearRefreshToken = () => {
    this._refreshToken = '';
    Cookies.set(REFRESH_TOKEN, '');
  };
}

const AuthenticationServiceInstance = new AuthenticationService();

export { AuthenticationServiceInstance as AuthenticationService };
