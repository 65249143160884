import React from 'react';

// @ts-ignore
export const Search: React.FC<{}> = ({ field }) => (
  <input
    className="filter__field"
    type="search"
    {...field}
    placeholder=""
  />
);
