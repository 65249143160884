import React from 'react';
import cn from 'classnames';

import Card from './Card/Card.js';
import { enteredAnimate } from '../../HOC/enteredAnimate';
import { Strategy } from '../../types/strategy';

type Props = {
  items: Strategy[];
  className?: string;
  footer?: React.ReactNode;
};

// @ts-ignore
const Cards: React.FC<Props> = ({ className, items, footer }) => (
  <div>
    <div className={cn('cards', className)}>
      <div className="cards__list">
        {items.map((item, key) => (
          <div className="cards__item" key={key}>
            <Card {...item} num={key} />
          </div>
        ))}
      </div>
      {footer}
    </div>
  </div>
);

export default enteredAnimate(Cards);
