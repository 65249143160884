import { createStore, createApi, createEffect, forward } from 'effector';

import {
  getAccount,
  getClientAccounts,
  getProfile,
  login,
  refreshAccount, refreshAccountV2
} from '../api';
import { Nullable } from '../types/utils';
import { Account } from '../types/account';
import { ClientAccount } from '../types/clientAccount';
import { Profile } from '../types/profile';
import { AuthenticationService } from '../services/authenticationService';
import { resetData } from '../actions/GeneralActions';
import { openPopup } from '../actions/PopupActions';
import { POP_UP } from '../const';

import { store } from '../services/store/configureStore';
import {RefreshedAccount} from '../types/refreshedAccount';

type TAccount$State = {
  account: Nullable<Account>;
  accounts: ClientAccount[];
  refreshedAccounts: RefreshedAccount[];
  profile: Nullable<Profile>;
  isFetchingProfile: boolean;
};

const initialState: TAccount$State = {
  account: undefined,
  accounts: [],
  refreshedAccounts: [],
  profile: undefined,
  isFetchingProfile: false
};

export const Account$ = createStore<TAccount$State>(initialState);

const {
  setAccounts,
  setProfile,
  setAccount,
  refresh,
  refreshV2,
  setFetching,
  reset,
  resetRefreshV2
} = createApi(Account$, {
  setAccount: (state, { result }) => {
    const { success, response } = result;

    if (success) {
      accountApi.getAccounts('');
      accountApi.fetching(true);
      accountApi.getProfile('').finally(() => {
        accountApi.fetching(false);
      });

      return { ...state, account: response };
    }

    return state;
  },
  setAccounts: (state, { result }) => {
    return { ...state, accounts: result.response };
  },
  setProfile: (state, { result }) => {
    return { ...state, profile: result.response };
  },
  refresh: (state, { result }) => {
    const { success, response } = result;
    if (success) {
      return {
        ...state,
        account: response.account,
        accounts: response.accounts,
        profile: response.profile
      };
    }
    return state;
  },
  refreshV2: (state, { result }) => {
    const { success, response } = result;
    if (success) {
      return {
        ...state,
        refreshedAccounts: response
      };
    }
    return state;
  },
  setFetching: (state, value) => {
    return { ...state, isFetchingProfile: value };
  },
  reset: (state, value) => {
    AuthenticationService.clearAccessToken();
    AuthenticationService.clearRefreshToken();
    store.dispatch(resetData());
    store.dispatch(openPopup(POP_UP.AUTHORIZATION));

    return initialState;
  },
  resetRefreshV2: (state) => {
    return {
      ...state,
      refreshedAccounts: []
    }
  }
});

export const accountApi = {
  login: createEffect().use(login),
  logOut: createEffect(),
  getAccount: createEffect().use(getAccount),
  getProfile: createEffect().use(getProfile),
  getAccounts: createEffect().use(getClientAccounts),
  refreshAccounts: createEffect().use(refreshAccount),
  refreshAccountsV2: createEffect<string, {}, {}>().use(refreshAccountV2),
  fetching: createEffect(),
  resetRefreshV2: createEffect()
};

forward({
  from: accountApi.login.done,
  to: setAccount
});

forward({
  from: accountApi.getAccount.done,
  to: setAccount
});

forward({
  from: accountApi.refreshAccounts.done,
  to: refresh
});

forward({
  from: accountApi.refreshAccountsV2.done,
  to: refreshV2
});

forward({
  from: accountApi.getAccounts.done,
  to: setAccounts
});

forward({
  from: accountApi.getProfile.done,
  to: setProfile
});

forward({
  from: accountApi.fetching,
  to: setFetching
});

forward({
  from: accountApi.logOut,
  to: reset
});

forward({
  from: accountApi.resetRefreshV2,
  to: resetRefreshV2
})
