import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string;
  isActive: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const Burger: React.FC<Props> = ({
  className,
  isActive,
  onOpen,
  onClose
}) => {
  const onClick = () => {
    if (isActive) {
      onClose();
    } else {
      onOpen();
    }
  };

  return (
    <button
      onClick={onClick}
      className={classNames('burger', className, { _active: isActive })}
      type="button">
      <span className="burger__text">Меню</span>
    </button>
  );
};
