import React from 'react'
import { withRouter } from 'react-router-dom'

import {Section} from '../../../components/Section'
import Button from '../../../components/Button/Button'

import { PAGE_EXPERTS } from '../../Routes'
import { GAService } from '../../../services/gaService'

// eslint-disable-next-line
const Request = ({ history }) => {

  const onClick = () => {
    GAService.sendEvent('MainAutorsRequest')
    history.push(PAGE_EXPERTS)
  }

  return (
    <Section className={'_request'}>
      <div className='section__content'>
        <div className='section__title'>Хотите предложить нам свою стратегию?</div>
        <p className='section__desc' />
        <div>
          <Button
            className='_primary _large'
            type='button'
            text='Заполнить форму'
            onClick={onClick}
          />
        </div>
      </div>
    </Section>
  )
}

export default withRouter(Request)
