import React, { useEffect, useState } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { email, required } from '../../utils/Form/formik-validation';
import Button from '../Button/Button';
import { post } from '../../api/apiHelper';
import { Input } from '../FormComponents/Input';
import { POP_UP } from '../../const';
import { connect } from 'react-redux';
import { toJS } from '../../HOC/toJS';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../actions/PopupActions';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(email)
    .required(required)
});

const initialValues = {
  email: ''
};

type FormFields = {
  email: string;
};

type FormProps = {
  isSubscribed: boolean;
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({
  isSubscribed,
  loading,
  error,
  errors,
  resetForm
}) => {
  useEffect(() => {
    resetForm();
  }, [isSubscribed]);

  const disable = Object.keys(errors).length > 0;

  return (
    <Form className="section__form" noValidate>
      <div className="form-control-group">
        <div className="form-control-group__item">
          <Field
            type="email"
            name="email"
            placeholder="Email"
            component={Input}
          />
        </div>
        <div className="form-control-group__item">
          <Button
            type="submit"
            loading={loading}
            disabled={disable}
            className="subscribe-to-analytics__button _success _large"
            text="Подписаться"
          />
        </div>
      </div>

      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

type Props = {
  popupActions: any;
};

const SubscribeToAnalytics: React.FC<Props> = ({ popupActions }) => {
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = (values: FormFields) => {
    const request = {
      email: values.email
    };

    setIsSubscribed(false);
    setLoading(true);
    setError('');

    post('analytics/subscribe', request)
      .then(({ success, errorMessage }) => {
        if (success) {
          setIsSubscribed(true);
          popupActions.openPopup(POP_UP.SUBSCRIBE, {
            description:
              `Поздравляем! <br /> Теперь вы подписаны на новости и аналитику от экспертов FinTarget!`
          });
        } else {
          setError(errorMessage);
        }
      })
      .catch(errorMessage => {
        setError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="subscribe-to-analytics">
      <div className="subscribe-to-analytics__description">
        <div className="subscribe-to-analytics__description-title">
          Подпишись на новости и аналитику
        </div>
        <div className="subscribe-to-analytics__description-sub-title">
          Аналитика и новости от экспертов FinTarget
        </div>
      </div>
      <div className="subscribe-to-analytics__actions">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          onSubmit={onSubmit}>
          {props =>
            React.createElement(FormTemplate, {
              ...props,
              isSubscribed,
              loading,
              error
            })
          }
        </Formik>
      </div>
    </div>
  );
};

// @ts-ignore
const mapStateToProps = state => ({
  popupData: state.getIn(['popup', 'data'])
});

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

const wrappedConponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(SubscribeToAnalytics));

export { wrappedConponent as SubscribeToAnalytics };
