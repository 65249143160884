import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

const h1 = {
  textAlign: 'center',
  fontSize: '30px',
  fontWeight: '600',
  marginBottom: '20px'
}

const h2 = {
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: '600',
  marginBottom: '20px'
}

class ErrorBoundary extends Component {

  state = { hasError: false };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.state.hasError &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      // eslint-disable-next-line
      this.setState({ hasError: false });
    }
  }
  // eslint-disable-next-line
  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    // logErrorToMyService(error, info);
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1 style={h1}>Произошла непредвиденная ошибка</h1>
          <div style={h2}>Попробуйте загрузить другую страницу</div>
        </div>
      )
    }

    return this.props.children
  }
}

const withRouterComponent = withRouter(ErrorBoundary)

export { withRouterComponent as ErrorBoundary }
