import React from 'react';
import { Article } from './Article';
import { AnalyticsBrief } from '../../types/analyticsBrief';

type Props = {
  items: AnalyticsBrief[];
};

// @ts-ignore
export const ArticleList: React.FC<Props> = ({ items }) => {
  const firstColumnCount = 2;

  return (
    <div className="news">
      <div className="news__row">
        <div className="news__col">
          {items.map(
            (item, key) =>
              key < firstColumnCount && (
                <Article {...item} key={key} className="_wide" />
              )
          )}
        </div>
        <div className="news__col">
          {items[firstColumnCount] && (
            <Article {...items[firstColumnCount]} className="_long _right" />
          )}
        </div>
      </div>
    </div>
  );
};
