import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as Follow } from '../AboutPage/assets/follow.svg';
import { ReactComponent as Recommendation } from '../AboutPage/assets/recommendations.svg';
import { ReactComponent as Attention } from '../Promo/attention.svg';
import { useStore } from 'effector-react';
import { StrategiesTop$, strategiesTopApi } from '../../effector/top';
import { QueryService, UTM_PARAMS } from '../../services/queryService';
import { GAService } from '../../services/gaService';
import { Element, scroller } from 'react-scroll';
import {
  PAGE_ARTICLES,
  PAGE_ONBOARDING,
  PAGE_PERSONAL,
  PAGE_STRATEGIES
} from '../Routes';
import { Slider } from '../../components/Slider';
import { Section } from '../../components/Section';
import { Howto } from '../../components/Howto';
import Button from '../../components/Button/Button';
import { ReactComponent as Visual } from '../AboutPage/assets/visual.svg';
import { ReactComponent as Ellipse } from '../AboutPage/assets/ellipse.svg';
import cn from 'classnames';
import YouTube from 'react-youtube';
import { Wave } from '../../components/Cards/Wave';
import Cards from '../../components/Cards/Cards';
import { AskQuestion } from '../../components/Form/AskQuestion';
import { Link } from '../../components/Link';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../actions/PopupActions';
import { connect } from 'react-redux';
import { toJS } from '../../HOC/toJS';
import { withQuery, getProgressSymbol } from '../../utils';
import { Banners$, bannersApi } from '../../effector/banners';
import { ArticlesLast$, articlesLastApi } from '../../effector/articlesLast';
import { DealsLast$, dealsLastApi } from '../../effector/dealsLast';
import { ArticleList } from '../../components/ArticleList';
import { Deal } from '../../components/Deal';
import { useHistory } from 'react-router-dom';
import { POP_UP, SEO_CONTENT } from '../../const';
import {SubscribeToAnalytics} from '../../components/SubscribeToAnalytics';
import {Account$} from '../../effector/clientAccounts';
import Helmet from 'react-helmet';

const connections = [
  {
    image: <Follow />,
    title: 'Оставьте заявку',
    description: `Мы поможем вам в открытии счета и подробнее расскажем о нашей площадке.`
  },
  {
    image: <Recommendation />,
    title: 'Поставьте перед нами задачу',
    description: `Сохранить или рискнуть и заработать? Подберем для вас подходящую стратегию.`
  },
  {
    image: <Attention />,
    title: 'Наблюдайте за результатом',
    description: `В личном кабинете понятная информация о состоянии ваших вложений.`
  }
];

const bcsInfo = [
  {
    columns: [
      {
        value: '25',
        description: 'лет на рынке'
      },
      {
        value: '37,7 млрд $',
        description: `объем клиентских операций <br /> на фондовом рынке`
      }
    ]
  },
  {
    columns: [
      {
        value: '5000',
        description: 'сотрудников'
      },
      {
        value: '370 000',
        description: 'клиентов в России'
      }
    ]
  },
  {
    columns: [
      {
        value: '96',
        description: 'агентских пунктов'
      },
      {
        value: '1',
        description: `место в рейтингах <br />Московской биржи`
      }
    ]
  }
];

const promos = [
  {
    id: 1,
    title: 'Что такое инвестиционная стратегия',
    description:
      'Виктор Бондарович рассказывает, что такое инвестиционная стратегия, зачем она нужна, и с чего нужно начать формирование собственной стратегии.',
    video: 'vaT3EjVlVhk'
  },
  {
    id: 2,
    title: 'Какие бывают инвестиционные стратегии',
    description:
      'Виктор Бондарович рассказывает про основные виды стратегий: классические, алгоритмические и стратегии на основе искусственного интеллекта.',
    video: 'MYkESTREudQ'
  },
  {
    id: 3,
    title: 'Как выбрать инвестиционную стратегию',
    description:
      'Всеволод Зубов рассказывает, как выбрать инвестиционную стратегию. Для этого нужно определить финансовую цель, готовность к риску и срок инвестирования.',
    video: 'AIrqjeGKcRk'
  },
  {
    id: 4,
    title: 'Как стратегии попадают на финтаргет',
    description:
      'Ларг Сапецкий рассказывает, как авторские стратегии попадают на Fintarget: отбор, который проходят авторы торговых стратегий, риск-менеджмент стратегии, инвестиционный профиль стратегии.',
    video: 'q_t6RZUY55M'
  }
];

const COMMENT_LENGTH = 200;

type Props = {
  popupActions: any;
};

const MainPage: React.FC<Props> = ({ popupActions }) => {
  const [promo, setPromo] = useState(promos[0]);
  const history = useHistory();

  const top = useStore(StrategiesTop$);
  const banners = useStore(Banners$);
  const articles = useStore(ArticlesLast$);
  const deals = useStore(DealsLast$);
  const {account} = useStore(Account$);

  const params = {
    [UTM_PARAMS.REF_ID]: QueryService.refId,
    [UTM_PARAMS.UTM_SOURCE]: QueryService.utmSource,
    [UTM_PARAMS.UTM_CAMPAIGN]: QueryService.utmCampaign,
    [UTM_PARAMS.UTM_CONTENT]: QueryService.utmContent,
    [UTM_PARAMS.UTM_MEDIUM]: QueryService.utmMedium,
    [UTM_PARAMS.UTM_TERM]: QueryService.utmTerm
  };

  const requestEntityCount = 3;

  useEffect(() => {
    strategiesTopApi.get('');
    bannersApi.get(params[UTM_PARAMS.UTM_CONTENT]);
    articlesLastApi.get(requestEntityCount);
    dealsLastApi.get(requestEntityCount);
  }, []);

  const scrollTo = () => {
    GAService.sendEvent('');

    scroller.scrollTo('callback', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  const openAccount = () => {
    GAService.sendEvent('ButtonOpenDepo');

    const url = withQuery(params)(PAGE_ONBOARDING);

    const win = window.open(url, '_blank');
    win?.focus();
  };

  const toPersonalPage = () => {
    if (account) {
      history.push(PAGE_PERSONAL);
    } else {
      popupActions.openPopup(POP_UP.AUTHORIZATION, {
        onSuccess: () => {
          history.push(PAGE_PERSONAL);
        }
      });
    }
  };

  const items = [
    {
      title: 'Открываем счет',
      link: PAGE_ONBOARDING,
      onClick: openAccount
    },
    {
      title: 'Выбираем и подключаем стратегию',
      link: PAGE_STRATEGIES
    },
    {
      title: 'Всё',
      link: '',
      onClick: toPersonalPage
    }
  ];

  const bannersWithLinkParams = useMemo(() => {
    const updatedBunners = banners.map(item => {
      if (item.url.includes(PAGE_ONBOARDING)) {
        return { ...item, url: withQuery(params)(item.url) };
      }

      return item;
    });

    return updatedBunners;
  }, [banners]);

  const seo = SEO_CONTENT['/'];

  return (
    <>
      {seo && <Helmet {...seo} />}
      <div className="promo">
        <Slider items={bannersWithLinkParams} />

        <Section>
          <h1 className="about-page__subtitle">
            Мы сделали инвестиции еще доступнее
          </h1>
          <Howto items={items} className="about" />
          <div className="promo__button-wrapper">
            <Button
              onClick={openAccount}
              className="button _primary _large"
              type="button"
              text="Открыть счет"
            />
          </div>
        </Section>

        <Section>
          <div className="about-page__possibilities possibilities">
            <div className="possibilities__visualization">
              <Visual />
              <Ellipse className="ellipse" />
            </div>
            <div className="possibilities__description">
              <div className="possibilities__title">
                Автоследование доступно каждому:
              </div>
              <ul className="possibilities__description-list">
                <li className="possibilities__description-list-item">
                  Не надо ехать в офис, все в онлайне
                </li>
                <li className="possibilities__description-list-item">
                  Информативный личный кабинет
                </li>
                <li className="possibilities__description-list-item">
                  Удобное подключение стратегий
                </li>
                <li className="possibilities__description-list-item">
                  Прозрачная информация по комиссиям
                </li>
                <li className="possibilities__description-list-item">
                  Не требуется отбирать и покупать акции самостоятельно
                </li>
                <li className="possibilities__description-list-item">
                  Доступно в мобильном приложении «Мир Инвестиций»
                </li>
              </ul>
            </div>
          </div>
          <div className="promo__button-wrapper">
            <Button
              onClick={() => scrollTo()}
              className="button _success _large"
              type="button"
              text="Как подключить"
            />
          </div>
        </Section>

        <Section className="promo__section-marketplace">
          <header className="section__header promo__section-header">
            <div className="section__title">Как это работает</div>
            <div className="promo__section-subtitle">
              Видео о том, как работает автоследование
            </div>
          </header>
          <div className="promo__marketplace">
            <ul className="promo__marketplace-list">
              {promos.map((item, index) => (
                <li
                  key={index}
                  className="promo__marketplace-list-item"
                  onClick={() => setPromo(item)}>
                  <span
                    className={cn('promo__marketplace-list-item-span', {
                      'promo__marketplace-list-item-span_active':
                        item.id === promo.id
                    })}>
                    {item.title}{' '}
                  </span>
                </li>
              ))}
            </ul>
            <div className="promo__marketplace-content">
              <YouTube
                videoId={promo.video}
                containerClassName="promo__marketplace-video"
              />
              <div className="promo__marketplace-video-description">
                {promo.description}
              </div>
            </div>
          </div>
          <div className="promo__button-wrapper">
            <Button
              onClick={() => scrollTo()}
              className="button _success _large"
              type="button"
              text="Оставить заявку"
            />
          </div>
          <Wave rootClass="promo__waves" />
        </Section>

        <Section>
          <header className="section__header">
            <div className="section__title">Более 25 стратегий от 10+ авторов</div>
          </header>
          <div className="section__content">
            <Cards
              items={top}
              footer={
                <footer className="cards__footer">
                  <Button
                    link={PAGE_STRATEGIES}
                    tag="a"
                    onCLick={() => {
                      GAService.sendEvent('List_strategies');
                    }}
                    className="_primary _large"
                    text="Смотреть все стратегии"
                  />
                </footer>
              }
            />
          </div>
        </Section>

        <Section>
          <div className="about-page__subtitle promo__subtitle">С чего начать</div>
          <div className="promo__connections">
            {connections.map(item => (
              <div className="promo-connections" key={Math.random()}>
                <div className="promo-connections__item-image">{item.image}</div>
                <div className="promo-connections__item-description">
                  <div className="promo-connections__item-title">
                    {item.title}
                  </div>
                  <div
                    className="promo-connections__item-text"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="promo__button-wrapper">
            <Button
              onClick={() => scrollTo()}
              className="button _primary _large"
              type="button"
              text="Оставить заявку"
            />
          </div>
        </Section>

        <Section>
          <header className="section__header _divider">
            <div className="section__title">Новости и аналитика</div>
            <Link link={PAGE_ARTICLES} className="section__link">
              вся аналитика
            </Link>
          </header>

          <div className="section__content">
            {articles && <ArticleList items={articles} />}
          </div>
        </Section>

        <Section>
          <SubscribeToAnalytics />
        </Section>

        {deals && (
          <Section>
            <header className="section__header _divider">
              <div className="section__title">Последние сделки по стратегиям</div>
            </header>

            <div className="section__content">
              <div className="recent-deals recent-deals_horizontal">
                {deals.map(
                  ({
                    realizedPl,
                    symbol,
                    name,
                    time,
                    comment,
                    strategyName,
                    strategyId,
                    friendlyUrl
                  }) => {
                    const title = `${getProgressSymbol(realizedPl)} ${Math.abs(
                      realizedPl
                    )}% ${symbol} (${name})`;

                    const text =
                      // @ts-ignore
                      comment.length > COMMENT_LENGTH
                        ?
                          // @ts-ignore
                          `${comment.substring(
                            0,
                            COMMENT_LENGTH
                          )}...<span class="recent-deal__description-more">читать полностью</span>`
                        : comment;

                    return (
                      <Link
                        key={strategyId}
                        link={`${PAGE_STRATEGIES}/${friendlyUrl}`}
                        className="recent-deal-wrapper">
                        <Deal
                          realizedPl={realizedPl}
                          title={title}
                          subTitle={strategyName}
                          time={time}
                          // @ts-ignore
                          comment={text}
                        />
                      </Link>
                    );
                  }
                )}
              </div>
            </div>
          </Section>
        )}

        <Element name="callback" className="element" />
        <AskQuestion
          name="Оставить заявку"
          eventName="CallbackRequest"
          className="promo__section">
          Мы ответим на все ваши вопросы - заполните эту простую форму и мы вам
          перезвоним!
        </AskQuestion>

        <div className="about-page__section-wrapper">
          <div className="about-page__note">
            Проект разработала компания «БКС брокер» — брокер №1 в рейтинге
            Московской биржи.
            <br />
            «БКС» — это:
          </div>

          <div className="about-page__bcs bcs">
            {bcsInfo.map(item => (
              <div className="bcs__column" key={Math.random()}>
                {item.columns.map(column => (
                  <div className="bcs__column-item" key={Math.random()}>
                    <div className="bcs__column-item-value">{column.value}</div>
                    <div
                      className="bcs__column-item-description"
                      dangerouslySetInnerHTML={{ __html: column.description }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="about-page__abuse">
            <div className="about-page__note about-page__note-right about-page__abuse-text">
              Раскрытие информации о компании, форма подачи обращений и связь со
              службой поддержки:{' '}
              <Link link="https://broker.ru/disclosure">
                https://broker.ru/disclosure
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

const wrappedComponent = connect(
  null,
  mapDispatchToProps
)(toJS(MainPage));

export { wrappedComponent as MainPage };
