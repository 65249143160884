import React from 'react';
import { toJS } from './toJS';
import { connect } from 'react-redux';
import { getComponentDisplayName } from '../utils/getComponentDisplayName';
import { bindActionCreators } from 'redux';
import * as PageHeaderActions from '../actions/PageHeaderActions';
 import { LARGE_CLASS } from '../components/PageHeader';

export const addHeaderClassName = WrappedComponent => {
  const mapDispatchToProps = dispatch => ({
    pageHeaderActions: bindActionCreators(PageHeaderActions, dispatch)
  });

  class CurrentLanguage extends React.Component {
    componentWillMount() {
      setTimeout(() => {
        this.props.pageHeaderActions.addHeaderClass(LARGE_CLASS);
      }, 0);
    }

    componentWillUnmount() {
      this.props.pageHeaderActions.addHeaderClass('');
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  CurrentLanguage.displayName = `CurrentLanguage(${getComponentDisplayName(
    WrappedComponent
  )})`;

  return connect(
    null,
    mapDispatchToProps
  )(toJS(CurrentLanguage));
};
