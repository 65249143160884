import React from 'react';
import { useStore } from 'effector-react';
import { useRouteMatch } from 'react-router-dom';

import { Faq$ } from '../../../effector/faq';
import { QuestionCategory } from '../../../types/questionCategory';

import { QuestionList } from '../components/QuestionList';
import { Link } from '../../../components/Link';

import { ReactComponent as GeneralIcon } from './assets/general.svg';
import { ReactComponent as UsingIcon } from './assets/using.svg';
import { ReactComponent as AlgoIcon } from './assets/algo.svg';
import { ReactComponent as BalanceIcon } from './assets/balance.svg';
import { ReactComponent as AboutIcon } from './assets/about.svg';
import { ReactComponent as AuthorIcon } from './assets/author.svg';

const IconMap: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  common: GeneralIcon,
  use: UsingIcon,
  algorithm: AlgoIcon,
  balance: BalanceIcon,
  about: AboutIcon,
  author: AuthorIcon
};

export const General: React.FC<{}> = ({}) => {
  const categories = useStore<QuestionCategory[]>(Faq$);
  const { url } = useRouteMatch();

  return (
    <div className="faq-general">
      {categories.map((item, index) => {
        const Icon = IconMap[item.tag];

        return (
          <div className="faq-general__item" key={index}>
            <Link
              className="faq-general__item-icon"
              link={`${url}/${item.tag}`}>
              <Icon />
            </Link>
            <QuestionList
              groupTag={item.tag}
              questions={item.questions}
              title={item.name}
            />
          </div>
        );
      })}
    </div>
  );
};
