import React, { useMemo } from 'react';
import { Icon } from '../../Icon';
import { Link } from '../../Link';
import { PAGE_PORTFOLIOS } from '../../../Pages/Routes';

import { enteredAnimate } from '../../../HOC/enteredAnimate';
import {
  floorPercent,
  getProgressSymbol,
  getCurrencySymbol,
  formatMoney
} from '../../../utils';
import { Wave } from '../Wave';

type Props = {
  name: string;
  potencial: number;
  minSum: number;
  friendlyUrl: number;
  currency: string;
  num: number;
};

const PortfolioCard: React.FC<Props> = ({
  name,
  potencial,
  minSum,
  friendlyUrl,
  currency,
  num
}) => {
  const icon = useMemo(
    () => ({
      class: 'stable-card__icon',
      name: 'chevron-right',
      width: 7,
      height: 11
    }),
    []
  );

  const cardClass = useMemo(() => {
    let styleClass = '';
    const delimiter = 9;

    switch (num % delimiter) {
      case 1:
      // tslint:disable-next-line:no-magic-numbers
      case 5:
      // tslint:disable-next-line:no-magic-numbers
      case 6:
        styleClass = '_violet';
        break;
      // tslint:disable-next-line:no-magic-numbers
      case 2:
      // tslint:disable-next-line:no-magic-numbers
      case 3:
      // tslint:disable-next-line:no-magic-numbers
      case 7:
        styleClass = '_dark';
        break;
      default:
    }

    return styleClass;
  }, [num]);

  return (
    <Link
      link={`${PAGE_PORTFOLIOS}/${friendlyUrl}`}
      className={`portfolio-card portfolio-card${cardClass}`}>
      <div className="portfolio-card__content">
        <h3 className="portfolio-card__title"> {name}</h3>
        <div className="portfolio-card__currency">
          {getCurrencySymbol(currency)}
        </div>
      </div>
      <footer className="portfolio-card__footer">
        <div className="portfolio-card__details">
          <div className="portfolio-card__details-block">
            <div className="portfolio-card__details-block-value">
              {getProgressSymbol(potencial)}
              {floorPercent(potencial)}%
            </div>
            <div className="portfolio-card__details-block-description">
              Потенциал роста
            </div>
          </div>
          <div className="portfolio-card__details-block">
            <div className="portfolio-card__details-block-value">
              {formatMoney(minSum, currency, 0)}
            </div>
            <div className="portfolio-card__details-block-description">
              Минимальная сумма
            </div>
          </div>
        </div>
        <span className="portfolio-card__link">
          <Icon {...icon} />
        </span>
      </footer>
      <Wave
        rootClass="portfolio-card__waves"
        childrenClass="portfolio-card__wave"
      />
    </Link>
  );
};

const wrappedComponent = enteredAnimate(PortfolioCard);

export { wrappedComponent as PortfolioCard };
