import { createStore, createApi, createEffect, forward } from 'effector';

import { getStrategy } from '../api';
import { Strategy } from '../types/strategy';
import { Nullable } from '../types/utils';

type TStrategy$State = Nullable<Strategy>;

export const Strategy$ = createStore<TStrategy$State>(null);

const { set } = createApi(Strategy$, {
  set: (state, { result }) => result.response
});

export const strategyApi = {
  get: createEffect().use(getStrategy)
};

forward({
  from: strategyApi.get.done,
  to: set
});
