import React, { PureComponent } from 'react'
import classNames from 'classnames'

class Contact extends PureComponent {
  render () {
    const { className } = this.props
    return (
      <div className={classNames('contact', className)} >
        <div className='contact__title'>Бесплатный звонок по россии</div>
        <a href='tel:88005005545' className='contact__phone'>8 800 500 55 45</a>
      </div>
    )
  }
}

export default Contact
