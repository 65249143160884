import { Author } from '../../Author';
import React from 'react';
import cn from 'classnames';

import { StarRating, Size } from '../../StarRating';

import {Icon} from '../../Icon';
import { Link } from '../../Link';
import { PAGE_STRATEGIES } from '../../../Pages/Routes';
import { enteredAnimate } from '../../../HOC/enteredAnimate';

import { floorPercent, getProgressSymbol } from '../../../utils';
import { Wave } from '../Wave';
import { SimpleLineChart } from '../../Chart/LineChart/SimpleLineChart';
import { AuthorColorType } from '../../Author';
import { Label } from '../../Label';

const Card = ({
  className,
  name,
  caption,
  author,
  actualProfit,
  friendlyUrl,
  num,
  history,
  currency,
  minInvestProfile,
  displayRating,
  profitDescription
}) => {
  let icon = {
    class: 'stable-card__icon',
    name: 'chevron-right',
    width: 7,
    height: 11
  };

  let styleClass = '';

  switch (num % 9) {
    case 1:
    case 5:
    case 6:
      styleClass = '_violet';
      break;
    case 2:
    case 3:
    case 7:
      styleClass = '_dark';
      break;
    default:
  }

  return (
    <Link
      link={`${PAGE_STRATEGIES}/${friendlyUrl}`}
      className={cn('card', styleClass, className)}
    >
      <>
        <div className="card__content">
          <div className="card__title-info">
            {caption && <span className="card__caption _dark">{caption}</span>}
            {minInvestProfile && (
              <div className="card__profile">{minInvestProfile.name}</div>
            )}
            <div className="card__title-info__description">
              <div className="card__title">{name}</div>
              <header className="card__header">
                {displayRating && (
                  <StarRating
                    checked={displayRating}
                    size={Size.large}
                  />
                )}
              </header>
            </div>
          </div>
          <div className="card__info">
            <div className="card__info-wrap">
              <div className="card__info-inner _v2">
                <div className={cn('card__value', { fall: actualProfit < 0 })}>
                  {getProgressSymbol(actualProfit)}
                  {floorPercent(actualProfit)}%
                </div>
                {currency && (
                  <div className="card__caption _light">{profitDescription}</div>
                )}
              </div>
              <div className="card__chart">
                <SimpleLineChart
                  className="card__chart-content"
                  data={[history]}
                />
              </div>
            </div>
          </div>
        </div>
      </>

      <footer className="card__footer">
        <Author
          {...author}
          type={styleClass && AuthorColorType.light}
          showLink={false}
        />

        <span className="card__link">
          <Icon {...icon} />
        </span>
      </footer>
      <Wave />
    </Link>
  );
};

export default enteredAnimate(Card);
