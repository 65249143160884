import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Collapse } from 'react-collapse';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';

import { Nullable } from '../../types/utils';
import { getStrategySecurities } from '../../api';
import { Strategy$, strategyApi } from '../../effector/strategy';

import { Page404 } from '../Page404/Page404';
import { ReactComponent as PlusIcon } from '../../components/Modal/_assets/i-plus.svg';
import { Test } from '../../types/strategy';

const StrategySecurity: React.FC<AllowedClass> = ({
  className,
  securities
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const onTopClick = useCallback(() => setIsOpened(!isOpened), [isOpened]);

  return (
    <div className="securities__group-container">
      <div className="securities__group" onClick={onTopClick}>
        <div className="securities__group-name">{className}</div>
        <div
          className={cn('securities__group-icon', {
            'securities__group-icon_active': isOpened
          })}>
          <PlusIcon />
        </div>
      </div>
      <Collapse isOpened={isOpened}>
        <div className="securities__group-list">
          <div className="securities__group-list-item">Тикер</div>
          <div className="securities__group-list-item">Исин</div>
          <div className="securities__group-list-item">Наименование</div>
          <div className="securities__group-list-item">Необходимый тест НКИ</div>
        </div>
          {securities.map(({ ticker, isin, name, requiredTest }, index) => (
            <div key={index} className="securities__group-list">
              <div>{ticker}</div>
              <div>{isin}</div>
              <div>{name}</div>
              <div>{requiredTest?.name ?? 'Тестирование не требуется'}</div>
            </div>
          ))}
      </Collapse>
    </div>
  );
};

type Security = {
  ticker: string;
  name: string;
  isin: string;
  requiredTest: Nullable<Test>
};

type AllowedClass = {
  className: string;
  securities: Security[];
};

export const StrategySecurities: React.FC<{}> = ({}) => {
  const [loadedClasses, setLoadedClasses] = useState(false);
  const [loadedStrategy, setLoadedStrategy] = useState(false);
  const [allowedClasses, setAllowedClassesList] = useState<
    Nullable<AllowedClass[]>
  >(null);
  const { id } = useParams<{ id: string }>();
  const strategy = useStore(Strategy$);

  useEffect(() => {
    strategyApi.get(id).finally(() => setLoadedStrategy(true));
    getStrategySecurities(id)
      .then(result => {
        const { success, response } = result;

        if (success) {
          setAllowedClassesList(response);
        }
      })
      .catch(() => {})
      .finally(() => setLoadedClasses(true));
  }, [id]);

  if (loadedClasses && loadedStrategy && !allowedClasses) {
    return (
      <div className="securities">
        <Page404 />
      </div>
    );
  }

  return (
    <div className="securities">
      <div className="securities__header">
        Перечень ценных бумаг, сделок с ценными бумагами и (или) договоров,
        являющихся производными финансовыми инструментами, в отношении которых
        ООО "Компания БКС" предоставляются индивидуальные инвестиционные
        рекомендации в рамках услуги "Автоследование" в соответствии со
        Стратегией "{strategy?.name}"
      </div>
      {allowedClasses?.map(({ className, securities }) => (
        <StrategySecurity
          className={className}
          securities={securities}
          key={className}
        />
      ))}
    </div>
  );
};
