import React from 'react';

import Button from '../../../Button/Button';

// import { Label } from '../../../Label';

import { ReactComponent as ManIcon } from './man3.svg';
import { ReactComponent as ManMobileIcon } from './man-mobile.svg';
import { ReactComponent as CircleIcon } from './circle.svg';
import { ReactComponent as $Icon } from './dollar.svg';
import { Banner } from '../../../../types/banner';

type Props = Partial<Banner>;

export const Type3: React.FC<Props> = ({ title, subtitle, buttonText }) => (
  <div className="slider-item__container">
    <div className="slider-item__content">
      <div className="slider-item__labels">
        {/*{labels.map((label, key) => (*/}
        {/*  <Label key={key} {...label} />*/}
        {/*))}*/}
      </div>
      <div className="slider-item__title">{title}</div>
      <p className="slider-item__subtitle">{subtitle}</p>
      <Button
        className="button _large _primary slider-item__button"
        type="button"
        text={buttonText || 'Подробнее'}
      />
      <$Icon className="slider-item__dollar" />
      <$Icon className="slider-item__dollar1" />
    </div>
    <div className="slider-item__image slider-item__image-first">
      <ManIcon className="slider-item__icon slider-item__icon-type3" />

      <Button
        className="button _large _white slider-item__button-mobile"
        type="button"
        text={buttonText || 'Подробнее'}
      />

      <ManMobileIcon className="slider-item__icon slider-item__icon-type3-mobile" />
      <CircleIcon className="slider-item__icon-circle" />
    </div>
  </div>
);
