export const required = 'Заполните это поле';
export const email = 'Некорректный email';
export const number = 'Доступны только числа';

export const FIELD_LENGTH = {
  login: 50,
  password: 200,
  name: 128,
  phone: 32,
  description: 1024
};
