import React, { useState } from 'react';

import { CheckboxList } from './CheckboxList';
import { RadioButtonList } from './RadioButtonList';
import { DatePickerComponent } from './Datepicker';

const COMPONENT_TYPE = {
  RADIO: 1, // 'radiobutton',
  CHECKBOX: 2, // 'checkbox',
  DATEPICKER: 3 // 'datepicker'
};

const AnswerComponent = ({
  question,
  loading,
  error,
  onAnswer,
  goBack,
  canGoBack
}) => {
  switch (question.selectionType) {
    case COMPONENT_TYPE.CHECKBOX:
      return (
        <CheckboxList
          error={error}
          loading={loading}
          questionCode={question.questionCode}
          title={question.value}
          items={question.answers}
          onComplete={onAnswer}
          goBack={goBack}
          canGoBack={canGoBack}
        />
      );
    case COMPONENT_TYPE.RADIO:
      return (
        <RadioButtonList
          error={error}
          loading={loading}
          questionCode={question.questionCode}
          title={question.value}
          items={question.answers}
          onComplete={onAnswer}
          goBack={goBack}
          canGoBack={canGoBack}
        />
      );
    case COMPONENT_TYPE.DATEPICKER:
      return (
        <DatePickerComponent
          error={error}
          loading={loading}
          questionCode={question.questionCode}
          title={question.value}
          items={question.answers}
          onComplete={onAnswer}
          goBack={goBack}
          canGoBack={canGoBack}
        />
      );
  }
};

const initialStep = 1;
export const Questionnaire = ({
  questions,
  error,
  templateId,
  loading,
  onComplete
}) => {
  const [step, setStep] = useState(initialStep);
  const [answers, setAnswer] = useState([]);

  const getQuestion = () => {
    if (!questions || !questions[step - 1]) {
      return null;
    }

    return questions[step - 1];
  };

  const onAnswer = answer => {
    if (answer) {
      const filteredAnswers = answers.filter(({questionCode}) => questionCode !== answer.questionCode);
      const updatedAnswers = [...filteredAnswers, answer];
      setAnswer(updatedAnswers);

      if (questions.length > step) {
        setStep(step + 1);
      } else {
        submit(updatedAnswers);
      }
    }
  };

  const goBack = () => {
    if (step > initialStep) {
      setStep(step - 1);
      const updatedAnswers = answers.slice(0, answers.length - 1);

      setAnswer(updatedAnswers);
    }
  };

  const submit = updatedAnswers => {
    const data = {
      templateId,
      questions: updatedAnswers
    };

    onComplete(data);
  };

  const question = getQuestion();

  if (!question) {
    return null;
  }

  const title = `${step}/${questions.length}`;
  const canGoBack = step > 1;

  return (
    <>
      <div className="invest-profile__step">{title}</div>
      <div className="invest-profile__content">
        <div className="invest-profile__content_title">{question.value}</div>
        {question.attachedImage && (
          <div>
            <img
              className="invest-profile__content_image"
              src={question.attachedImage}
              alt={question.value}
            />
          </div>
        )}
        <div className="invest-profile__content_answers">
          <AnswerComponent
            question={question}
            loading={loading}
            error={error}
            onAnswer={onAnswer}
            goBack={goBack}
            canGoBack={canGoBack}
          />
        </div>
      </div>
    </>
  );
};
