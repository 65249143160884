import React from 'react';
import { Iterable } from 'immutable';
import { getComponentDisplayName } from '../utils/getComponentDisplayName';

export const toJS = WrappedComponent => {
  class toJS extends React.Component {
    render() {
      const KEY = 0;
      const VALUE = 1;

      const propsJS = Object.entries(this.props).reduce(
        (newProps, wrappedComponentProp) => {
          newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(
            wrappedComponentProp[VALUE]
          )
            ? wrappedComponentProp[VALUE].toJS()
            : wrappedComponentProp[VALUE];
          return newProps;
        },
        {}
      );

      return <WrappedComponent {...propsJS} />;
    }
  }

  toJS.displayName = `toJS(${getComponentDisplayName(WrappedComponent)})`;

  return toJS;
};
