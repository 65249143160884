import React, { PureComponent } from 'react';
import { Collapse } from 'react-collapse';
import Toggle from 'react-toggle';
import cn from 'classnames';
import ReactPaginate from 'react-paginate';
import { Icon } from '../Icon';
import TitleTable from './TitleTable/TitleTable';
import HistoryTable from './HistoryTable/HistoryTable';
import DescriptionTab from './DescriptionTab/DescriptionTab';
import { enteredAnimate } from '../../HOC/enteredAnimate';
import { setIirForAgreement } from '../../api';
import { post } from '../../api/apiHelper';
import { connect } from 'react-redux';
import { toJS } from '../../HOC/toJS';
import { setSignal } from '../../actions/SignalActions';
import AccountMain from '../AccountMain/AccountMain';
import { formatMoney, getConnectionType } from '../../utils';
import { CONNECTION_TYPE } from '../../const';
import { setRecommendation } from '../../actions/RecommendationActions';
import HistoryTableRecommendations from './HistoryTable/HistoryTableRecommendations';
import { SimpleLineChart } from '../Chart/LineChart/SimpleLineChart';
import { AccountPortfolio } from './Portfolio';

const tableHeaders = [
  'Сделка',
  'Дата',
  'Инструмент',
  'Объем',
  'Цена входа',
  'Стоимость вложений',
  'P/L',
  'Документ'
];

const tableHeadersRecommendations = ['Дата', '№ ИИР', 'ИИР'];

const mapStateToProps = (state, ownProps) => ({
  signals: state.getIn(['signal', `${ownProps.clientCode}`]),
  recommendations: state.getIn(['recommendation', `${ownProps.clientCode}`])
});

const mapDispatchToProps = dispatch => ({
  setSignals: data => dispatch(setSignal(data)),
  setRecommendations: data => dispatch(setRecommendation(data))
});

class Account extends PureComponent {
  state = {
    isOpen: false,
    curOpenTab: 'desc',
    page: 1,
    pageSize: 8,
    totalPages: 0,
    errorMessage: '',
    iir: this.props.sendIirSms
  };

  componentDidMount() {
    const { page, pageSize } = this.state;
    const { clientCode, status } = this.props;

    if (status === CONNECTION_TYPE.AUTOFOLLOW) {
      this.loadSignals(page, clientCode, pageSize);
    } else if (status === CONNECTION_TYPE.RECOMMENDATIONS) {
      this.loadRecomendations(page, clientCode, pageSize);
    }
  }

  lockAccount = () => {
    this.props.refreshAccounts();
    this.setState({ isOpen: false });
  };

  handlePageClick = ({ selected }) => {
    const { status, clientCode } = this.props;

    const nextPage = selected + 1;

    if (status === CONNECTION_TYPE.AUTOFOLLOW) {
      this.loadSignals(nextPage, clientCode, this.state.pageSize);
    } else if (status === CONNECTION_TYPE.RECOMMENDATIONS) {
      this.loadRecomendations(nextPage, clientCode, this.state.pageSize);
    }

    this.setState({ page: nextPage });
  };

  loadSignals = (page, clientCode, pageSize) => {
    const params = {
      clientCode,
      page,
      pageSize
    };

    post('account/clientaccountsignals', params).then(result => {
      const { errorMessage, success, response } = result;

      if (success) {
        this.props.setSignals({ clientId: clientCode, data: response.signals });
        this.setState({ totalPages: response.pageCount });
      } else {
        this.setState({ errorMessage });
      }
    });
  };

  loadRecomendations = (page, clientCode, pageSize) => {
    const params = {
      clientCode,
      page,
      pageSize
    };

    post('account/clientaccountrecomendations', params).then(result => {
      const { errorMessage, success, response } = result;

      if (success) {
        this.props.setRecommendations({
          clientId: clientCode,
          data: response.recomendations
        });
        this.setState({ totalPages: response.pageCount });
      } else {
        this.setState({ errorMessage });
      }
    });
  };

  setIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  setOpenTab = curOpenTab => {
    this.setState({ curOpenTab });
  };

  getTitleTable = () => {
    const {
      agreementGuid,
      currencyPortfolios,
      strategyName,
      // history,
      status
    } = this.props;

    const onHandleChange = () => {
      setIirForAgreement({ agreementGuid, sendIirSms: !this.state.iir }).then(
        () => {
          this.setState({ iir: !this.state.iir });
        }
      );
    };

    let titleTable = [
      {
        order: 1,
        title: 'Подключенная стратегия',
        text: [`${strategyName} ${getConnectionType(status)}`],
        row: {
          text: (
            <label className="account__iir">
              <Toggle
                checked={this.state.iir}
                icons={false}
                onChange={onHandleChange}
              />
              <span className="account__iir-label">
                получать SMS-оповещения о поступлении инвестиционных
                рекомендаций
              </span>
            </label>
          )
        }
      },
      {
        order: 2,
        title: 'Всего средств',
        text: currencyPortfolios
          ? currencyPortfolios.map(item =>
              item.liquidPrice
                ? formatMoney(item.liquidPrice, item.currency)
                : 0
            )
          : [],
        prefix: 'или'
      },
      {
        order: 3,
        title: 'Свободные средства',
        text: currencyPortfolios
          ? currencyPortfolios.map(item =>
              formatMoney(item.freeFunds, item.currency)
            )
          : [],
        prefix: 'и'
      },
      // {
      //   order: 5,
      //   title: '',
      //   row: {
      //     img: (
      //       <SimpleLineChart
      //         className="card__chart-content account__chart-up no-hover"
      //         data={[history]}
      //       />
      //     )
      //   }
      // }
    ];

    titleTable = titleTable.sort((a, b) => a.order - b.order);

    return titleTable;
  };

  render() {
    const {
      className,
      agreementId,
      signals,
      strategyId,
      currencyPortfolios,
      recommendations,
      state,
      status,
      refreshAccounts
    } = this.props;

    if (status === 3) {
      return (
        <AccountPortfolio
          account={{ ...this.props }}
          state={state}
          refreshAccounts={refreshAccounts}
        />
      );
    }

    if (!strategyId) {
      return (
        <AccountMain
          className="_main"
          title={`${agreementId}`}
          currencyPortfolios={currencyPortfolios}
          state={state}
        />
      );
    }

    const { isOpen, curOpenTab, page, totalPages } = this.state;

    const titlesTable = this.getTitleTable();

    return (
      <article
        className={cn(
          'account',
          { _expanded: isOpen, _blocked: state },
          className
        )}>
        {state && (
          <div className="account__block">
            <div className="account__block-item">{state}</div>
          </div>
        )}
        <header className="account__header">
          <div className="account__number">{`${agreementId}`}</div>
          <button
            className="account__toggle"
            type="button"
            onClick={this.setIsOpen}>
            <Icon
              {...{
                className: 'card__icon',
                name: 'chevron-down',
                width: 11,
                height: 7
              }}
            />
          </button>
        </header>
        <TitleTable data={titlesTable} />
        <Collapse isOpened={isOpen}>
          <div className="account__detail" data-expandable-content="">
            <div className="tabs _static _account">
              <div className="tabs__nav-wrapper">
                <div className="tabs__nav">
                  <span
                    className={cn('tabs__nav-item', {
                      _active: curOpenTab === 'desc'
                    })}
                    onClick={() => {
                      this.setOpenTab('desc');
                    }}>
                    Описание
                  </span>
                  <span
                    className={cn('tabs__nav-item', {
                      _active: curOpenTab === 'history'
                    })}
                    onClick={() => {
                      this.setOpenTab('history');
                    }}>
                    {status === CONNECTION_TYPE.RECOMMENDATIONS
                      ? 'История рекомендаций'
                      : 'История сделок'}
                  </span>
                </div>
              </div>
              <div className="tabs__content-wrapper">
                <div
                  className={cn('tabs__tab', {
                    _active: curOpenTab === 'history'
                  })}>
                  {status === CONNECTION_TYPE.RECOMMENDATIONS
                    ? recommendations && (
                        <HistoryTableRecommendations
                          items={recommendations}
                          tableHeaders={tableHeadersRecommendations}
                        />
                      )
                    : signals && (
                        <HistoryTable
                          items={signals}
                          tableHeaders={tableHeaders}
                        />
                      )}

                  {totalPages > 1 && (
                    <ReactPaginate
                      previousLabel={''}
                      nextLabel={''}
                      breakLabel={'...'}
                      pageCount={totalPages}
                      initialPage={page - 1}
                      onPageChange={this.handlePageClick}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={1}
                      containerClassName={'pagination'}
                      breakLinkClassName={'pagination__link'}
                      pageLinkClassName={'pagination__link'}
                      activeLinkClassName={'_active'}
                      previousLinkClassName={
                        'pagination__link pagination__prev'
                      }
                      nextLinkClassName={'pagination__link pagination__next'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  )}
                </div>
                <div
                  className={cn('tabs__tab account-description', {
                    _active: curOpenTab === 'desc'
                  })}>
                  {curOpenTab === 'desc' && isOpen && (
                    <DescriptionTab
                      {...this.props}
                      onDelete={this.lockAccount}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </article>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enteredAnimate(toJS(Account)));
