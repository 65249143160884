export enum BannerType {
  one = 'one',
  two = 'two',
  three = 'three',
  four = 'four'
};

export type Banner = {
  id: string;
  title: string;
  subtitle: string;
  buttonText: string;
  url: string;
  utm: string;
  type: BannerType;
  active: boolean;
}
