import React from 'react';

import { Type1 } from './Type1';
import { Type2 } from './Type2';
import { Type3 } from './Type3';
import { Type4 } from './Type4';

import { Link } from '../../Link';

import { GAService } from '../../../services/gaService';

import { Banner, BannerType } from '../../../types/banner';

type Props = Banner;

export const SliderItem: React.FC<Props> = ({ type, url, utm, ...rest }) => {
  const getComponentType = () => {
    switch (type) {
      case BannerType.one:
        return <Type1 {...rest} />;
        break;

      case BannerType.two:
        return <Type2 {...rest} />;
        break;

      case BannerType.three:
        return <Type3 {...rest} />;
        break;

      case BannerType.four:
        return <Type4 {...rest} />;
        break;

      default:
        return null;
    }
  };

  const onClick = () => {
    GAService.sendEvent(' Banner_' + utm);
  };

  return (
    <Link link={url} onClick={onClick} className="slider-item__link">
      {getComponentType()}
    </Link>
  );
};
