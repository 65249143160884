import { createStore, createApi, createEffect, forward } from 'effector';

import { getCategories } from '../api';
import { Nullable } from '../types/utils';
import { Category } from '../types/category';

type Categories$State = Nullable<Category[]>;

export const Categories$ = createStore<Categories$State>(null);

const { set } = createApi(Categories$, {
  set: (state, { result }) => {
    return result.response;
  }
});

export const categoriesApi = {
  // @ts-ignore
  get: createEffect().use(getCategories)
};

forward({
  from: categoriesApi.get.done,
  to: set
});
