import queryString from 'query-string';

import { CONNECTION_TYPE } from '../const';
/**
 * получаем ширину системного скролла
 * @return {number} Ширина скролла.
 */
export const scrollWidth = () => {
  // создадим элемент с прокруткой
  let div = document.createElement('div');

  div.style.overflowY = 'scroll';
  div.style.width = '50px';
  div.style.height = '50px';

  // при display:none размеры нельзя узнать
  // нужно, чтобы элемент был видим,
  // visibility:hidden - можно, т.к. сохраняет геометрию
  div.style.visibility = 'hidden';

  document.body.appendChild(div);
  let scrollWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  return scrollWidth;
};

export const isBrowser = typeof window !== 'undefined';

export const isServer = !isBrowser;

export const isDevelopment = process.env.NODE_ENV === 'development';

export const getPercent = (min, max, value) => {
  const percent = ((value - min) / (max - min)) * 180;
  let result = percent;

  if (result > 180) {
    result = 180;
  } else if (result < 0) {
    result = 0;
  }

  return roundNumber(result);
};

export const floorPercent = value => {
  const absValue = Math.abs(value);

  return absValue > 100 ? Math.floor(absValue) : absValue;
};

export const getCurrencySymbol = (currency) => {
  const currencies = {
    RUB: '₽',
    SUR: '₽',
    USD: '$',
    EUR: '€',
    '%': '%',
    undefined: '',
    null: ''
  };

  return currencies[currency];
};

export const formatMoney = (value, currency = 'RUB', toFixed = 2) => {
  const formatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: toFixed
  });

  const symbol = getCurrencySymbol(currency);

  return `${formatter.format(value)} ${ symbol || currency}`;
};

export const roundNumber = (value, to = 2) => parseFloat(value.toFixed(to));

export const getProgressSymbol = value => {
  let symbol = '';

  if (value > 0) {
    symbol = '+';
  } else if (value < 0) {
    symbol = '-';
  }

  return symbol;
};

export const getConnectionType = value => {
  let type = '';

  switch (value) {
    case CONNECTION_TYPE.AUTOFOLLOW:
      type = '(автоследование)';
      break;
    case CONNECTION_TYPE.RECOMMENDATIONS:
      type = '(автоконсультирование)';
      break;
  }

  return type;
};

export const getNestedProperty = (object, path) => {
  return path.split('.').reduce((m, i) => {
    return m && typeof m === 'object' ? m[i] : undefined;
  }, object);
};

export const withQuery = (...args) => endpoint => {
  const commonQueryObj = {};

  args.forEach(queryObj => {
    Object.keys(queryObj).forEach(queryObjKey => {
      if (queryObj[queryObjKey]) {
        commonQueryObj[queryObjKey] = queryObj[queryObjKey];
      }
    });
  });

  const result = queryString.stringifyUrl({
    url: endpoint,
    query: commonQueryObj
  });

  return result;
};
