import React from 'react';
import cn from 'classnames';

import { Link } from '../Link';
import {StarRating} from '../StarRating';
import { PAGE_EXPERTS } from '../../Pages/Routes';
import { Author as AuthorProps } from '../../types/author';
import {Icon} from '../Icon';

export enum AuthorColorType {
  light = 'light',
  review = 'review'
}

type Props = AuthorProps & {
  className?: string;
  showPhoto?: boolean;
  showRating?: boolean;
  showLink?: boolean;
  type?: AuthorColorType;
};

export const Author: React.FC<Props> = ({
  className,
  imageUrl,
  firstName,
  lastName,
  company,
  friendlyUrl,
  strategyCount,
  articleCount,
  type,
  rating,
  showLink = true,
  showPhoto = true,
  showRating = false
}) => {
  let styleClass = '';

  const icon = {
    className: 'stable-card__icon',
    name: 'chevron-right',
    width: 7,
    height: 11
  };

  switch (type) {
    case AuthorColorType.light:
      styleClass = '_light';
      break;
    case AuthorColorType.review:
      styleClass = '_review';
      break;
    default:
  }

  const picture = () => {
    if (showPhoto && imageUrl) {
      return (
        <picture className="author__avatar">
          <img
            className="author__img"
            src={imageUrl}
            alt={`${firstName} ${lastName}`}
          />
        </picture>
      );
    }
  };

  const link = `${PAGE_EXPERTS}/${friendlyUrl}`;

  const Tag = friendlyUrl ? Link : 'div';

  return (
    <Tag className="author__link" link={link}>
      <div className={cn('author', styleClass, className)}>
        {picture()}
        <div className="author__inner">
          {showRating && rating ? (
            <StarRating checked={rating} />
          ) : (
            <p className="author__company">{company}</p>
          )}

          <h1 className="author__name">
            {firstName} {lastName}{' '}
            {showRating && rating ? (
              <span className="author__company">{company}</span>
            ) : null}
          </h1>
          <p className="author__statistic">
            {typeof articleCount === 'number' && (
              <span className="author__statistic-item">
                статей: {articleCount}
              </span>
            )}

            {typeof strategyCount === 'number' && (
              <span className="author__statistic-item">
                стратегий: {strategyCount}
              </span>
            )}
          </p>
        </div>
        {showLink && (
          <span className="author__circle card__link">
            <Icon {...icon} />
          </span>
        )}
      </div>
    </Tag>
  );
};
