import { createStore, createApi, createEffect, forward } from 'effector';

import { getInvestProfiles } from '../api';
import { Option } from '../types/option';

type TInvestProfiles$State = Option[];

export const InvestProfiles$ = createStore<TInvestProfiles$State>([]);

const { set } = createApi(InvestProfiles$, {
  set: (state, { result }) => result.response
});

export const investProfilesApi = {
  get: createEffect().use(getInvestProfiles)
};

forward({
  from: investProfilesApi.get.done,
  to: set
});
