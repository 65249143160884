import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import {Icon} from '../../Icon';
import { toJS } from '../../../HOC/toJS';

type Props = {
  popupData: {
    description?: string;
  };
  className?: string;
};

const imageSize = {
  width: 136,
  height: 109
};

const Subscribe: React.FC<Props> = ({
  popupData: { description },
  className
}) => (
  <div className={cn('modal-attention', className)}>
    <div className="modal-attention__icon">
      <Icon
        className="modal-attention__svg"
        name="approval"
        width={imageSize.width}
        height={imageSize.height}
      />
    </div>
    {description && (
      <div
        className="modal-attention__text"
        dangerouslySetInnerHTML={{ __html: description }}/>
    )}
  </div>
);

// @ts-ignore
const mapStateToProps = state => ({
  popupData: state.getIn(['popup', 'data'])
});

const wrappedComponent = connect(
  mapStateToProps,
  null
)(toJS(Subscribe));

export { wrappedComponent as Subscribe };
