import React from 'react';

import { enteredAnimate } from '../../HOC/enteredAnimate';
import { AnalyticsBrief } from '../../types/analyticsBrief';
import { ArticleCard } from './ArticleCard';

type Props = {
  items: AnalyticsBrief[];
};

const ArticleCardList: React.FC<Props> = ({ items }) => (
  <div>
    <div className="article-cards">
      <div className="article-cards__list">
        {items.map((item, key) => (
          <div className="article-cards__item" key={key}>
            <ArticleCard {...item} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

const wrapped = enteredAnimate(ArticleCardList);

export { wrapped as ArticleCardList };
