import { createStore, createApi, createEffect, forward } from 'effector';

import { getAuthor } from '../api';
import { Author } from '../types/author';
import { Nullable } from '../types/utils';
// tslint:disable-next-line:no-any
type TAuthor$State = Nullable<Author>;

export const Author$ = createStore<TAuthor$State>(null);

const { set } = createApi(Author$, {
  set: (state, { result }) => result.response
});

export const authorApi = {
  get: createEffect().use(getAuthor)
};

forward({
  from: authorApi.get.done,
  to: set
});
