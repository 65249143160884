import * as PopupActions from '../../../actions/PopupActions';

import React, { useEffect, useMemo, useState } from 'react';

import { Burger } from './Burger';
import MainNav from '../../MainNav/MainNav';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { toJS } from '../../../HOC/toJS';
import { useHistory, useLocation } from 'react-router-dom';
import {
  PAGE_ABOUT,
  PAGE_ARTICLES,
  PAGE_EXPERTS,
  PAGE_HOME,
  PAGE_ONBOARDING,
  PAGE_PERSONAL, PAGE_PORTFOLIOS, PAGE_PROMO,
  PAGE_STRATEGIES,
  PAGE_SUPPORT_FAQ
} from '../../../Pages/Routes';
import { POP_UP } from '../../../const';
import { GAService } from '../../../services/gaService';
import {QueryService, UTM_PARAMS} from '../../../services/queryService';
import { withQuery } from '../../../utils';
import Button from '../../Button/Button';

import { Nullable } from '../../../types/utils';
import {useStore} from 'effector-react';
import {Account$, accountApi} from '../../../effector/clientAccounts';

type SubMenuProps = {
  className: string;
  logOut: () => void;
};

const SubMenu: React.FC<SubMenuProps> = ({ logOut, className }) => (
  <div className={classNames('navbar__submenu', className)}>
    <ul className="navbar__submenu-list">
      <li className="navbar__submenu-item" onClick={logOut}>
        <span>Выйти</span>
      </li>
    </ul>
  </div>
);

type Props = {
  className: string;
  navIsOpen: boolean;
  popupActions: object;
};
const Navbar: React.FC<Props> = ({
  navIsOpen,
  className,
  popupActions,
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [page, setPage] = useState<Nullable<Element>>(null);

  const {account} = useStore(Account$);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    closeNavPopup();
  }, [location.pathname]);

  useEffect(() => {
    setPage(document.querySelector('.page'));
  }, []);

  const handleHover = () => setIsOpened(true);
  const handleLeave = () => setIsOpened(false);

  const openNavPopup = () => {
    // @ts-ignore
    popupActions.openPopup('nav');
    page?.classList.add('_menu-opened');
  };

  const closeNavPopup = () => {
    // @ts-ignore
    popupActions.hidePopup('nav');
    page?.classList.remove('_menu-opened');
  };

  const onLogOut = () => {
    accountApi.logOut('');
    history.push(PAGE_HOME);
  };

  const toOnboadring = () => {
    GAService.sendEvent('ButtonOpenDepo');

    const params = {
      [UTM_PARAMS.REF_ID]: QueryService.refId,
      [UTM_PARAMS.UTM_SOURCE]: QueryService.utmSource,
      [UTM_PARAMS.UTM_CAMPAIGN]: QueryService.utmCampaign,
      [UTM_PARAMS.UTM_CONTENT]: QueryService.utmContent,
      [UTM_PARAMS.UTM_MEDIUM]: QueryService.utmMedium,
      [UTM_PARAMS.UTM_TERM]: QueryService.utmTerm
    }

    const url = withQuery(params)(PAGE_ONBOARDING);

    const win = window.open(url, '_blank');
    win?.focus();
  };

  const openAuth = () => {
    if (account) {
      history.push(PAGE_PERSONAL);
    } else {
      // @ts-ignore
      popupActions.openPopup(POP_UP.AUTHORIZATION, {
        onSuccess: () => {
          history.push(PAGE_PERSONAL);
          closeNavPopup();
        }
      });
    }

    page?.classList.remove('_menu-opened');
  };

  const menuItems = useMemo(() => {
    const items = [
      {
        link: PAGE_STRATEGIES,
        text: 'Стратегии'
      },
      {
        link: PAGE_ARTICLES,
        text: 'Аналитика'
      },
      {
        link: PAGE_EXPERTS,
        text: 'Авторам'
      },
      {
        link: PAGE_ABOUT,
        text: 'О проекте'
      },
      {
        link: PAGE_SUPPORT_FAQ,
        text: 'FAQ'
      }
    ];

    return location.pathname !== PAGE_PROMO ? items : [];
  },[location.pathname]);

  return (
    <div className={classNames('navbar', className, { _opened: navIsOpen })}>
      <div className="navbar__inner">
        <MainNav
          className="_light navbar__main-nav"
          items={menuItems}
        />

        <div className="navbar__open-bid-button">
          <Button
            onClick={toOnboadring}
            className="button _success _small navbar__button"
            text="Открыть счет"
          />
        </div>

        <div
          className="navbar__popover"
          onMouseLeave={handleLeave}
          onMouseEnter={handleHover}>
          <Button
            onClick={openAuth}
            className="button _success _small navbar__button"
            text={account ? 'Личный кабинет' : 'Войти'}
          />

          {account && (
            <SubMenu
              logOut={onLogOut}
              className={classNames({
                'navbar__submenu-visible': isOpened
              })}
            />
          )}
        </div>
      </div>
      <Burger
        className={'navbar__burger'}
        isActive={navIsOpen}
        onOpen={openNavPopup}
        onClose={closeNavPopup}
      />
    </div>
  );
};

// @ts-ignore
const mapStateToProps = state => ({
  navIsOpen: state.getIn(['popup', 'nav'])
});

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(toJS(Navbar));

export { wrappedComponent as Navbar };
