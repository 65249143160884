import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import { sendQuestions, getProfileDoc, getQuestions } from '../../api';
import { Questionnaire } from './Questionnaire';
import { toJS } from '../../HOC/toJS';
import { PdfViewer } from './PdfViewer';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { useHistory } from 'react-router-dom';
import { PAGE_HOME, PAGE_PERSONAL } from '../Routes';
import { enteredAnimate } from '../../HOC/enteredAnimate';
import { ViewProfile } from './ViewProfile';
import { Final } from './Final';
import { setExtraOpen } from '../../actions/RouterInfoActions';
import { Account$, accountApi } from '../../effector/clientAccounts';
import { useStore } from 'effector-react';
import { SEO_CONTENT } from '../../const';

const STEPS = {
  QUESTIONNAIRE: 1,
  VIEW_PROFILE: 2,
  ACCEPT_DOCUMENT: 3,
  FINAL: 4
};

const Step = ({ step, stepProps }) => {
  switch (step) {
    case STEPS.QUESTIONNAIRE:
      return <Questionnaire {...stepProps} />;

    case STEPS.VIEW_PROFILE:
      return <ViewProfile {...stepProps} />;

    case STEPS.ACCEPT_DOCUMENT:
      return <PdfViewer {...stepProps} />;

    case STEPS.FINAL:
      return <Final {...stepProps} />;

    default:
      return <Questionnaire {...stepProps} />;
  }
};

const InvestProfilePage = ({ previousPath, minProfileId, setExtraOpen }) => {
  const [questions, setQuestions] = useState([]);
  const [templateId, setTemplateId] = useState(null);

  const [step, setStep] = useState(STEPS.QUESTIONNAIRE);
  const [profile, setProfile] = useState({});
  const [doc, setDoc] = useState({});
  const [questionaryId, setQuestionaryId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const history = useHistory();
  const { account, isFetchingProfile } = useStore(Account$);

  useEffect(() => {
    getQuestions().then(result => {
      const { response } = result;

      if (response) {
        setQuestions(response.questions);
        setTemplateId(response.templateId);
      }
    });
  }, []);

  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!account && !isFetchingProfile) {
        history.push(PAGE_HOME);
      }
    }
  }, [account, history, isFetchingProfile]);

  const previousStep = () => {
    if (step !== STEPS.QUESTIONNAIRE) {
      setStep(step - 1);
    }
  };

  const nextStep = () => {
    if (step !== STEPS.FINAL) {
      setStep(step + 1);
    }
  };

  const sendAnswers = data => {
    setLoading(true);
    setError('');

    sendQuestions(data)
      .then(result => {
        const { errorMessage, success, response } = result;

        if (success && response) {
          setProfile(response.profile);
          setQuestionaryId(response.questionaryId);
          nextStep();
        } else {
          setError(errorMessage);
        }
      }) // eslint-disable-next-line
      .catch(error => setError('Произошла непредвиденная ошибка'))
      .finally(() => setLoading(false));
  };

  const acceptProfile = () => {
    const data = {
      questionaryId
    };

    setLoading(true);
    setError('');

    getProfileDoc(data)
      .then(result => {
        const { errorMessage, success, response } = result;

        if (success) {
          setDoc(response);
          nextStep();
        } else {
          setError(errorMessage);
        }
      }) // eslint-disable-next-line
      .catch(error => setError('Произошла непредвиденная ошибка'))
      .finally(() => setLoading(false));
  };

  const toFinalStep = () => {
    accountApi.fetching(true);
    accountApi.getProfile('').finally(() => {
      accountApi.fetching(false)
    });
    nextStep();
  };

  const redirect = () => {
    let path = PAGE_PERSONAL;

    if (minProfileId && minProfileId <= profile.ordernum) {
      path = previousPath;
      setExtraOpen();
    }

    history.push(path);
  };

  const getStepProps = () => {
    switch (step) {
      case STEPS.QUESTIONNAIRE:
        return {
          questions,
          error,
          templateId,
          loading,
          onComplete: sendAnswers
        };

      case STEPS.VIEW_PROFILE:
        return {
          loading,
          error,
          title: profile.name,
          value: profile.ordernum,
          description: profile.description,
          onCancel: previousStep,
          onConfirm: acceptProfile
        };

      case STEPS.ACCEPT_DOCUMENT:
        return {
          file: `data:application/pdf;base64,${doc.document}`,
          questionaryId,
          documentId: doc.documentId,
          onComplete: toFinalStep
        };

      case STEPS.FINAL:
        return {
          onClick: redirect
        };
    }
  };

  const stepProps = getStepProps();

  const seo = SEO_CONTENT['personal'];

  return (
    <>
      <Helmet {...seo} />
      <div className="invest-profile">
        <Breadcrumbs
          items={[
            {
              link: '/personal',
              text: 'Личный кабинет'
            },
            {
              active: true,
              text: 'Анкета для определения инвестиционного профиля'
            }
          ]}
        />
        <h1 className="invest-profile__title">
          Анкета для определения инвестиционного профиля
        </h1>
        <Step step={step} stepProps={stepProps} />
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  previousPath: state.getIn(['routerInfo', 'previousPath']),
  minProfileId: state.getIn(['routerInfo', 'profileId'])
});

const mapDispatchToProps = dispatch => ({
  setExtraOpen: () => dispatch(setExtraOpen())
});

const wrappedComponent = enteredAnimate(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(toJS(InvestProfilePage))
);

export { wrappedComponent as InvestProfilePage };
