import { useStore } from 'effector-react';
import React from 'react';
import Helmet from 'react-helmet';

import { ContentBlock } from '../../../components/ContentBlock';
import { Author$ } from '../../../effector/author';

type Props = {
  description: string;
};

export const AboutSection: React.FC<Props> = ({ description }) => {
  const author = useStore(Author$);

  const meta = {
    title: `${author?.firstName} ${author?.lastName} - об авторе Fintarget`,
    meta: [
      {
        name: 'description',
        content:
          `${author?.firstName} ${author?.lastName} - инвестиционный эксперт Fintarget. Об авторе инвестиционной стратегии автоследования Fintarget.`
      }
    ]
  };

  return (
    <>
      {meta && <Helmet {...meta} />}
      <div className="tabs__tab _active">
        <ContentBlock className="_show-all _text-small _two-col">
          <p
            className="content-block__text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </ContentBlock>
      </div>
    </>
  );
};
