import React, { useState, useContext, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '../../../components/Button/Button';
import { Input } from '../../../components/FormComponents/Input';
import { InputPhone } from '../../../components/FormComponents/InputPhone';
import { TextArea } from '../../../components/FormComponents/TextArea';
import { Checkbox } from '../../../components/FormComponents/Checkbox';

import { Upload } from './upload';

import { resetuploadedfiles, sendAuthorRequest } from '../../../api';
import {email, FIELD_LENGTH, required} from '../../../utils/Form/formik-validation';
import { GAService } from '../../../services/gaService';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(FIELD_LENGTH.name)
    .required(required),
  phone: Yup.string()
    .max(FIELD_LENGTH.phone)
    .required(required),
  email: Yup.string()
    .email(email)
    .required(required),
  description: Yup.string()
    .max(FIELD_LENGTH.description)
    .required(required),
  accept: Yup.string().required(required)
});

const initialValues = {
  name: '',
  phone: '',
  email: '',
  description: '',
  fileIds: [],
  accept: false
};

// eslint-disable-next-line
const RequestForm = ({ values, errors, setFieldValue }) => {
  const error = useContext(GlobalErrorContext);
  const loading = useContext(LoadingContext);

  const disable = !values.accept || Object.keys(errors).length > 0;

  return (
    <Form noValidate>
      <div className="form-control-group form-control-row">
        <Field
          className="form-control-group__item"
          type="text"
          name="name"
          placeholder="Имя"
          component={Input}
        />
        <Field
          className="form-control-group__item"
          name="phone"
          type="tel"
          placeholder="Телефон"
          component={InputPhone}
        />
      </div>

      <div className="form-control-row">
        <Field
          type="email"
          name="email"
          placeholder="Email"
          component={Input}
        />
      </div>

      <div className="form-control-row">
        <Field
          name="description"
          rows={5}
          placeholder="Опишите кратко суть вашей стратегии. Не забудьте рассказать про доходность, уровень рисков и используемые методы торговли."
          component={TextArea}
        />
      </div>

      <div className="form-control-row">
        <Upload onUploaded={setFieldValue} />
      </div>

      <div className="form-control-row">
        <Field
          name="accept"
          text={`Даю согласие на обработку <a class='checkbox__link' href='https://broker.ru/sdobs/assets/agreement.pdf'>персональных&nbsp;данных</a>`}
          component={Checkbox}
        />
      </div>

      {error && <p className="error-message">{error}</p>}

      <div className="experts-request__form-button-wrapper">
        <Button
          className="_primary _large"
          type="submit"
          text="Отправить заявку"
          disabled={disable}
          loading={loading}
        />
      </div>
    </Form>
  );
};

const RequestCompleted = () => (
  <div className="experts-request__success">
    Спасибо! Мы уже читаем вашу заявку!
    <p className="experts-request__success-content">
      В ближайшее время с вами свяжется наш менеджер для уточнения деталей.
    </p>
  </div>
);

const LoadingContext = React.createContext(false);
const GlobalErrorContext = React.createContext('');

export const Request = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    resetuploadedfiles();
  }, []);

  const onSubmit = values => {
    setLoading(true);
    GAService.sendEvent('AutorsFormSend');
    sendAuthorRequest(values)
      .then(result => {
        const { errorMessage, success } = result;

        if (success) {
          setCompleted(true);
        } else {
          setError(errorMessage);
        }
      }) // eslint-disable-next-line
      .catch(err => setError('Произошла непредвиденная ошибка'))
      .finally(() => setLoading(false));
  };

  return (
    <div className="experts-request">
      {completed ? (
        <RequestCompleted />
      ) : (
        <>
          <div className="experts-request__title">
            Хотите предложить нам свою стратегию?
          </div>
          <div className="experts-request__description">
            Расскажите нам в свободной форме о себе и своей стратегии, заполните
            прилагаемые формы "Паспорт стратегии и "трек рекорд" в одном из
            удобных вам форматов и мы оперативно свяжемся с Вами для обсуждения
            деталей.
          </div>
          <div className="experts-request__form">
            <LoadingContext.Provider value={loading}>
              <GlobalErrorContext.Provider value={error}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnChange
                  validateOnBlur
                  onSubmit={onSubmit}
                  component={RequestForm}
                />
              </GlobalErrorContext.Provider>
            </LoadingContext.Provider>
          </div>
        </>
      )}
    </div>
  );
};
