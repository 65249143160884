import React, {useMemo, useState} from 'react';
import cn from 'classnames';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';

import { isBrowser } from '../../../utils';
import { HIGHCHART_SETTINGS } from '../../../const';
import { ChartPoint } from '../../../types/chartPoint';

if (isBrowser) {
  Highcharts.setOptions(HIGHCHART_SETTINGS);
}

type ChartPointArray = number[][];

type Props = {
  className?: string;
  data: ChartPoint[];
  description?: string;
  benchmarkName?: string;
  visibleChart: boolean;
};

export const LineChart: React.FC<Props> = ({
  className,
  data,
  description,
  benchmarkName,
  visibleChart = true
}) => {
  const [chartVisibilitySettings, setChartVisibilitySettings] = useState({
    history: visibleChart,
    commission: true,
    benchmark: true
  });
  const CHART_STYLES = {
    HISTORY: {
      type: 'line',
      name: 'доходность без издержек',
      color: '#066b8a',
      threshold: null,
      visible: chartVisibilitySettings.history,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    HISTORY_COMMISSION: {
      type: 'line',
      name: 'доходность с учетом издержек *',
      color: '#5ECBA1',
      threshold: null,
      visible: chartVisibilitySettings.commission,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    BENCHMARK: {
      type: 'line',
      name: 'бенчмарк',
      color: '#9598a7',
      threshold: null,
      visible: chartVisibilitySettings.benchmark,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true
          }
        }
      }
    }
  };

  const indexName = benchmarkName ? `индекс ${benchmarkName}` : 'бенчмарк';

  const fillData = (data: ChartPointArray, options: any) => ({
    ...options,
    data
  });

  const points: ChartPointArray = [];
  const pointsWithCommissions: ChartPointArray = [];
  const pointsIndex: ChartPointArray = [];

  if (data != null) {
    data.forEach(({ t, v, vc, vi }) => {
      if (v != null) {
        points.push([t, v]);
      }
      if (vc != null) {
        pointsWithCommissions.push([t, vc]);
      }
      if (vi != null) {
        pointsIndex.push([t, vi]);
      }
    });
  }
  const chartData = points.length
    ? fillData(points, CHART_STYLES.HISTORY)
    : null;
  const chartDataCommissions = pointsWithCommissions.length
    ? fillData(pointsWithCommissions, CHART_STYLES.HISTORY_COMMISSION)
    : null;
  const chartDataIndex = pointsIndex.length
    ? fillData(pointsIndex, { ...CHART_STYLES.BENCHMARK, name: indexName })
    : null;

  const series = [chartDataCommissions, chartData, chartDataIndex].filter(
    item => item !== null
  );

  const options = {
    chart: {
      style: {
        fontFamily: 'Gilroy',
        color: '#9598A7'
      }
    },
    xAxis: {
      type: 'datetime',
      crosshair: {
        color: '#EBE9EA'
      },
      gridLineWidth: 1,
      gridLineColor: '#EBE9EA',
      labels: {
        style: {
          color: '#9598A7'
        },
        format: '{value:%d.%m.%Y}'
      },
      lineColor: '#EBE9EA',
      tickLength: 0,
      tickWidth: 0
    },
    yAxis: {
      gridLineColor: '#EBE9EA',
      labels: {
        style: {
          color: '#9598A7'
        }
      },
      title: {
        text: null
      },
      lineColor: '#EBE9EA',
      lineWidth: 1,
      opposite: true
    },
    credits: {
      enabled: false
    },
    legend: {
      align: 'left',
      verticalAlign: 'bottom',
      useHTML: true,
      itemStyle: {
        fontSize: '10px',
        borderBottom: '1px solid rgb(204, 204, 204)'
      },
      x: 0,
      y: 0
    },
    title: {
      text: ''
    },
    tooltip: {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: 2,
      valueDecimals: 2,
      useHTML: true,
      split: false,
      shared: true,
      formatter: function() {
        //@ts-ignore
        const date = Highcharts.dateFormat('%a, %e %B %Y', new Date(this.x));
        //@ts-ignore
        const seriesData = this.points.reduce(
          //@ts-ignore
          (array, point) =>
            array.concat([
              {
                name: point.series.name,
                value: point.y,
                color: point.color
              }
            ]),
          []
        );

        let data = '';

        seriesData.forEach(
          //@ts-ignore
          item =>
            (data += `<span class='__point-dot' style='color:${item.color};'>
                                        \u25CF
                                      </span>
                                      <span class='chart-tooltip__series'>
                                        ${item.name}:
                                        <b class='chart-tooltip__series-value'>
                                          ${Highcharts.numberFormat(
                                            item.value,
                                            //@ts-ignore
                                            2
                                          )}%
                                        </b>
                                      </span>
                                      <br />`)
        );

        return `<span className='chart-tooltip'>${date}</span><br />${data}<br />`;
      }
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick() {
            // @ts-ignore
            const isProfit = this.name === CHART_STYLES.HISTORY.name;
            const isProfitWithCommis =
              // @ts-ignore
              this.name === CHART_STYLES.HISTORY_COMMISSION.name;
            // @ts-ignore
            const isBenchmark = this.name === indexName;

            const profitChartIsExist = points.length > 0;
            const pointChartWithCommissionsExist = pointsWithCommissions.length > 0;

            // @ts-ignore
            if (this.chart && this.chart.series) {
              // @ts-ignore
              this.chart.series.forEach(item => {
                if (
                  isProfit && pointChartWithCommissionsExist &&
                  item.name === CHART_STYLES.HISTORY_COMMISSION.name
                ) {
                  setChartVisibilitySettings({
                    ...chartVisibilitySettings,
                    commission: !item.visible,
                    history: !chartVisibilitySettings.history
                  });
                } else if (
                  isProfitWithCommis && profitChartIsExist &&
                  item.name === CHART_STYLES.HISTORY.name
                ) {
                  setChartVisibilitySettings({
                    ...chartVisibilitySettings,
                    history: !item.visible,
                    commission: !chartVisibilitySettings.commission
                  });
                } else if (isProfit) {
                  setChartVisibilitySettings({
                    ...chartVisibilitySettings,
                    history: !chartVisibilitySettings.history
                  });
                } else if (isProfitWithCommis) {
                  setChartVisibilitySettings({
                    ...chartVisibilitySettings,
                    commission: !chartVisibilitySettings.commission
                  });
                } else if (isBenchmark) {
                  setChartVisibilitySettings({
                    ...chartVisibilitySettings,
                    benchmark: !chartVisibilitySettings.benchmark
                  });
                }
              });
            }

            return false;
          }
        }
      }
    },
    series
  };

  return (
    <div className={cn('chart', className)}>
      <div className="chart__img">
        <HighchartsReact
          //@ts-ignore
          options={options}
          highcharts={Highcharts}
        />
      </div>
      <div
        className="chart__description"
        dangerouslySetInnerHTML={{ __html: description as string }}
      />
    </div>
  );
};
