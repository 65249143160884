import React from 'react'
import Button from '../../../components/Button/Button'

// eslint-disable-next-line
export const Actions = ({ items }) => (
  <div className='invest-profile__actions'>
    {items.map((item, index) => (
      <Button
        key={index}
        className={item.className}
        text={item.text}
        disabled={item.disabled}
        loading={item.loading}
        onClick={item.onClick}
        link={item.link}
        tag={item.tag}
        type={item.type}
      />
    ))}
  </div>
)
