import React from 'react';
import classNames from 'classnames';

import { enteredAnimate } from '../../HOC/enteredAnimate';

type Props = {
  className?: string;
};

const Section: React.FC<Props> = ({ className, children }) => (
  <section className={classNames('section', className)}>{children}</section>
);

const wrappedComponent = enteredAnimate(Section);

export { wrappedComponent as Section };
