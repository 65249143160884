import React from 'react';
import NestedStatus from 'react-nested-status';
import {Section} from '../../components/Section';
import Button from '../../components/Button/Button';
import { ReactComponent as ErrorIcon } from './_assets/error.svg';

export const Page404 = () => (
  <NestedStatus code={404}>
    <Section className={'error'}>
      <div className="section__content error__content">
        <div className="error__image">
          <svg className={'error__svg'} width="525" height="365">
            <ErrorIcon />
          </svg>
        </div>
        <div className="error__info">
          <h1 className="error__title">404</h1>
          <div className="error__subtitle">Страница не найдена</div>
          <Button
            tag="a"
            link="/"
            className="_primary _large error__button"
            text="Перейти на главную"
          />
        </div>
      </div>
    </Section>
  </NestedStatus>
);
