import { createStore, createApi, createEffect, forward } from 'effector';

import { getArticles } from '../api';
import { Nullable } from '../types/utils';
import { AnalyticsBrief } from '../types/analyticsBrief';

type TArticles$State = Nullable<AnalyticsBrief[]>;

export const Articles$ = createStore<TArticles$State>(null);

const { set } = createApi(Articles$, {
  set: (state, { result }) => {
    return result.response;
  }
});


export const articlesApi = {
  // @ts-ignore
  get: createEffect().use(getArticles)
};

forward({
  from: articlesApi.get.done,
  to: set
});
