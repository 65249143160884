import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toJS } from '../../../HOC/toJS';
import * as PopupActions from '../../../actions/PopupActions';
import Button from '../../Button/Button';
import { POP_UP } from '../../../const';
import { unbindPortfolio } from '../../../api';

// @ts-ignore
const mapStateToProps = state => ({
  popupData: state.getIn(['popup', 'data'])
});

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

type Props = {
  popupData: any;
  popupActions: any;
};

const UnbindPortfolio: React.FC<Props> = ({ popupData, popupActions }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const openAccept = useCallback(() => {
    setLoading(true);
    unbindPortfolio(popupData)
      .then(result => {
        const { errorMessage, success, response } = result;

        if (success) {
          const data = { ...popupData, content: response.acceptHtml };
          // @ts-ignore
          popupActions.openPopup(POP_UP.CONFIRM_UNBIND_PORTFOLIO, data);
        } else {
          setErrorMessage(errorMessage);
        }
      })
      .catch(() => setErrorMessage('Произошла непредвиденная ошибка'))
      .finally(() => setLoading(false));
  }, [popupData, popupActions, setLoading, setErrorMessage]);

  return (
    <div>
      <p className="modal__text-content">
        Внимание! При отключении автоследования от стратегии все текущие
        открытые позиции останутся на вашем брокерском счете. Закрыть их вы
        можете самостоятельно.
      </p>
      <p
        className="modal__text-content-warning"
        dangerouslySetInnerHTML={{ __html: popupData.unbindNote }}
      />
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <Button
        className="_primary _large _wide"
        text="Отключить"
        loading={loading}
        onClick={openAccept}
      />
    </div>
  );
};

const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(UnbindPortfolio));

export { wrappedComponent as UnbindPortfolio };
