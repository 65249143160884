import React, {useEffect} from 'react';
import {useStore} from 'effector-react';
import {useParams, useRouteMatch} from 'react-router-dom';
// @ts-ignore
// tslint:disable-next-line:no-implicit-dependencies
import YandexShare from 'react-yandex-share';
import Helmet from 'react-helmet';
import moment from 'moment';

import {enteredAnimate} from '../../HOC/enteredAnimate';

import {ArticleStrategies} from './strategies';
import {Author} from '../../components/Author';
import {ArticleList} from '../../components/ArticleList';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {PromoPageWrapper} from '../../components/PromoPageWrapper';

import {Article as ArticleProps} from '../../types/article';

import {Article$, articleApi} from '../../effector/article';
import {AuthorArticles$, authorArticlesApi} from '../../effector/authorArticles';

const LeftColumn: React.FC<ArticleProps> = ({
                                              date,
                                              title,
                                              categories,
                                              fullHtml,
                                              author
                                            }) => (
  <div className="article">
    <div className="article__author">
      {author && (
        <Author {...author} />
      )}
    </div>
    <div className="article__categories">
      {categories.map(item => (
        <div key={item.id} className="label _primary article__categories-item">
          {item.name}
        </div>
      ))}
    </div>
    <div className="article__date">
      {moment(date).format('DD.MM.YYYY HH:mm')}
    </div>
    <div
      className="page-template__content-title"
      dangerouslySetInnerHTML={{__html: title || ''}}
    />
    <div
      className="page-template__content"
      dangerouslySetInnerHTML={{__html: fullHtml || ''}}
    />
    <YandexShare
      content={{title}}
      theme={{lang: 'ru', services: 'collections,vkontakte,facebook,odnoklassniki,twitter,linkedin,whatsapp,telegram'}}
    />
  </div>
);

const LeftColumnConnected = enteredAnimate(LeftColumn);

const RightColumn: React.FC<{ title?: string }> = ({title}) => (
  <>
    <div
      className="page-template__suggestion_title"
      dangerouslySetInnerHTML={{__html: title || ''}}
    />
    <div className="page-template__suggestion_content">
      <ArticleStrategies/>
    </div>
  </>
);

const defaultCrumbs = [
  {
    link: '/blogs',
    text: 'Новости и аналитика'
  }
];

export const Article: React.FC<{}> = () => {
  const articles = useStore(AuthorArticles$);
  const article = useStore(Article$);
  const {id} = useParams<{id: string}>();

  useEffect(() => {
    articleApi.get(id);
  }, [id]);

  useEffect(() => {
    if (article?.author.friendlyUrl) {
      const params = {
        id: article?.author.friendlyUrl,
        count: 3
      };

      authorArticlesApi.get(params);
    }
  }, [article?.author.friendlyUrl]);

  if (!article) {
    return null;
  }

  const meta = {
    title: article?.seoTitle,
    meta: [
      {
        name: 'description',
        content: article?.seoDescription
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href
      }
    ],
  };

  return (
    <>
      {meta && <Helmet {...meta} />}
      <Breadcrumbs
        items={[...defaultCrumbs, {active: true, text: article.title}]}
      />
      <PromoPageWrapper
        image={article.picture}
        LeftComponent={() => <LeftColumnConnected {...article} />}
        RightComponent={() => <RightColumn title={article.strategiesTitle}/>}
      />
      {articles && articles?.length > 0 && (
        <div className="article__articles">
          <h2 className="article__subtitle">Другие статьи автора</h2>
          <ArticleList items={articles}/>
        </div>
      )}
    </>
  );
};
