import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import {GAService} from "../../services/gaService";
import Button from "../Button/Button";

export type TableRow = {
  title: string;
  content: string;
  className?: string;
};

type Props = {
  headers: string[];
  rows: TableRow[][];
  linesCount?: number;
  className?: string;
};

export const Table: React.FC<Props> = ({
  headers,
  rows,
  className,
  linesCount = rows.length
}) => {
  const [visibleLines, setVisibleLines] = useState<number>(linesCount);

  const rowTogglingIsEnabled = useMemo(
    () => linesCount !== rows.length && linesCount < rows.length,
    [linesCount, rows]
  );

  const isAllRowsVisible = useMemo(() => {
    return rows.length <= visibleLines;
  }, [visibleLines, rows]);

  const onClickLinesToggle = useCallback(() => {
    setVisibleLines(isAllRowsVisible ? linesCount : rows.length);
  }, [isAllRowsVisible, linesCount, rows, setVisibleLines]);

  return (
    <div>
      <table className={cn('table-new table-new_striped', className)}>
        <thead>
          <tr>
            {headers.map((item, key) => (
              <th key={key}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(
            (item, key) =>
              key <= visibleLines && (
                <tr key={key}>
                  {item.map(({ title, content, className }, key) => (
                    <td key={key}>
                      <div className="table-new__row">
                        <div className="table-new__title">{title}</div>
                        <div
                          className={cn('table-new__content _nobr', className)}>
                          {content}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              )
          )}
        </tbody>
      </table>
      {rowTogglingIsEnabled && (
        <div className="table-new__show">
          <Button
            className="_primary _large"
            text={isAllRowsVisible ? 'Свернуть' : 'Развернуть'}
            onClick={onClickLinesToggle}
          />
        </div>
      )}
    </div>
  );
};
