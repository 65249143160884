import React from 'react';
import cn from 'classnames';
import moment from 'moment';

import { PAGE_ARTICLES } from '../../../Pages/Routes';
import { AnalyticsBrief } from '../../../types/analyticsBrief';

import { Link } from '../../Link';

import { enteredAnimate } from '../../../HOC/enteredAnimate';

type Props = AnalyticsBrief & {
  className?: string;
};

const Article: React.FC<Props> = ({
  date,
  url,
  title,
  image,
  cutHtml,
  categories,
  className
}) => {
  const styles = {
    backgroundImage: `url(${image})`
  };

  return (
    <Link link={`${PAGE_ARTICLES}/${url}`} className="news-item__wrapper">
      <article className={cn('news-item', className)}>
        <div className="news-item__img" style={styles} />
        <div className="news-item__content">
          <div className="news-item__labels">
            {categories.map((item, key) => (
              <span key={key} className="label _primary news-item__labels-item">
                {item.name}
              </span>
            ))}
          </div>
          <span className="news-item__link">{title}</span>
          <p
            className="news-item__anons"
            dangerouslySetInnerHTML={{ __html: cutHtml }}
          />
          <time className="news-item__date">
            {moment(date).format('DD.MM.YYYY HH:mm')}
          </time>
        </div>
      </article>
    </Link>
  );
};

const wrapped = enteredAnimate(Article);

export { wrapped as Article };
