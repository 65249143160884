import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import {POP_UP, SEO_CONTENT} from '../../const';
import { toJS } from '../../HOC/toJS';
import { PAGE_EXPERTS, PAGE_PERSONAL } from '../Routes';
import * as PopupActions from '../../actions/PopupActions';

import { Link } from '../../components/Link';
import { Howto } from '../../components/Howto';
import Button from '../../components/Button/Button';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

import { ReactComponent as Visual } from './assets/visual.svg';
import { ReactComponent as Ellipse } from './assets/ellipse.svg';
import { ReactComponent as Follow } from './assets/follow.svg';
import { ReactComponent as Recommendation } from './assets/recommendations.svg';
import { ReactComponent as LkLeft } from './assets/lk-ellipse-fill.svg';
import { ReactComponent as LkRight } from './assets/lk-ellipse-blue.svg';
import { Section } from '../../components/Section';
import {useStore} from "effector-react";
import {Account$} from "../../effector/clientAccounts";

const defaultCrumbs = [
  {
    text: 'О проекте',
    active: true
  }
];

const items = [
  {
    title: 'Выберите стратегию',
    link: '/faq'
  },
  {
    title: 'Подключите автоследование или рекомендации',
    link: '/faq'
  },
  {
    title: 'Следите за результатами в личном кабинете',
    link: '/faq'
  }
];

const connections = [
  {
    image: <Follow />,
    title: 'Автоследование',
    description: `Вы подписываетесь на стратегию, а торговые поручения выполняются автоматически <br /> на основе рекомендаций автора стратегии`
  },
  {
    image: <Recommendation />,
    title: 'Автоконсультирование',
    description: `Вы получаете индивидуальные инвестиционные рекомендации и сами решаете,<br /> выполнять их или нет`
  }
];

const bcsInfo = [
  {
    columns: [
      {
        value: '25',
        description: 'лет на рынке'
      },
      {
        value: '37,7 млрд $',
        description: `объем клиентских операций <br /> на фондовом рынке`
      }
    ]
  },
  {
    columns: [
      {
        value: '5000',
        description: 'сотрудников'
      },
      {
        value: '370 000',
        description: 'клиентов в России'
      }
    ]
  },
  {
    columns: [
      {
        value: '96',
        description: 'агентских пунктов'
      },
      {
        value: '1',
        description: `место в рейтингах <br />Московской биржи`
      }
    ]
  }
];

const AboutPage = ({ popupActions, history }) => {
  const {account} = useStore(Account$);

  const toLk = () => {
    if (account) {
      history.push(PAGE_PERSONAL);
    } else {
      popupActions.openPopup(POP_UP.AUTHORIZATION, {
        onSuccess: () => {
          history.push(PAGE_PERSONAL);
        }
      });
    }
  };

  const seo = SEO_CONTENT['about'];

  return (
    <>
      {seo && <Helmet {...seo} />}
      <div className="about-page">
        <Breadcrumbs items={defaultCrumbs} />

        <Section>
          <h1 className="about-page__title">Fintarget — торгуй как профи</h1>
          <div className="about-page__title-description">
            Fintarget - магазин инвестиционных <br /> стратегий. С нами Вы будете
            инвестировать <br /> как профессионал.
          </div>

          <div className="about-page__note about-page__note-right">
            Если вы профессиональный управляющий, вы <br /> можете разместить
            здесь свою стратегию
          </div>

          <div className="about-page__connect about-page__note-right">
            <Button
              tag="a"
              link={PAGE_EXPERTS}
              className="button _success _large"
              type="button"
              text="Как это сделать"
            />
          </div>
        </Section>

        <div className="about-page__possibilities possibilities">
          <div className="possibilities__visualization">
            <Visual />
            <Ellipse className="ellipse" />
          </div>
          <div className="possibilities__description">
            <div className="possibilities__title">С нами вы получите</div>
            <ul className="possibilities__description-list">
              <li className="possibilities__description-list-item">
                Возможность инвестировать не имея опыта
              </li>
              <li className="possibilities__description-list-item">
                Профессиональное управление рисками от Брокера №1
              </li>
              <li className="possibilities__description-list-item">
                Комментарии и обратную связь от авторов стратегии
              </li>
            </ul>
          </div>
        </div>

        <div className="about-page__subtitle">Как это работает?</div>
        <Howto items={items} className="about" />

        <div className="about-page__subtitle">
          Варианты работы <br /> со стратегиями
        </div>
        <div className="about-page__connections">
          {connections.map(item => (
            <div className="connections" key={Math.random()}>
              <div className="connections__item-image">{item.image}</div>
              <div className="connections__item-description">
                <div className="connections__item-title">{item.title}</div>
                <div
                  className="connections__item-text"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="about-page__lk lk">
          <div className="lk__images">
            <div className="lk__images-wrapper">
              <LkLeft className="lk__image-left" />
              <LkRight className="lk__image-right" />
            </div>
          </div>
          <div className="lk__title">
            Следите за операциями с бумагами в личном кабинете
          </div>
          <button onClick={toLk} className="button _success _small" type="button">
            <span className="button__text lk__button-text">Личный кабинет</span>
          </button>
        </div>

        <div className="about-page__section-wrapper">
          <div className="about-page__note">
            Проект разработала компания «БКС брокер» — брокер №1 в рейтинге
            Московской биржи.
            <br />
            «БКС» — это:
          </div>

          <div className="about-page__bcs bcs">
            {bcsInfo.map(item => (
              <div className="bcs__column" key={Math.random()}>
                {item.columns.map(column => (
                  <div className="bcs__column-item" key={Math.random()}>
                    <div className="bcs__column-item-value">{column.value}</div>
                    <div
                      className="bcs__column-item-description"
                      dangerouslySetInnerHTML={{ __html: column.description }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="about-page__abuse">
            <div className="about-page__note about-page__note-right about-page__abuse-text">
              Раскрытие информации о компании, форма подачи обращений и связь со
              службой поддержки:{' '}
              <Link link="https://broker.ru/disclosure">
                https://broker.ru/disclosure
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
});

const wrappedComponent = withRouter(
  connect(
    null,
    mapDispatchToProps
  )(toJS(AboutPage))
);

export { wrappedComponent as AboutPage };
