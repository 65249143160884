import React from 'react';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import Swiper from 'react-id-swiper';

import { SliderItem } from './SliderItem';
import { enteredAnimate } from '../../HOC/enteredAnimate';
import { Banner } from '../../types/banner';

const options = {
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets' as  'bullets' | 'fraction' | 'progressbar' | 'custom',
    bulletClass: 'swiper-pagination-bullet',
    bulletActiveClass: 'swiper-pagination-bullet-active',
    clickable: true
  },
  autoplay: {
    delay: 7000
  },
  speed: 800,
  direction: isMobile ? 'horizontal' : 'vertical' as 'horizontal' | 'vertical' ,
  slidesPerView: 1,
  loop: true,
  draggable: true,
  containerClass: 'swiper-container main-slider__swiper',
  spaceBetween: 0
};

type Props = {
  items: Banner[];
  className: string;
};
const Slider: React.FC<Props> = ({ className, items }) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <section className={cn('main-slider', className)}>
          <Swiper
            {...options}
            //@ts-ignore
            autoplay={items.length === 1 ? false : options.autoplay}
          >
            {items.map((item, key) => (
              <div className="swiper-slide" key={key}>
                <SliderItem {...item} />
              </div>
            ))}
          </Swiper>
      </section>
    </div>
  );
};

const wrappedComponent = enteredAnimate(Slider);

export { wrappedComponent as Slider };
