import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-tippy/dist/tippy.css';
import 'react-toggle/style.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App/App';

import { store } from './services/store/configureStore';

import './styles/sass/screen.sass';

const Page = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.hydrate(Page, document.getElementById('root'));
