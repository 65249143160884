import React, { PureComponent } from 'react';
import cn from 'classnames';
import TitleTable from '../TitleTable/TitleTable';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../../actions/PopupActions';
import { connect } from 'react-redux';
import {PieChart} from '../../Chart/PieChart';
import {LineChart} from '../../Chart/LineChart';
import { COLORS, FREE_MONEY_COLOR } from '../../Chart/COLORS';
import { post } from '../../../api/apiHelper';
import { setPosition } from '../../../actions/PositionActions';
import { toJS } from '../../../HOC/toJS';
import { setHistory } from '../../../actions/HistoryActions';
import { formatMoney, roundNumber } from '../../../utils';
import Moment from 'react-moment';
import { POP_UP } from '../../../const';

const pieThead = [
  ' ',
  'Тикер',
  'Количество',
  'Доля',
  'Сделка',
  'Стоимость входа',
  'Текущая стоимость',
  'Прибыль'
];

const mapStateToProps = (state, ownProps) => ({
  positions: state.getIn(['position', `${ownProps.clientCode}`]),
  history: state.getIn(['history', `${ownProps.clientCode}`])
});

const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch),
  setPositions: data => dispatch(setPosition(data)),
  setHistory: data => dispatch(setHistory(data))
});

class AutoFollowDescriptionTab extends PureComponent {
  state = {
    errorMessage: ''
  };

  componentDidMount() {
    // eslint-disable-next-line
    this.loadPositions(this.props.clientCode);
    this.loadHistory(this.props.clientCode);
  }

  openRemoveStrategy = () => {
    // eslint-disable-next-line
    const { strategyId, clientCode, onDelete, unbindNote } = this.props;
    const approvalText = `<p>Спасибо! <br /> Ваша заявка принята.</p>`;

    // eslint-disable-next-line
    this.props.popupActions.openPopup(POP_UP.REMOVE_STRATEGY, {
      strategyId,
      clientCode,
      unbindNote,
      text: approvalText,
      action: onDelete
    });
  };

  loadPositions = clientCode => {
    post('account/clientaccountpositions', { clientCode: clientCode }).then(
      result => {
        const { errorMessage, success, response } = result;

        if (success) {
          // eslint-disable-next-line
          this.props.setPositions({ clientId: clientCode, data: response });
        } else {
          this.setState({ errorMessage });
        }
      }
    );
  };

  loadHistory = clientCode => {
    post('account/clientaccounthistory', { clientCode: clientCode }).then(
      result => {
        const { errorMessage, success, response } = result;

        if (success) {
          // eslint-disable-next-line
          this.props.setHistory({ clientId: clientCode, data: response });
        } else {
          this.setState({ errorMessage });
        }
      }
    );
  };

  getTitleTable = () => {
    const {
      // eslint-disable-next-line
      profile,
      // eslint-disable-next-line
      currencyPortfolios
    } = this.props;

    let titleTable = [
      {
        order: 1,
        title: 'Инвестиционный профиль клиента',
        text: [profile]
      },
      {
        order: 2,
        title: 'Стоимость вложений',
        text: currencyPortfolios
          ? currencyPortfolios.map(item =>
              item.positionsCost
                ? formatMoney(item.positionsCost, item.currency)
                : 0
            )
          : [],
        prefix: 'или'
      },
      {
        order: 3,
        title: 'Ликвидная стоимость',
        text: currencyPortfolios
          ? currencyPortfolios.map(item =>
              item.liquidPrice
                ? formatMoney(item.liquidPrice, item.currency)
                : 0
            )
          : [],
        prefix: 'или'
      }
    ];

    titleTable = titleTable.sort((a, b) => a.order - b.order);

    return titleTable;
  };

  render() {
    // eslint-disable-next-line
    const { positions, /*history*/ } = this.props;

    const titlesTable = this.getTitleTable();

    return (
      <>
        <div className="account-description__chart-info">
          <div className="chart _legend">
            <div className="chart__img account-description__chart-img">
              {positions && (
                <PieChart
                  data={positions}
                  textKey={'securityKey'}
                  valueKey={'weight'}
                  symbolKey={'securityKey'}
                />
              )}
            </div>
            <div className="chart__legend account-description__chart-legend">
              <table className="table _bordered account-description__table">
                <thead>
                  <tr data-stagger="">
                    {pieThead.map((item, key) => (
                      <th key={key}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {positions &&
                    positions.map((item, key) => (
                      <tr data-stagger="" key={key}>
                        <td>
                          <div className="chart__item">
                            <div
                              className="chart__color _small"
                              style={{
                                backgroundColor: item.securityKey
                                  ? COLORS[key]
                                  : FREE_MONEY_COLOR
                              }}
                            />
                            <span className="chart__item__name">
                              {item.securityKey
                                ? item.name
                                : 'Свободные средства'}
                            </span>
                          </div>
                        </td>
                        {[
                          {
                            title: 'Тикер',
                            text: item.securityKey ? item.securityKey : ''
                          },
                          {
                            title: 'Количество',
                            text: item.quantity ? item.quantity : ''
                          },
                          {
                            title: 'Доля',
                            text: `${roundNumber(Math.abs(item.weight))}%`
                          },
                          {
                            title: 'Сделка',
                            text: item.securityKey
                              ? item.weight > 0
                                ? 'Покупка'
                                : 'Продажа'
                              : ''
                          },
                          {
                            title: 'Стоимость входа',
                            text: item.securityKey
                              ? formatMoney(
                                  Math.abs(item.entryValue),
                                  item.priceCurrency
                                )
                              : ''
                          },
                          {
                            title: 'Текущая стоимость',
                            text: item.currentValue
                              ? formatMoney(item.currentValue, item.priceCurrency)
                              : ''
                          },
                          {
                            title: 'Прибыль',
                            className:
                              item.unrealizedPnl >= 0 ? 'rise' : 'fall',
                            text: item.securityKey
                              ? `${formatMoney(
                                  item.unrealizedPnl,
                                  item.currency
                                )} (${item.unrealizedPnlPct}%)`
                              : ''
                          }
                        ].map((item, key) => (
                          <td key={key}>
                            <div className="table__row">
                              <div className="table__title">{item.title}</div>
                              <div
                                className={cn(
                                  'table__content _nobr',
                                  item.className
                                )}>
                                {item.text}
                              </div>
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="account-description__info _v3">
          <TitleTable data={titlesTable} />
        </div>
        <div style={{ textAlign: 'right' }}>
          <button
            className="account-description__close"
            type="button"
            onClick={this.openRemoveStrategy}>
            Отключиться от стратегии
          </button>
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(AutoFollowDescriptionTab));
