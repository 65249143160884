import React from 'react';

// eslint-disable-next-line
export const PromoPageWrapper = ({
  image,
  LeftComponent,
  RightComponent,
  showRight = true
}) => {
  const styles = {
    backgroundImage: `url(${image})`
  };

  return (
    <div className="page-template">
      <div className="page-template__col page-template__col-left">
        <div className="page-template__col_image">
          {image && <div className="page-template__image" style={styles} />}
        </div>
        <div className="page-template__col_content">
          <LeftComponent />
        </div>
      </div>
      {showRight && (
        <div className="page-template__col page-template__col-right">
          <div className="page-template__col_content">
            <RightComponent />
          </div>
        </div>
      )}
    </div>
  );
};
