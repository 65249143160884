import React from 'react';
import { Route, Switch } from 'react-router-dom';
import scrollTop from '@aic/react-scroll-top';

import { Page404 } from './Page404/Page404';
import { SupportFaq } from './SupportFaq';
import HomePage from './HomePage/HomePage';
import { StrategiesPage } from './StrategiesPage/';
import { PersonalPage}  from './PersonalPage';
import { StrategyItem } from './StrategyItem';
import { AboutPage } from './AboutPage/AboutPage';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { InvestProfilePage } from './InvestProfile';
import { ExpertsPage } from './Experts';
import { GetFreePage } from './GetFree';
import { AuthorPage } from './AuthorPage';
import { Articles } from './Articles';
import { Article } from './Article';
import { Promo } from './Promo';
import { MainPage } from './MainPage';
import { UnsubscribePage } from './Unsubscribe';
import {PortfolioPage} from "./Portfolio";
import {PortfoliosPage} from "./Portfolios";
import {StrategySecurities} from "./StrategySecurities";

export const PAGE_HOME = '/';
export const PAGE_SUPPORT_FAQ = '/faq';
export const PAGE_SUPPORT_FAQ_CATEGORY = '/:category';
export const PAGE_SUPPORT_FAQ_QUESTION = '/:question';
export const PAGE_ABOUT = '/about';
export const PAGE_STRATEGIES = '/strategies';
export const PAGE_STRATEGY_ITEM = '/strategies/:id';
export const PAGE_PERSONAL = '/personal';
export const PAGE_INVEST_PROFILE = '/investprofile';
export const PAGE_GET_FREE = '/getitfree';
export const PAGE_EXPERTS = '/experts';
export const PAGE_AUTHOR = '/experts/:id';
export const PAGE_AUTHOR_STRATEGIES = '/strategies';
export const PAGE_AUTHOR_ARTICLES = '/blog';
export const PAGE_ARTICLES = '/blogs';
export const PAGE_ARTICLE = '/blogs/:id';
export const PAGE_PROMO = '/promo';
export const PAGE_UNSUBSCRIBE = '/unsubscribe';
export const PAGE_PORTFOLIOS = '/portfolios';
export const PAGE_PORTFOLIO = '/portfolios/:id';
export const PAGE_STRATEGY_SECURITY = '/strategies/security';
export const PAGE_STRATEGY_SECURITIES = '/strategies/security/:id';

export const PAGE_TARIFFS = 'https://broker.ru/regulations';
export const PAGE_ONBOARDING = 'https://broker.ru/sdobs';

const Routes = () => (
  <ErrorBoundary>
    <Switch>
      <Route exact path={PAGE_HOME} component={MainPage} />
      <Route path={PAGE_STRATEGIES} exact component={StrategiesPage} />
      <Route path={PAGE_STRATEGY_ITEM} exact component={StrategyItem} />
      <Route path={PAGE_PERSONAL} exact component={PersonalPage} />
      <Route path={PAGE_SUPPORT_FAQ} component={SupportFaq} />
      <Route path={PAGE_ABOUT} exact component={AboutPage} />
      <Route path={PAGE_INVEST_PROFILE} exact component={InvestProfilePage} />
      <Route path={PAGE_GET_FREE} exact component={GetFreePage} />
      <Route path={PAGE_EXPERTS} exact component={ExpertsPage} />
      <Route path={PAGE_AUTHOR} component={AuthorPage} />
      <Route path={PAGE_ARTICLES} exact component={Articles} />
      <Route path={PAGE_ARTICLE} exact component={Article} />
      <Route path={PAGE_PROMO} exact component={Promo} />
      <Route path={PAGE_UNSUBSCRIBE} exact component={UnsubscribePage} />
      <Route path={PAGE_PORTFOLIOS} exact component={PortfoliosPage} />
      <Route path={PAGE_PORTFOLIO} exact component={PortfolioPage} />
      <Route path={PAGE_STRATEGY_SECURITIES} exact component={StrategySecurities} />
      <Route path={'*'} component={Page404} />
    </Switch>
  </ErrorBoundary>
);

export default scrollTop({})(Routes);
