import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { default as SliderComponent } from 'rc-slider';

import { getNestedProperty } from '../../../utils';

import 'rc-slider/assets/index.css';

const GRADIENT_COLORS_LENGTH = 7;

type Props = {
  title: string;
  name: string;
  rcSlider: any;
  divider?: number;
  className?: string;
};

export const Slider: React.FC<Props> = ({
  title,
  name,
  rcSlider,
  divider = 1,
  className
}) => {
  const { values, setFieldValue } = useFormikContext();

  const [slideValue, setValue] = useState(getNestedProperty(values, name));

  const layoutValue = useMemo(() => {
    return (slideValue / divider).toLocaleString('ru-RU', {
      maximumFractionDigits: 2
    });
  }, [slideValue]);

  const classStep = useMemo(
    () => Math.ceil((rcSlider.max - rcSlider.min) / GRADIENT_COLORS_LENGTH),
    [rcSlider]
  );

  const handleClass = useMemo(() => {
    if (slideValue <= classStep) {
      return 'green';
    }

    if (slideValue <= classStep * 2) {
      return 'green2';
    }

    if (slideValue <= classStep * 3) {
      return 'green3';
    }

    if (slideValue <= classStep * 4) {
      return 'yellow';
    }

    if (slideValue <= classStep * 5) {
      return 'orange';
    }

    if (slideValue <= classStep * 6) {
      return 'red2';
    }

    if (slideValue <= rcSlider.max) {
      return 'red3';
    }

    return '';
  }, [classStep, slideValue, rcSlider]);

  const onChange = useCallback(
    (value: number) => {
      setValue(value);
    },
    [setValue]
  );

  const onAfterChange = useCallback(
    (value: number) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  return (
    <div
      className={classNames('range-slider', className, handleClass)}
      style={{ width: '100%' }}>
      <h3 className="range-slider__title">
        {title}{layoutValue}%
      </h3>
      <div className="range-slider__wrap">
        <SliderComponent
          {...rcSlider}
          value={slideValue}
          onChange={onChange}
          onAfterChange={onAfterChange}
        />
      </div>
    </div>
  );
};
