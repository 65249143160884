import { createStore, createApi, createEffect, forward } from 'effector';

import { getAuthorStrategies } from '../api';
import { Nullable } from '../types/utils';
// tslint:disable-next-line:no-any
type TAuthorStrategy$State = Nullable<any[]>;

export const AuthorStrategy$ = createStore<TAuthorStrategy$State>(null);

const { set } = createApi(AuthorStrategy$, {
  set: (state, { result }) => {
    return result.response;
  }
});

export const authorStrategyApi = {
  get: createEffect().use(getAuthorStrategies)
};

forward({
  from: authorStrategyApi.get.done,
  to: set
});
