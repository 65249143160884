import React, { useState, useEffect } from 'react'

export const Timer = ({ time, timeFinishCallback }) => {
  const [seconds, setSeconds] = useState(0)
  const [timeOutId, setTimeOutId] = useState(null)

  useEffect(() => {
    setSeconds(time)
  }, [time])

  useEffect(() => {
    tick()
    return clearTimeout(timeOutId)
  }, [seconds])

  const tick = () => {
    const timerId = setTimeout(() => {
      if (seconds <= 0) {
        if (timeFinishCallback) {
          timeFinishCallback()
        }

        return;
      }

      if (seconds > 0) {
        const timeLeft = seconds - 1
        setSeconds(timeLeft)
        tick()
      }
    }, 1000)

    setTimeOutId(timerId)
  }

  const getShowedTime = () => {
    const curMinutes = Math.floor(seconds / 60)
    let curSeconds = seconds - 60 * curMinutes
    curSeconds = curSeconds < 10 ? '0' + curSeconds : curSeconds

    return curMinutes + ':' + curSeconds
  }

  return <span className='timer'>{getShowedTime()}</span>
}
