import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { COLORS, FREE_MONEY_COLOR } from '../COLORS';
import { roundNumber } from '../../../utils';

type Props = {
  data: object[];
  textKey: string;
  valueKey: string;
  symbolKey: string;
};
export const PieChart: React.FC<Props> = ({
  data,
  textKey,
  valueKey,
  symbolKey
}) => {
  const dataSeries = data.map((item, key) => ({
    // @ts-ignore
    color: item[symbolKey] ? COLORS[key] : FREE_MONEY_COLOR,
    // @ts-ignore
    name: item[textKey],
    // @ts-ignore
    symbol: item[symbolKey] ? item[symbolKey] : `Свободные средства`,
    // @ts-ignore
    y: roundNumber(Math.abs(item[valueKey]))
  }));

  const chartOptions = useMemo(
    () => ({
      chart: {
        type: 'pie',
        spacing: [0, 0, 0, 0],
        height: '100%'
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          states: {
            hover: {
              halo: {
                size: 0,
                opacity: 0
              }
            }
          },
          tooltip: {
            headerFormat: '',
            pointFormat:
              '<span style="background-color: #fff">{point.symbol} {point.y} %</span>',
            padding: 7,
            outside: false
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      series: [
        {
          data: dataSeries
        }
      ]
    }),
    [dataSeries]
  );

  return (
    <HighchartsReact
      highcharts={Highcharts}
      // @ts-ignore
      options={chartOptions}
    />
  );
};
