import { createStore, createApi, createEffect, forward } from 'effector';

import { getLastArticles } from '../api';
import { Nullable } from '../types/utils';
import { AnalyticsBrief } from '../types/analyticsBrief';
// tslint:disable-next-line:no-any
type TArticlesLast$State = Nullable<AnalyticsBrief[]>;

export const ArticlesLast$ = createStore<TArticlesLast$State>(null);

const { set } = createApi(ArticlesLast$, {
  set: (state, { result }) => {
    return result.response;
  }
});

export const articlesLastApi = {
  // @ts-ignore
  get: createEffect().use(getLastArticles)
};

forward({
  from: articlesLastApi.get.done,
  to: set
});
