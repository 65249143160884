import React from 'react';

import Button from '../../../Button/Button';

import { Label } from '../../../Label';

import { ReactComponent as ManIcon } from './man2.svg';
import { ReactComponent as ManMobileIcon } from './man2-mobile.svg';
import { ReactComponent as VaveIcon } from './vave.svg';
import { Banner } from '../../../../types/banner';

type Props = Partial<Banner>;

export const Type2: React.FC<Props> = ({ title, subtitle, buttonText }) => (
  <div className="slider-item__container">
    <div className="slider-item__content">
      <div className="slider-item__labels">
        {/*{labels.map((label, key) => (*/}
        {/*  <Label key={key} {...label} />*/}
        {/*))}*/}
      </div>
      <div className="slider-item__title">{title}</div>
      <p className="slider-item__subtitle">{subtitle}</p>
      <Button
        className="button _large _primary slider-item__button"
        type="button"
        text={buttonText || 'Подробнее'}
      />
    </div>
    <div className="slider-item__image slider-item__image-first">
      <ManIcon className="slider-item__icon slider-item__icon-type2" />

      <Button
        className="button _large _white slider-item__button-mobile"
        type="button"
        text={buttonText || 'Подробнее'}
      />

      <ManMobileIcon className="slider-item__icon slider-item__icon-type2-mobile" />
      <VaveIcon className="slider-item__icon-vave" />
    </div>
  </div>
);
