import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../components/Button/Button';
import { ContentBlock } from '../../../components/ContentBlock';
import { Author } from '../../../components/Author';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../../actions/PopupActions';
import { connect } from 'react-redux';
import { enteredAnimate } from '../../../HOC/enteredAnimate';
import { toJS } from '../../../HOC/toJS';
import { formatMoney } from '../../../utils';
import { POP_UP } from '../../../const';
import Helmet from 'react-helmet';
import { QuestionTooltip } from '../../../components/UIKit/Tooltip';
import {PAGE_STRATEGY_SECURITY, PAGE_TARIFFS} from '../../Routes';
import { removePath } from '../../../actions/RouterInfoActions';
import { StrategyParams } from './StrategyParams';
import { StarRating, Size } from '../../../components/StarRating';
import { RecentDeals } from './RecentDeals';
import { ChartDetails } from './ChartDetails';
import { GAService } from '../../../services/gaService';
import { CallbackForm } from './CallbackForm';
import {ServiceKind, Strategy} from '../../../types/strategy';
import { InvestProfile } from '../../../types/investProfile';
import { useStore } from 'effector-react';
import { Account$ } from '../../../effector/clientAccounts';
import {
  StrategyProfits$,
  strategyProfitsApi
} from '../../../effector/strategyProfits';
import cn from 'classnames';
import { ProfitsTable } from './ProfitsTable';
import YouTube from 'react-youtube';
import { Howto } from '../../../components/Howto';
import { Wave } from '../../../components/Cards/Wave';
import { Section } from '../../../components/Section';
import { Element, scroller } from 'react-scroll';
import {useHistory} from 'react-router-dom';
import {Link} from '../../../components/Link';

enum TabType {
  profit,
  conditions,
  params
}

const items = [
  {
    title: 'Вы получаете консультацию эксперта БКС по стратегии',
    link: ''
  },
  {
    title: 'Вы подключаетесь к стратегии',
    link: ''
  },
  {
    title: 'Вы получаете идеи и отчёты о движении ваших средств',
    link: ''
  }
];

const promos = [
  {
    id: 1,
    title: 'Что такое инвестиционная стратегия',
    description:
      'Виктор Бондарович рассказывает, что такое инвестиционная стратегия, зачем она нужна, и с чего нужно начать формирование собственной стратегии.',
    video: 'vaT3EjVlVhk'
  },
  {
    id: 2,
    title: 'Какие бывают инвестиционные стратегии',
    description:
      'Виктор Бондарович рассказывает про основные виды стратегий: классические, алгоритмические и стратегии на основе искусственного интеллекта.',
    video: 'MYkESTREudQ'
  },
  {
    id: 3,
    title: 'Как выбрать инвестиционную стратегию',
    description:
      'Всеволод Зубов рассказывает, как выбрать инвестиционную стратегию. Для этого нужно определить финансовую цель, готовность к риску и срок инвестирования.',
    video: 'AIrqjeGKcRk'
  },
  {
    id: 4,
    title: 'Как стратегии попадают на финтаргет',
    description:
      'Ларг Сапецкий рассказывает, как авторские стратегии попадают на Fintarget: отбор, который проходят авторы торговых стратегий, риск-менеджмент стратегии, инвестиционный профиль стратегии.',
    video: 'q_t6RZUY55M'
  }
];

const tabs = [
  {
    id: TabType.profit,
    name: 'Доходность',
    className: 'strategy-detail__tab'
  },
  // {
  //   id: TabType.conditions,
  //   name: 'Условия'
  // },
  {
    id: TabType.params,
    name: 'Параметры'
  }
];

type Props = {
  strategy: Strategy;
  profile: InvestProfile;
  popupActions: object;
  extraOpen: boolean;
  removePath: () => void;
};

const StrategyDetail: React.FC<Props> = ({
  strategy,
  popupActions,
  extraOpen,
  removePath
}) => {
  const [activeTab, setActiveTab] = useState<{ id: TabType; name: string }>(
    tabs[1]
  );
  const [promo, setPromo] = useState<{
    id: number;
    title: string;
    description: string;
    video: string;
  }>(promos[0]);

  const { account } = useStore(Account$);
  const history = useHistory();
  const profits = useStore(StrategyProfits$);

  useEffect(() => {
    strategyProfitsApi.get(strategy.id);

    if (extraOpen) {
      openAddStrategy();
      removePath();
    }
  }, []);

  const params = useMemo(() => {
    const investProfileName = strategy?.minInvestProfile?.name;
    const investProfileDescription = strategy?.minInvestProfile?.description;
    const subscriptionThreshold = strategy?.subscriptionThreshold;
    const isQualified = strategy?.forQualifiedInvestorsOnly;

    const currencyId = strategy?.currency?.id;
    const duration = strategy?.duration?.name;
    const tools = strategy?.tools;

    const params = [
      {
        order: 1,
        name: 'Инвестиционный профиль',
        description: (
          <span>
            {investProfileName}{' '}
            <QuestionTooltip text={investProfileDescription} />
            {isQualified ? (
              <span className="strategy-params__row-addition">
                доступно только для квалифицированных инвесторов
              </span>
            ) : (
              ''
            )}
          </span>
        )
      },
      {
        order: 2,
        name: 'Инструменты',
        description: tools.reduce(
          (prev, value) => prev + (prev.length > 0 ? ', ' : '') + value.name,
          ''
        )
      },
      {
        order: 3,
        name: 'Торговые площадки',
        description: (
          <span>
            {strategy?.strategyBoards ? strategy.strategyBoards.map(({code}) => code).join(', ') : ''}
            <QuestionTooltip text={strategy?.strategyBoards
              ? strategy?.strategyBoards.map(({description, id}) => (
                <React.Fragment key={id}>
                  <span key={id}>{description}</span>
                  <br />
                </React.Fragment>
              ))
              : ''
            }/>
          </span>
        )
      },
      {
        order: 4,
        name: 'Минимальная сумма',
        description: (
          <span>
            {formatMoney(subscriptionThreshold, currencyId, 0)}
            <QuestionTooltip text="Минимальная сумма инвестиций, позволяющая эффективно использовать преимущества стратегии" />
          </span>
        )
      },
      {
        order: 5,
        name: 'Срок инвестиций',
        description: (
          <span>
            от {duration.toLowerCase()}
            <QuestionTooltip text="Параметр отражает рекомендуемый срок нахождения в стратегии для достижения ожидаемой доходности" />
          </span>
        )
      }
    ];

    // @ts-ignore
    if (strategy?.autoconsult) {
      params.push({
        order: 6,
        name: 'Стоимость Автоконсультирования <br /> ',
        description: (
          <span>
            {strategy.priceAC}
            <QuestionTooltip
              text={`Стоимость услуги "Автоконсультирование" составляет +100% к стоимости брокерского обслуживания по тарифу клиента
               для сделок на фондовом рынке Московской и Санкт-Петербургской бирже
                и/или 10 рублей за каждый срочный контракт на Срочном рынке Московской бирже.<br/><br/>
                Дополнительно ежемесячно взимается вознаграждение 100 рублей в месяц за СМС-обслуживание клиента.<br/><br/>
                Подробнее о регламенте и тарифах можно узнать в разделе 31 приложения №11 к
                Регламенту оказания услуг на рынке ценных бумаг ООО «Компания БКС» <a target="_blank" href='${PAGE_TARIFFS}'>на сайте Компании</a>.
              `}
            />
          </span>
        )
      });
    }

    // @ts-ignore
    if (strategy?.autofollow) {
      const baseTooltip = `Стоимость услуги в соответствии с категорией стратегии и правила расчета вознаграждения указаны в
                      п.31 Приложения №11 к Регламенту оказания услуг на рынке ценных бумаг ООО «Компания БКС».<br/>
                      Подробнее о регламенте и тарифах <a target="_blank" href='${PAGE_TARIFFS}'>на сайте Компании</a>.`;
      const campaign = strategy.campaigns?.find(({serviceKind}) => serviceKind === ServiceKind.AF);

      params.push({
        order: 7,
        name: 'Стоимость Автоследования <br /> ',
        description: (
          <React.Fragment>
            <span className={cn({'crossed': campaign})}>
              {strategy.priceAF}
              <QuestionTooltip text={baseTooltip}/>
            </span>
            {campaign && (
              <span>
                <span style={{ fontWeight: 700 }}>{campaign.tariffDisplayValue}</span>
                <QuestionTooltip text={campaign.tariffInfo}/>
                <span className="strategy-params__row-addition">
                  {campaign.tariffDescription}</span>
            </span>
            )}
          </React.Fragment>
        )
      });
    }

    if (strategy?.requiredTests && strategy.requiredTests.length > 0 && strategy.requiredTestsLink) {
      params.push({
          order: 8,
          name: 'Требуется тестирование',
          description: (
            <Link
              key={id}
              className="strategy-params__link"
              link={strategy.requiredTestsLink as string}
            >
                  {strategy.requiredTests.reduce(
                    (prev, value) => prev + (prev.length > 0 ? ', ' : '') + value.name,
                    ''
                  )}
            </Link>
          )
        })
    }

    return params.sort((a, b) => a.order - b.order);
  }, [strategy]);

  const videoId = useMemo(() => {
    const videoUrl = strategy.videoUrl;
    let id = '';

    if (videoUrl) {
      const splits = videoUrl.split('/');
      id = splits[splits.length - 1];
    }
    return id;
  }, [strategy]);

  const ratingTooltip = useMemo(
    () => (
      <div className="tooltip">
        <div className="tooltip__title">
          <strong>Рейтинг качества стратегии</strong>
        </div>
        <div className="tooltip__list">
          Рейтинг отражает эффективность стратегии в режиме автоследования с
          учетом риск-метрик и формируется из следующих параметров:
        </div>
        <div>Качество исполнения сделок на счете</div>
        <div>Соотношение риск/доходность</div>
        <div>Качественные параметры риск-метрик</div>
        <a href="/faq/algorithm/rating_calculation" className="tooltip__link">
          Подробнее о расчете рейтинга
        </a>
      </div>
    ),
    []
  );

  const onTooltipShow = useCallback(() => {
    GAService.sendEvent('ratingFAQ');
  }, []);

  const openAddStrategy = useCallback(() => {
    if (account) {
      // @ts-ignore
      popupActions.openPopup(POP_UP.ADD_STRATEGY);
    } else {
      // @ts-ignore
      popupActions.openPopup(POP_UP.AUTHORIZATION, {
        onSuccess: () => {
          // @ts-ignore
          popupActions.openPopup(POP_UP.ADD_STRATEGY);
        }
      });
    }
  }, []);

  const scrollTo = useCallback(() => {
    scroller.scrollTo('callback', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }, []);

  const onAllowedClassLink = useCallback(() => {
    history.push(`${PAGE_STRATEGY_SECURITY}/${strategy.id}`)
  }, [strategy, history]);

  const {
    id,
    name,
    tag,
    infoHtml,
    descriptionHtml,
    author,
    chartComment,
    displayRating
  } = strategy;

  const meta = {
    title: `${strategy.name} / Fintarget.ru - Автоследование в БКС Брокер`,
    meta: [
      {
        name: 'description',
        content:
          'Инвестиционные стратегии для торговли на бирже от экспертов БКС Брокер в формате автоследования'
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href
      }
    ]
  };

  return (
    <section className="strategy-detail">
      {meta && <Helmet {...meta} />}
      <div className="strategy-detail__row">
        <div className="strategy-detail__content">
          <div className="strategy-detail__content-section">
            <h1 className="strategy-detail__title">
              <div className="strategy-detail__title-wrapper">
                <div className="strategy-detail__rating-wrapper">
                  {displayRating && (
                    <StarRating
                      checked={displayRating}
                      size={Size.large}
                      className="strategy-detail__rating"
                    />
                  )}
                  <QuestionTooltip
                    className="strategy-detail__tooltip"
                    text={ratingTooltip}
                    onShow={onTooltipShow}
                  />
                </div>
                {name}
              </div>
            </h1>
            <ChartDetails strategyId={id} chartComment={chartComment} />

            <div className="tabs tabs-strategy">
              <div className="tabs__nav-wrapper">
                <div className="tabs__nav">
                  {tabs.map(tab => (
                    <span
                      key={tab.id}
                      className={cn('tabs__nav-item', tab.className, {
                        _active: activeTab.id === tab.id
                      })}
                      onClick={() => setActiveTab(tab)}>
                      {tab.name}
                    </span>
                  ))}
                </div>
              </div>
              <div className="tabs__content-wrapper">
                {activeTab.id === TabType.profit ? (
                  <ProfitsTable
                    profits={profits}
                    className="strategy-detail__tab"
                  />
                ) : (
                  <StrategyParams items={params} />
                )}
              </div>
            </div>
          </div>
          <div className="strategy-detail__sidebar">
            <div className="strategy-detail__connect">
              <Button
                className="_primary _large"
                text="Подключить"
                onClick={() => {
                  openAddStrategy();
                  GAService.sendEvent('ButtonAcceptTop');
                }}
              />
            </div>
            <p className="strategy-detail__subtitle strategy-detail__sales-points">
              {tag}
            </p>
            <p
              className="strategy-detail__text"
              dangerouslySetInnerHTML={{ __html: infoHtml }}
            />
          </div>
        </div>
      </div>

      <div className="strategy-detail__subtitle strategy-detail__profit">
        Доходность стратегии
      </div>
      <ProfitsTable profits={profits} className="strategy-detail__profit" />

      <div className="strategy-detail__row">
        <div className="strategy-detail__content">
          <div className="strategy-detail__content-section">
            <div className="strategy-detail__subtitle">Описание стратегии</div>
            <ContentBlock>
              <p
                className="strategy-detail__text"
                dangerouslySetInnerHTML={{ __html: descriptionHtml }}
              />
            </ContentBlock>
          </div>
          <div className="strategy-detail__sidebar">
            <div className="strategy-detail__video">
              {videoId && (
                <YouTube
                  videoId={videoId}
                  containerClassName="promo__marketplace-video"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="promo__about-tools" onClick={onAllowedClassLink}>
        <span className="checkbox__link">Подробнее об инструментах стратегии</span>
      </div>
      <div className="strategy-detail__subtitle">Автор стратегии</div>
      {
        // @ts-ignore
        // tslint:disable-next-line:jsx-wrap-multiline
        <Author className="strategy-detail__author" {...author} />
      }
      <ContentBlock>
        {author && (
          <p
            className="content-block__text"
            dangerouslySetInnerHTML={{ __html: author.infoHtml }}
          />
        )}
      </ContentBlock>
      <div className="strategy-detail__subtitle">Как это работает</div>
      <Howto items={items} className="about" />
      <Section className="promo__section-marketplace strategy-detail__videos">
        <div className="promo__marketplace">
          <ul className="promo__marketplace-list">
            {promos.map((item, index) => (
              <li
                key={index}
                className="promo__marketplace-list-item"
                onClick={() => setPromo(item)}>
                <span
                  className={cn('promo__marketplace-list-item-span', {
                    'promo__marketplace-list-item-span_active':
                      item.id === promo.id
                  })}>
                  {item.title}{' '}
                </span>
              </li>
            ))}
          </ul>
          <div className="promo__marketplace-content">
            <YouTube
              videoId={promo.video}
              containerClassName="promo__marketplace-video"
            />
            <div className="promo__marketplace-video-description">
              {promo.description}
            </div>
          </div>
        </div>
        <div className="promo__button-wrapper">
          <Button
            onClick={scrollTo}
            className="button _success _large"
            type="button"
            text="Оставить заявку"
          />
        </div>
        <Wave rootClass="promo__waves" />
      </Section>
      <RecentDeals strategyId={id} />
      <Element name="callback" className="element" />
      <CallbackForm />
    </section>
  );
};

// @ts-ignore
const mapStateToProps = state => ({
  extraOpen: state.getIn(['routerInfo', 'profileId'])
});

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch),
  removePath: () => dispatch(removePath())
});

// tslint:disable-next-line:no-default-export
export default enteredAnimate(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(toJS(StrategyDetail))
  // tslint:disable-next-line:max-file-line-count
);
