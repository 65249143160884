import { createStore, createApi, createEffect, forward } from 'effector';

import { getBanners } from '../api';
import { Banner } from '../types/banner';
import { Nullable } from '../types/utils';

export type TBanners$State = Banner[];

const initialState: TBanners$State = [];

export const Banners$ = createStore<TBanners$State>(initialState);

const { set } = createApi(Banners$, {
  set: (state, { result }) => {
    return result.response;
  }
});

export const bannersApi = {
  get: createEffect<Nullable<string>, {}, {}>().use(getBanners)
};

forward({
  from: bannersApi.get.done,
  to: set
});
