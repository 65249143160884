import React, { useState } from 'react'

import { Answer } from '../Answer'
import { Actions } from '../../Actions'

export const CheckboxList = ({
  loading,
  questionCode,
  error,
  items,
  canGoBack,
  onComplete,
  goBack
}) => {
  const [answers, setAnswers] = useState([])

  const inAnswers = answer =>
    !!answers.find(item => item.answerCode === answer.answerCode)

  const onAnswerClick = answer => {
    let updatedAnswers

    if (inAnswers(answer)) {
      updatedAnswers = answers.filter(
        item => item.answerCode !== answer.answerCode
      )
    } else {
      updatedAnswers = [...answers, answer]
    }

    setAnswers(updatedAnswers)
  }

  const onSubmit = () => {
    const result = {
      questionCode,
      answers
    }

    setAnswers([])
    onComplete(result)
  }

  const backButton = {
    loading,
    className: '_large',
    text: 'Назад',
    disabled: loading,
    onClick: goBack
  }

  const nextButton = {
    loading,
    className: '_primary _large',
    text: 'Далее',
    disabled: answers.length === 0 || loading,
    onClick: onSubmit
  }

  return (
    <>
      {items.map(item => (
        <Answer
          key={item.answerCode}
          isSelected={inAnswers(item)}
          text={item.value}
          onClick={() => {
            onAnswerClick({
              answerCode: item.answerCode
            })
          }}
        />
      ))}
      {error && <div className='invest-profile__error'> {error}</div>}
      <Actions items={canGoBack ? [backButton, nextButton] : [nextButton]} />
    </>
  )
}
