import { createStore, createApi, createEffect, forward } from 'effector';

import { getStrategyProfits } from '../api';
import { Nullable } from '../types/utils';
import { StrategyProfit } from '../types/strategyProfit';

type TStrategyProfits$State = Nullable<StrategyProfit[]>;

export const StrategyProfits$ = createStore<TStrategyProfits$State>(null);

const { set } = createApi(StrategyProfits$, {
  set: (state, { result }) => result.response
});

export const strategyProfitsApi = {
  get: createEffect().use(getStrategyProfits)
};

forward({
  from: strategyProfitsApi.get.done,
  to: set
});
