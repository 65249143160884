import React from 'react'
import ReactModal from 'react-modal'
import cn from 'classnames'

// eslint-disable-next-line
const Modal = ({ title, isOpen, children, className, onRequestClose }) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    bodyOpenClassName={'_modal-opened'}
    overlayClassName={'modal__overlay'}
    className={'modal__container'}
    portalClassName={cn('modal is-open', className)}
  >
    <header className='modal__header'>
      {title && <div className='modal__title'>{title}</div>}
      <button className='modal__close' type='button' onClick={onRequestClose}>
        Закрыть
      </button>
    </header>
    <div className='modal__content'>{children}</div>
  </ReactModal>
)

export default Modal
