import React from 'react';
import SelectField, { ValueType } from 'react-select';
import cn from 'classnames';
import { FieldInputProps, useField } from 'formik';

import { Option } from '../../../types/option';

type Props = {
  field: FieldInputProps<any>;
  options: Option[];
  placeholder: string;
  className?: string;
  disabled?: boolean;
  multiple?: boolean;
};

export const Select: React.FC<Props> = ({
  field: fieldProp,
  options,
  placeholder,
  className,
  disabled = false,
  multiple = false
}) => {
  const [field, meta, helpers] = useField(fieldProp.name);

  const isError = meta.touched && meta.error;

  const onChange = (value: ValueType<Option, boolean>) => {
    if (value) {
      helpers.setValue(value);
    } else {
      helpers.setValue([]);
    }
  };

  return (
    <div className={cn('select', className)}>
      <SelectField
        value={field.value}
        className="select__control"
        classNamePrefix="react-select"
        placeholder={placeholder}
        isDisabled={disabled}
        options={options}
        isMulti={multiple}
        onChange={onChange}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
      />
      {isError && <span className="form-control__message">{meta.error}</span>}
    </div>
  );
};
