import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from '../../reducers/_index';
import Immutable from 'immutable';
import { isDevelopment } from '../../utils';
import {
  asyncActionMiddleware,
  AsyncActionController
} from '@aic/react-remote-data-provider/extensions/serverReRender';
import { collectorMiddleware } from '@aic/react-remote-data-provider/extensions/collector';
import { globalErrorMiddleware } from '@aic/react-remote-data-provider/extensions/globalError';

const stateTransformer = state => {
  if (Immutable.Iterable.isIterable(state)) {
    return state.toJS();
  } else {
    return state;
  }
};

export const configureStore = (
  asyncActionController = new AsyncActionController()
) => {
  let store;
  let initialState;

  if (typeof window !== 'undefined' && window.__INITIAL_STATE__) {
    initialState = Immutable.fromJS(window.__INITIAL_STATE__);
  }

  if (isDevelopment) {
    const reduxDebug = process.env.REACT_APP_REDUX_DEBUG === 'true';

    const composeEnhancers =
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

    store = composeEnhancers(
      applyMiddleware(
        thunkMiddleware.withExtraArgument(asyncActionController),
        asyncActionMiddleware(asyncActionController),
        globalErrorMiddleware({ setByDefault: true }),
        collectorMiddleware(),
        createLogger({
          stateTransformer,
          predicate: (getState, action) => reduxDebug || action.debug,
          diff: true,
          collapsed: (getState, action, logEntry) =>
            !logEntry.error && !action.debug
        })
      )
    )(createStore)(rootReducer, initialState);

    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('../../reducers/_index', () => {
        store.replaceReducer(require('../../reducers/_index').rootReducer);
      });
    }
  } else {
    store = compose(
      applyMiddleware(
        thunkMiddleware.withExtraArgument(asyncActionController),
        asyncActionMiddleware(asyncActionController),
        globalErrorMiddleware({ setByDefault: true }),
        collectorMiddleware()
      )
    )(createStore)(rootReducer, initialState);
  }

  return store;
};

export const store = configureStore();
