import React, { useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps } from 'formik';

import {Section} from '../../../../components/Section';

import Button from '../../../../components/Button/Button';
import { Input } from '../../../../components/FormComponents/Input';
import { Checkbox } from '../../../../components/FormComponents/Checkbox';
import { InputPhone } from '../../../../components/FormComponents/InputPhone';

import { post } from '../../../../api/apiHelper';
import { GAService } from '../../../../services/gaService';

import { required } from '../../../../utils/Form/formik-validation';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(required),
  phone: Yup.string().required(required),
  rules: Yup.boolean().required(required)
});

const initialValues = {
  name: '',
  phone: '',
  rules: false
};

type FormFields = {
  name: string;
  phone: string;
  rules: boolean;
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ loading, error, values }) => {
  return (
    <Form className="section__form" noValidate>
      <div className="form-control-row">
        <Field placeholder="Имя" name="name" type="text" component={Input} />
      </div>

      <div className="form-control-row">
        <Field placeholder="Телефон" name="phone" component={InputPhone} />
      </div>

      <div className="form-control-row">
        <Field
          name="rules"
          text={`Даю согласие на обработку <a class='checkbox__link' href='https://broker.ru/sdobs/assets/agreement.pdf'>персональных&nbsp;данных</a>`}
          component={Checkbox}
        />
      </div>

      <div>
        <Button
          type="submit"
          loading={loading}
          disabled={!values.rules}
          className="_primary _large _wide"
          text="Отправить"
        />
      </div>

      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const CallbackForm: React.FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = (values: FormFields) => {
    const request = {
      question: {
        name: values.name,
        phone: values.phone
      },
      accept: values.rules
    };

    setLoading(true);
    setError('');

    GAService.sendEvent('CallbackRequest');

    post('questions/add', request)
      .then(({ success, errorMessage }) => {
        if (success) {
          setFormSuccess(true);
        } else {
          setError(errorMessage);
          setLoading(false);
        }
      })
      .catch(errorMessage => {
        setError(errorMessage);
        setLoading(false);
      });
  };

  return (
    <Section className="callback-form _form">
      <div className="section__inner">
        <div className="section__content">
          <div className="section__title">Задать вопрос по стратегии</div>
          <p className="section__desc">
            Заполните форму заявки, и мы перезвоним вам в ближайшее время.
          </p>
        </div>
        {formSuccess && (
          <div className="section__form">
            <div className="section__desc">
              <div className="section__subtitle">Спасибо!</div>
              <p className="section__success">
                Мы получили ваш запрос и в ближайшее время на него ответим!
              </p>
            </div>
          </div>
        )}
        {!formSuccess && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            validateOnBlur
            onSubmit={onSubmit}>
            {props =>
              React.createElement(FormTemplate, {
                ...props,
                loading,
                error
              })
            }
          </Formik>
        )}
      </div>
    </Section>
  );
};
