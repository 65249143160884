import { createStore, createApi, createEffect, forward } from 'effector';

import { getMarkets } from '../api';
import { Option } from '../types/option';

type TMarkets$State = Option[];

export const Markets$ = createStore<TMarkets$State>([]);

const { set } = createApi(Markets$, {
  set: (state, { result }) => result.response
});

export const marketsApi = {
  get: createEffect().use(getMarkets)
};

forward({
  from: marketsApi.get.done,
  to: set
});
