import * as React from 'react'
import classNames from 'classnames'

// eslint-disable-next-line
export const LoadingDots = ({ className }) => (
  <span className={classNames('loading', className)}>
    <i className='loading__left' />
    <i className='loading__center' />
    <i className='loading__right' />
  </span>
)
