import Modal from '../Modal/Modal'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { toJS } from '../../HOC/toJS'
import * as PopupActions from '../../actions/PopupActions'

import {
  AutoFollowing, BindPortfolio,
  RemoveStrategyConfirmation, UnbindPortfolioConfirmation
} from '../Form/AutoFollowing/AutoFollowing'
import {AuthForm} from '../Form/AuthForm'

import RemoveStrategy from '../Form/RemoveStrategy/RemoveStrategy'
import AddStrategy from '../Form/AddStrategy'
// import Recommendations from '../Form/Recommendations/Recommendations'
import { Attention } from '../Form/Attention/Attention'
import Approval from '../Form/Approval/Approval'
import AutoFollowingAccept from '../Form/AutoFollowingAccept'
import { POP_UP } from '../../const'
import {Subscribe} from "../Form/Subscribe";
import {UnbindPortfolio} from "../Form/UnbindPortfolio";

const PopupManager = ({ popupActions, popup }) => (
  <>
    <Modal
      isOpen={popup[POP_UP.AUTHORIZATION]}
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.AUTHORIZATION)
      }}
      title='Для подключения стратегий вам необходим брокерский счет в БКС'
    >
      <AuthForm />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.REMOVE_STRATEGY]}
      className='_v2'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.REMOVE_STRATEGY)
      }}
      title='Отключение стратегии'
    >
      <RemoveStrategy />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.AUTO_FOLLOWING]}
      className='_v2'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.AUTO_FOLLOWING)
      }}
      title='Подключение автоследования'
    >
      <AutoFollowing />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.BIND_PORTFOLIO]}
      className='_v2'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.BIND_PORTFOLIO)
      }}
      title='Подключение портфеля'
    >
      <BindPortfolio />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.UNBIND_PORTFOLIO]}
      className='_v2'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.UNBIND_PORTFOLIO)
      }}
      title='Отключение портфеля'
    >
      <UnbindPortfolio />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.CONFIRM_UNBIND_PORTFOLIO]}
      className='_v2'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.CONFIRM_UNBIND_PORTFOLIO)
      }}
      title='Отключение портфеля'
    >
      <UnbindPortfolioConfirmation />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.AUTO_FOLLOWING_ACCEPT]}
      className='_v2'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.AUTO_FOLLOWING_ACCEPT)
      }}
      title='Подключение автоследования'
    >
      <AutoFollowingAccept />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.REMOVE_AUTO_FOLLOWING]}
      className='_v2'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.REMOVE_AUTO_FOLLOWING)
      }}
      title='Отключение стратегии'
    >
      <RemoveStrategyConfirmation />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.ADD_STRATEGY]}
      className='_v2'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.ADD_STRATEGY)
      }}
      title='Подключение стратегии'
    >
      <AddStrategy />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.RECOMMENDATIONS]}
      className='_v2'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.RECOMMENDATIONS)
      }}
      title='Подключение автоконсультирования'
    >
      <AutoFollowing />
      {/* <Recommendations /> */}
    </Modal>

    <Modal
      isOpen={popup[POP_UP.ATTENTION]}
      className='_v2 _no-title'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.ATTENTION)
      }}
    >
      <Attention />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.APPROVAL]}
      className='_v2 _no-title'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.APPROVAL)
      }}
    >
      <Approval />
    </Modal>

    <Modal
      isOpen={popup[POP_UP.SUBSCRIBE]}
      className='_v2 _no-title'
      onRequestClose={() => {
        popupActions.hidePopup(POP_UP.SUBSCRIBE)
      }}
    >
      <Subscribe />
    </Modal>
  </>
)

const mapStateToProps = state => ({
  popup: state.get('popup')
})

const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(PopupActions, dispatch)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(toJS(PopupManager))
)
