import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { Logo } from '../Logo';
import Contact from './Contact/Contact';
import MainNav from '../MainNav/MainNav';

import { enteredAnimate } from '../../HOC/enteredAnimate';

import {
  PAGE_ABOUT,
  PAGE_ARTICLES,
  PAGE_EXPERTS, PAGE_PORTFOLIOS,
  PAGE_STRATEGIES,
  PAGE_SUPPORT_FAQ
} from '../../Pages/Routes';

import data from './copyright';

class PageFooter extends PureComponent {
  render() {
    const { className } = this.props;
    return (
      <footer className={classNames('page-footer', className)}>
        <div className="page-footer__container">
          <div className="page-footer__main">
            <Logo className="_dark _small page-footer__logo" />
            <MainNav
              className={'_dark page-footer__main-nav'}
              items={[
                {
                  link: PAGE_STRATEGIES,
                  text: 'Стратегии'
                },
                {
                  link: PAGE_ARTICLES,
                  text: 'Аналитика'
                },
                {
                  link: PAGE_EXPERTS,
                  text: 'Авторам'
                },
                {
                  link: PAGE_ABOUT,
                  text: 'О проекте'
                },
                {
                  link: PAGE_SUPPORT_FAQ,
                  text: 'FAQ'
                }
              ]}
            />
            <Contact className={'page-footer__contact'} />
          </div>

          <div className="page-footer__copyright">
            {data.map((item, key) => (
              <p
                key={key}
                className="page-footer__text"
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
          </div>
        </div>
      </footer>
    );
  }
}

export default enteredAnimate(PageFooter);
