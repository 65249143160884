import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  link: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

type LinkProps = {
  tag?: string;
  href?: string;
  target?: string;
  rel?: string;
  to?: string;
  className?: string;
  onClick?: () => void;
};

export const Link: React.FC<Props> = ({
  children,
  className,
  link,
  onClick
}) => {
  const componentProps: LinkProps = {};

  const route = link || '#';

  const isHttp =
    route && typeof route === 'string' && route.indexOf('http') === 0;

  // @ts-ignore
  const Component: React.FC<LinkProps> = route && isHttp ? 'a' : RouterLink;

  if (route) {
    if (isHttp) {
      componentProps.tag = 'a';
      componentProps.href = route;
      componentProps.target = '_blank';
      componentProps.rel = 'noopener noreferrer';
    } else {
      componentProps.to = route;
    }

    if (onClick) {
      componentProps.onClick = onClick;
    }
  }

  return (
    <Component className={className} {...componentProps}>
      {children && children}
    </Component>
  );
};
