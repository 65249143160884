import React from 'react';
import { getComponentDisplayName } from '../utils/getComponentDisplayName';

export const enteredAnimate = WrappedComponent => {
  class EnteredAnimate extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  EnteredAnimate.displayName = `EnteredAnimate(${getComponentDisplayName(
    WrappedComponent
  )})`;

  return EnteredAnimate;
};
