import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { noun } from 'plural-ru';
import { useStore } from 'effector-react';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import Cards from '../../../components/Cards/Cards';

import { filterCards } from '../_helpers';

import { FormProps, SortCategory } from '../';

import { Strategies$ } from '../../../effector/strategies';

// import { HeaderList } from '../../../components/HeaderList';
// import Helmet from "react-helmet";

export const sortCategories: SortCategory[] = [
  {
    id: 'actualProfit',
    name: 'по доходности'
  },
  {
    id: 'estimatedRisk',
    name: 'по уровню риска'
  },
  {
    id: 'rating',
    name: 'по рейтингу'
  },
  {
    id: 'subscriptionThresholdRub',
    name: 'по минимальной сумме'
  }
];

export enum Sort {
  ASK = 'asc',
  DESK = 'desc'
}

// tslint:disable-next-line:no-magic-numbers
const ascSortingCategory = [sortCategories[1], sortCategories[3]];

export const Catalog: React.FC<{}> = () => {
  const strategies = useStore(Strategies$);
  const { values, setFieldValue } = useFormikContext<FormProps>();

  const cards = useMemo(() => filterCards({ values, cards: strategies }), [
    values,
    strategies
  ]);

  const setCardFilter = useCallback(
    (category: SortCategory) => {
      let sortDirections = Sort.DESK;

      if (
        values.filter.sort.direction === Sort.DESK &&
        (values.filter.sort.id === category.id ||
          ascSortingCategory.includes(category))
      ) {
        sortDirections = Sort.ASK;
      }

      setFieldValue('filter.sort', {
        id: category.id,
        name: category.name,
        direction: sortDirections
      });
    },
    [values, setFieldValue]
  );

  return (
    <>
      <header className="section__header">
        <h1 className="section__title">Стратегии</h1>
        <span className="section__caption">
          {noun(cards.length, 'Найдена', 'Найдено', 'Найдено')}{' '}
          {noun(cards.length, '%d стратегия', '%d стратегии', '%d стратегий')}
        </span>
        <div className="filter__categories">
          <div className="filter__categories-label">Сортировать: </div>
          <div className="filter__categories-list">
            {sortCategories.map((item, key) => (
              <div
                key={key}
                onClick={() => setCardFilter(item)}
                className={cn('filter__categories-item', {
                  'filter__categories-item-active':
                    values.filter.sort.id === item.id
                })}>
                {item.name}

                {values.filter.sort.id === item.id && (
                  <span className="filter__categories-item-sort">
                    <FontAwesomeIcon
                      icon={
                        values.filter.sort.direction === Sort.ASK
                          ? faSortUp
                          : faSortDown
                      }
                    />
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </header>
      <div className="section__content">
        {cards && cards.length ? (
          <Cards items={cards} />
        ) : (
          <div>
            Подходящих стратегий не найдено, попробуйте изменить условия поиска
          </div>
        )}
      </div>
    </>
  );
};
