import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Routes from '../Pages/Routes';
import { withRouter } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import PageFooter from '../components/PageFooter/PageFooter';
import PopupManager from '../components/PopupManager/PopupManager';
import { getNestedProperty, scrollWidth } from '../utils';
import { ReactComponent as SpriteIco } from './assets/sprite-ico.svg';
import { SEO_CONTENT } from '../const';

import queryString from 'query-string';
import { QueryService, UTM_PARAMS } from '../services/queryService';
import { accountApi } from '../effector/clientAccounts';
import { store } from '../services/store/configureStore';
import { resetPopup } from '../actions/PopupActions';

class App extends Component {
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      store.dispatch(resetPopup());
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    scrollWidth();
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${scrollWidth()}px`
    );
    accountApi.getAccount('');
    this.checkReferenceId();
  }

  checkReferenceId() {
    const searchString = getNestedProperty(this.props, 'location.search');
    const params = queryString.parse(searchString);

    if (params[UTM_PARAMS.REF_ID]) {
      QueryService.refId = params[UTM_PARAMS.REF_ID];
    }

    if (params[UTM_PARAMS.UTM_SOURCE]) {
      QueryService.utmSource = params[UTM_PARAMS.UTM_SOURCE];
    }

    if (params[UTM_PARAMS.UTM_CAMPAIGN]) {
      QueryService.utmCampaign = params[UTM_PARAMS.UTM_CAMPAIGN];
    }

    if (params[UTM_PARAMS.UTM_CONTENT]) {
      QueryService.utmContent = params[UTM_PARAMS.UTM_CONTENT];
    }

    if (params[UTM_PARAMS.UTM_MEDIUM]) {
      QueryService.utmMedium = params[UTM_PARAMS.UTM_MEDIUM];
    }

    if (params[UTM_PARAMS.UTM_TERM]) {
      QueryService.utmTerm = params[UTM_PARAMS.UTM_TERM];
    }
  }

  render() {
    const seo = SEO_CONTENT['/'];

    return (
      <>
        <Helmet {...seo} />
        <div className="invisible-container">
          <SpriteIco />
        </div>
        <PageHeader className={'page__header'} />
        <main className="page__content">
          <Routes />
        </main>
        <PageFooter className={'page__footer'} />
        <PopupManager />
      </>
    );
  }
}

const withRouterApp = withRouter(App);
export { withRouterApp as App };
