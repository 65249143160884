import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Author } from '../../components/Author';
import Account from '../../components/Account/Account';
import { connect } from 'react-redux';
import { toJS } from '../../HOC/toJS';
import { useHistory } from 'react-router-dom';
import { PAGE_HOME, PAGE_INVEST_PROFILE, PAGE_PERSONAL } from '../Routes';
import { SEO_CONTENT } from '../../const';
import Helmet from 'react-helmet';
import { setPath } from '../../actions/RouterInfoActions';
import Button from '../../components/Button/Button';
import { GAService } from '../../services/gaService';
import { useStore } from 'effector-react';
import { Account$, accountApi } from '../../effector/clientAccounts';
import { Nullable } from '../../types/utils';
import { Account as AccountType } from '../../types/account';

type Props = {
  isFetching: boolean;
  setPath: (data: { path: string }) => void;
};

const PersonalPage: React.FC<Props> = ({ isFetching, setPath }) => {
  const [prevFetching, setPrevFetching] = useState(false);
  const [prevAccount, setPrevAccount] = useState<Nullable<AccountType>>(null);

  const history = useHistory();
  const { account, accounts, profile, isFetchingProfile } = useStore(Account$);

  useEffect(() => {
    accountApi.getAccounts('');
  }, []);

  useEffect(() => {
    if (
      (prevFetching && !prevAccount && !isFetching && !account) ||
      (prevAccount && !account)
    ) {
      history.push(PAGE_HOME);
    }
    setPrevFetching(isFetchingProfile);
    setPrevAccount(account);
  }, [account, isFetchingProfile]);

  const refreshAccounts = useCallback(() => {
    accountApi.refreshAccounts('');
  }, []);

  const toInvestProfile = useCallback(() => {
    GAService.sendEvent('PersonalChangeProfile');

    setPath({ path: PAGE_PERSONAL });
    history.push(PAGE_INVEST_PROFILE);
  }, [setPath]);

  const updateLimits = useCallback(() => {
    GAService.sendEvent('PersonalRefreshButton');
    refreshAccounts();
  }, [refreshAccounts]);

  const seo = SEO_CONTENT['personal'];

  return (
    <div>
      <Helmet {...seo} />
      <Breadcrumbs
        items={[
          {
            active: true,
            text: 'Личный кабинет'
          }
        ]}
      />
      <section>
        {
          // @ts-ignore
          // tslint:disable-next-line:jsx-wrap-multiline
          <Author
            className="_personal"
            {...account}
            showPhoto={false}
            showLink={false}
          />
        }
      </section>
      {profile && profile.name && (
        <section className="personal-page__profile">
          <span>
            Инвестиционный профиль:{' '}
            <span className="personal-page__profile-name">{profile.name}</span>
          </span>
          <span
            className="personal-page__profile-link"
            style={{ cursor: 'pointer' }}
            onClick={toInvestProfile}>
            Изменить
          </span>
        </section>
      )}

      <section className="personal-page__accounts">
        <span className="personal-page__accounts-title">Мои счета:</span>
        <Button
          onClick={updateLimits}
          className="_primary _small"
          text="Обновить"
        />
      </section>

      <section>
        {accounts &&
          accounts.map((item, key) => (
            <Account {...item} key={key} refreshAccounts={refreshAccounts} />
          ))}
      </section>
    </div>
  );
};

// @ts-ignore
const mapDispatchToProps = dispatch => ({
  // @ts-ignore
  setPath: data => dispatch(setPath(data))
});

const wrappedComponent = connect(
  null,
  mapDispatchToProps
)(toJS(PersonalPage));

export { wrappedComponent as PersonalPage };
