import React from 'react';
import cn from 'classnames';

type Props = {
  titles: string[];
  className?: string;
  data: Array<
    Array<{
      title: string;
      text: string;
      color?: string;
      className?: string;
    }>
  >;
};

export const PieChartDetails: React.FC<Props> = ({
  titles,
  className,
  data
}) => {
  return (
    <div className={cn('pie-chart-details', className)}>
      <table className="table pie-chart-details__table">
        <thead>
          <tr>
            {titles.map((item, key) => (
              <th key={key}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, key) => (
            <tr data-stagger="" key={key}>
              {row.map(({ title, text, color, className }, index) => {
                return index === 0 ? (
                  <td key={title}>
                    <div className="pie-chart-details__item">
                      <div
                        className="pie-chart-details__color pie-chart-details__color_small"
                        style={{
                          backgroundColor: color
                        }}
                      />
                      <span className="pie-chart-details__item-name">
                        {text}
                      </span>
                    </div>
                  </td>
                ) : (
                  <td key={title}>
                    <div className="table__row">
                      <div className="table__title">{title}</div>
                      <div className={cn('table__content _nobr', className)}>
                        {text}
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
