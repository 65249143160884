import { polyfill } from 'es6-promise';
import 'whatwg-fetch';
import { get, post, deleteRequest } from './apiHelper';

import { Nullable } from '../types/utils';
import { PortfolioBinding } from '../types/portfolioBinding';

polyfill();

// @ts-ignore
export const login = data => post('account/login', data);

export const logOut = () => get('account/logout');

export const getAccount = () => get('account/clientdetails');

export const getClientAccounts = () => get('account/clientAccounts');

export const refreshAccount = () => get('account/refresh');

export const refreshAccountV2 = (strategyId: string) =>
  get(`v2/account/refresh/${strategyId}`);

export const getProfile = () => get('account/clientprofile');

// @ts-ignore
export const sendQuestions = data => post('account/calcquestionary', data);

// @ts-ignore
export const getProfileDoc = data => post('account/profiledoc', data);

// @ts-ignore
export const startProfileConfirmation = data =>
  post('account/sendprofileconfirmation', data);

// @ts-ignore
export const confirmProfile = data => post('account/confirmprofile', data);

// @ts-ignore
export const bindStrategy = data => post('account/bindstrategy', data);

// @ts-ignore
export const getAuthorsStatistic = () => get('authors/statistics');

// @ts-ignore
export const getAuthorsDocuments = () => get('authors/docsbygroup');

// @ts-ignore
export const getAuthorDocument = data => post('authors/downloaddoc', data);

// @ts-ignore
export const deleteUploadedAuthorDocument = data =>
  post('authors/deletedoc', data);

// @ts-ignore
export const sendAuthorRequest = data => post('authors/requestnew', data);

// @ts-ignore
export const resetuploadedfiles = () => get('authors/resetuploadedfiles');

// @ts-ignore
export const getRecentDeals = strategyId =>
  get(`strategies/trades/${strategyId}/3`);

// @ts-ignore
export const getChartData = (strategyId, period) =>
  get(`strategies/charts/${strategyId}/${period}`);

export const getTickers = () => get('quotes/indices');

export const getStrategiesProfits = () => get('strategies/profits');

export const getFree = () => get('strategies/getfree');

export const getFaq = () => get('support');

export const getQuestions = () => get('account/profilequestionary');

export const getStrategies = () => get('strategies');

// @ts-ignore
export const getStrategy = id => get(`strategies/${id}`);

export const getInvestProfiles = () => get('investprofile');

export const getMarkets = () => get('markets');

export const getTools = () => get('tools');

export const getTop = () => get('strategies/top');

export const getRecommended = () => get('strategies/recommended');

// @ts-ignore
export const getAuthor = id => get(`authors/name/${id}`);
// @ts-ignore
export const getAuthorStrategies = id => get(`strategies/byauthor/${id}`);
// @ts-ignore
export const getAuthorArticles = params =>
  get(`analytics/author/${params.id}/${params.count}`);

// @ts-ignore
export const getArticles = params => post(`analytics/last_cat`, params);

// @ts-ignore
export const getLastArticles = (count? = 3) => get(`analytics/last/${count}`);

// @ts-ignore
export const getLastDeals = (count? = 3) => get(`lastSignals/${count}`);

// @ts-ignore
export const getArticle = id => get(`analytics/${id}`);

// @ts-ignore
export const getArticleStrategies = id => get(`analytics/${id}/strategies`);

// @ts-ignore
export const getStrategyArticles = (id, count? = 3) =>
  get(`analytics/strategy/${id}/${count}`);

export const getCategories = () => get('analytics/categories');

export const getBanners = (utm: Nullable<string> = '') =>
  get(`banners/utm/${utm}`);

export const getLandingBanners = () => get('banners/landing');

export const setIirForAgreement = (data: {
  agreementGuid: string;
  sendIirSms: boolean;
}) => post('account/setiirsms', data);

// @ts-ignore
export const unsubscribe = data => deleteRequest('analytics/subscribe', data);

export const getPortfolios = () => get('v2/termportfolios');

export const getPortfolio = (data: { portfolioId: string }) =>
  get(`v2/termportfolios/${data.portfolioId}`);

export const getAccountPortfolios = (data: { clientCode: string }) =>
  post('account/termportfolios', data);

export const bindPortfolio = (data: PortfolioBinding) =>
  post('v2/termportfolios/bind', data);

export const unbindPortfolio = (data: PortfolioBinding) =>
  post('v2/termportfolios/unbind', data);

// @ts-ignore
export const getStrategyProfits = id => get(`strategies/plperiods/${id}`);

export const getStrategySecurities = (id: string) =>
  get(`strategies/securities/${id}`);
