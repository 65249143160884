import 'rc-slider/assets/index.css';

import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Slider, { Range } from 'rc-slider';
import { useFormikContext } from 'formik';

import { FormProps } from '../../';
import { Option } from '../../../../types/option';

import { getNestedProperty } from '../../../../utils';

type Props = {
  name: string;
  title: string;
  range?: boolean;
  rcSlider: any;
  divider: string;
  measure?: string;
  className?: string;
  profiles?: Option[];
};

export const RangeSlider: React.FC<Props> = ({
  name,
  className,
  title,
  range,
  rcSlider,
  profiles = []
}) => {
  const { values, setFieldValue } = useFormikContext<FormProps>();

  const slideValue = useMemo(() => getNestedProperty(values, name), [
    values,
    name
  ]);

  const profileName = useMemo(() => profiles.find(x => x.id === slideValue), [
    profiles,
    slideValue
  ]);

  const onChange = useCallback(
    (value: number | number[]) => {
      setFieldValue(name, value);
    },
    [setFieldValue, name]
  );

  return (
    <div className={classNames('range-slider', range && '_range', className)}>
      <div className="range-slider__title">
        {title} {profileName && profileName.name}
      </div>
      <div className="range-slider__wrap">
        {range ? (
          <Range {...rcSlider} value={slideValue} onChange={onChange} />
        ) : (
          <Slider {...rcSlider} value={slideValue} onChange={onChange} />
        )}
      </div>
    </div>
  );
};
