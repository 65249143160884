import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { LoadingDots } from '../../../../components/UIKit/LoadingDots';
import {LineChart} from '../../../../components/Chart/LineChart';

import { getChartData } from '../../../../api';

const withLoading = WrappedComponent => props => {
  const [loading, setLoading] = useState(false);

  return (
    <div
      className={cn('with-loading', {
        'with-loading-loading': loading
      })}>
      <LoadingDots className={cn({ 'button-hide': !loading })} />
      <WrappedComponent {...props} setLoading={setLoading} />
    </div>
  );
};

const periods = [
  { key: 'ytd', value: 'YTD' },
  { key: '1w', value: '1 нед' },
  { key: '1m', value: '1 мес' },
  { key: '3m', value: '3 мес' },
  { key: '6m', value: '6 мес' },
  { key: '1y', value: '1 год' },
  { key: 'all', value: 'Весь период' }
];

const ChartDetails = ({ strategyId, chartComment, setLoading }) => {
  const [period, setPeriod] = useState(periods[periods.length - 1]);
  const [data, setData] = useState(null);

  useEffect(() => {
    loadChartData(period.key);
  }, [strategyId]);

  const onPeriodClick = newPeriod => {
    if(!data.validPeriods.includes(newPeriod.key)){
      return null;
    }

    setLoading(true);
    setPeriod(newPeriod);
    loadChartData(newPeriod.key);
  };

  const loadChartData = periodKey => {
    getChartData(strategyId, periodKey)
      .then(result => {
        const { success, response } = result;

        if (success && response) {
          setData(response);
        }
      }) // eslint-disable-next-line
      .catch(error => console.log('Произошла непредвиденная ошибка'))
      .finally(() => setLoading(false));
  };

  if (!data) {
    return null;
  }

  const {
    chart,
    profit,
    profitDescription,
    indexProfit,
    indexDescription,
    validPeriods
  } = data;

  return (
    <div className="strategy-detail__chart">
      <div className="strategy-detail__subtitle">Доходность</div>
      <div className="strategy-detail__info">
        <div className="strategy-profit">
          <span
            className={cn('strategy-profit__value', {
              fall: profit < 0
            })}>
            {profit}%
          </span>
          <div className="strategy-profit__caption">
            <div>{profitDescription}</div>
            <div>с учетом издержек</div>
          </div>
        </div>
        <div className="strategy-statistic">
          <p>{indexProfit && `${indexProfit}% индекс ${indexDescription}`}</p>
        </div>
      </div>
      <div className="chart__periods">
        {periods.map(item => (
          <span
            className={cn('chart__period', {
              'chart__period-active': period.key === item.key,
              'chart__period-disable': !validPeriods.includes(item.key)
            })}
            key={item.key}
            onClick={() => onPeriodClick(item)}>
            {item.value}
          </span>
        ))}
      </div>
      <LineChart
        description={chartComment}
        data={chart}
        visibleChart={false}
        benchmarkName={indexDescription}
      />
      <div className='strategy-detail__description'>
        * доходность рассчитана на основе средней цены исполнения сигналов стратегии по счетам клиентов и включает в себя плату за услугу "Автоследование" в соответствии с тарифом стратегии.
      </div>
    </div>
  );
};

const comp = withLoading(ChartDetails);

export { comp as ChartDetails };
