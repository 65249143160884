import React from 'react';
import { FieldInputProps, useField } from 'formik';

type Props = {
  field: FieldInputProps<any>;
  text: string;
  onChange?: (value: any) => null;
};

export const Checkbox: React.FC<Props> = ({
  field: fieldProp,
  text,
  onChange
}) => {
  const [field, meta, helpers] = useField(fieldProp.name);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.checked);
    onChange && onChange(event.target.checked);
  };

  return (
    <label className="checkbox">
      <input
        {...field}
        type="checkbox"
        className="checkbox__control"
        onChange={onChangeHandler}
        checked={field.value}
      />
      <span
        className="checkbox__text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </label>
  );
};
